<template>
  <a-table
    :columns="columnsFiles"
    :data-source="data ? data : 'No hay información.'"
    :showHeader="false"
    :pagination="false"
    :loading="loadingDocument"
  >
    <template #title>{{ title }}</template>
    <template #ver="{ text, record }">
      <span>
        <a :href="record.route" target="_blank" rel="noreferrer noopener">
          {{ text }}
        </a>
      </span>
    </template>
  </a-table>
</template>

<script>
import { useStore } from 'vuex';
import { GET_LOGGED_USER } from '../../store/types';

export default {
  name: 'DocumentosAdjuntos',
  props: {
    data: '',
    title: '',
    loadingDocument: false
  },
  setup() {
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_LOGGED_USER].roleName;

    const rowSelectionForm = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows
        );
      }
    };

    const columnsFiles = [
      {
        dataIndex: 'name',
        width: 100
      }
    ];

    if (user_role != 'rrhh') {
      columnsFiles.push({
        dataIndex: 'ver',
        width: 100,
        align: 'right',
        slots: { customRender: 'ver' }
      });
    }

    return {
      rowSelectionForm,
      columnsFiles
    };
  }
};
</script>
