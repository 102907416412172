<template>
  <div>
    <div>
      <a-row justify="space-between" align="middle">
        <div v-if="executives.length > 0" class="mt-3">
          <a-col v-for="(a, index) in executives" :key="index">
            <a-typography-title :level="4" v-if="a.isPrincipal">Ejecutivo principal</a-typography-title>
          </a-col>
        </div>
        <a-button type="primary" class="new_policy" @click="openCreate">
          Nuevo ejecutivo
        </a-button>
      </a-row>
    </div>
  </div>

  <div></div>
  <div v-if="loading">
    <a-skeleton active />
  </div>
  <div v-else class="mt-3">
    <div v-for="(a, index) in executives" :key="index">
      <a-descriptions bordered>
        <a-descriptions-item label="Nombre" :span="3">
          {{ a.name }}
        </a-descriptions-item>
        <a-descriptions-item label="Correo Electrónico" :span="3">
          {{ a.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Teléfono" :span="3">
          {{ a.phone }}
        </a-descriptions-item>
      </a-descriptions>
      <span style="justify-content: flex-end; list-style: none; display: flex" class="mt-2 mr-2 mb-2">
        <div class="edit-executive-text" @click="openUpdate(a)">Editar</div>
        <div v-if="admin" class="delete-executive-text ml-5" @click="openDelete(a)">
          Eliminar
        </div>
      </span>
    </div>
  </div>

  <!-- Modal -->
  <a-modal v-model:visible="visible" centered @cancel="handleOk" :title="modalTitle" :closable="!loading"
    :maskClosable="!loading">
    <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <!-- CREATE / UPDATE -->
      <div v-if="modalType !== 'Delete'">
        <a-form-item name="name" label="Nombre">
          <a-input v-model:value="formState.name" placeholder="Ingrese Nombre" :maxlength="50" />
        </a-form-item>
        <a-form-item name="email" label="Correo electrónico">
          <a-input v-model:value="formState.email" placeholder="Ingrese correo electrónico" :maxlength="50" />
        </a-form-item>
        <a-form-item name="phone" label="Teléfono">
          <a-input v-model:value="formState.phone" placeholder="Ingrese número de teléfono" :maxlength="12" />
        </a-form-item>
        <a-form-item ref="isPrincipal" name="isPrincipal">
          <a-checkbox v-model:checked="formState.isPrincipal">
            Ejecutivo principal
          </a-checkbox>
        </a-form-item>
      </div>
      <!-- DELETE -->
      <div v-if="modalType === 'Delete'">
        <p>
          Para eliminar al ejecutivo
          <strong>{{ formState.name }}</strong> escribe en el recuadro
          “eliminar” y presiona continuar.
        </p>
        <a-form-item ref="eliminar" name="eliminar">
          <a-input v-model:value="formState.eliminar" placeholder="eliminar" :maxlength="8" />
        </a-form-item>
      </div>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleOk" :disabled="loading">Cancelar</a-button>
      <a-button v-if="modalType === 'Create'" key="submit" @click="onCreate" :disabled="blockCreateButton()"
        :loading="loading">
        Agregar
      </a-button>
      <a-button v-if="modalType === 'Update'" key="submit" @click="onEdit" :disabled="blockEditButton()"
        :loading="loading">
        Guardar cambios
      </a-button>
      <a-button type="primary" danger v-if="modalType === 'Delete'" key="submit" @click="onDelete"
        :disabled="blockDeleteButton()" :loading="loading">
        Continuar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// Store
import {
  GET_INSURANCECOMPANY_EXECUTIVES,
  FETCH_INSURANCECOMPANY_EXECUTIVES,
  POST_INSURANCECOMPANY_EXECUTIVE,
  PUT_INSURANCECOMPANY_EXECUTIVE,
  DELETE_INSURANCECOMPANY_EXECUTIVE,
  GET_LOGGED_USER
} from '@/store/types';
// Utils
import openNotificationWithIcon from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'Ejecutivos',
  components: {},
  emits: ['refreshExecutive'],
  setup(_, { emit }) {
    const route = useRoute();
    const store = useStore();
    const insuranceCompanyId = ref(route.params.id);

    // BROKER DATA
    const admin = ref(false);
    admin.value = store.getters[GET_LOGGED_USER].roleName !== 'rrhh';

    const executives = ref([]);
    const getExecutives = async () => {
      await store
        .dispatch(FETCH_INSURANCECOMPANY_EXECUTIVES, insuranceCompanyId.value)
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `Error al cargar ejecutivos. ${err.response.data.message}`
          )
        );
      executives.value = await store.getters[GET_INSURANCECOMPANY_EXECUTIVES];
    };
    getExecutives();

    //* Modal
    const visible = ref(false);
    const modalType = ref('');
    const modalTitle = ref('');

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      visible.value = false;
      formRef.value.resetFields();
    };

    // * Form
    const loading = ref(false);
    const formRef = ref();
    const formState = reactive({
      id: '',
      name: '',
      email: '',
      phone: '',
      isPrincipal: false,
      eliminar: ''
    });
    const formBackup = reactive({
      id: '',
      name: '',
      email: '',
      phone: '',
      isPrincipal: false
    });

    //* Render Modal
    const openCreate = () => {
      cleanFormState();
      modalType.value = 'Create';
      modalTitle.value = 'Nuevo ejecutivo';
      showModal();
    };

    const openUpdate = (values) => {
      cleanFormState();
      formBackup.id = values.id;
      formBackup.name = values.name;
      formBackup.email = values.email;
      formBackup.phone = values.phone;
      formBackup.isPrincipal = values.isPrincipal;

      formState.id = formBackup.id;
      formState.name = formBackup.name;
      formState.email = formBackup.email;
      formState.phone = formBackup.phone;
      formState.isPrincipal = formBackup.isPrincipal;

      modalType.value = 'Update';
      modalTitle.value = 'Editar Ejecutivo';
      showModal();
    };

    const openDelete = (values) => {
      cleanFormState();
      formState.id = values.id;
      formState.name = values.name;
      modalType.value = 'Delete';
      modalTitle.value = 'Eliminar Ejecutivo';
      showModal();
    };

    //* Validations
    const cleanFormState = () => {
      formState.id = '';
      formState.name = '';
      formState.email = '';
      formState.phone = '';
      formState.isPrincipal = false;
      formState.eliminar = '';
    };
    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    const rules = {
      name: [
        {
          required: true,
          message: 'Ingrese nombre',
          trigger: 'change',
          type: 'string'
        }
      ],
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    const blockCreateButton = () => {
      let blocked = true;
      if (formState.name.length >= 3 && formState.email.length >= 5) {
        blocked = false;
      }
      return blocked;
    };

    const blockEditButton = () => {
      let blocked = true;
      if (
        formState.name != formBackup.name ||
        formState.email != formBackup.email ||
        formState.phone != formBackup.phone ||
        formState.isPrincipal != formBackup.isPrincipal
      ) {
        blocked = false;
      }
      return blocked;
    };

    const blockDeleteButton = () => {
      if (formState.eliminar === 'eliminar') {
        return false;
      }
      return true;
    };

    //* Actions
    const onCreate = async () => {
      loading.value = true;

      try {
        const payload = {
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          isPrincipal: formState.isPrincipal
        };

        if (payload.phone == null || payload.phone == '') {
          delete payload.phone;
        }

        await store.dispatch(POST_INSURANCECOMPANY_EXECUTIVE, {
          idInsurance: insuranceCompanyId.value,
          payload
        });
        await getExecutives();

        // Emit para que actualize la informacion del ejecutivo que aparece a la izquirda
        emit('refreshExecutive', true);

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Ejecutivo creado con éxito'
        );
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido crear al Ejecutivo. ${error.response.data.message}`
        );
      }

      loading.value = false;

      visible.value = false;
    };

    const onEdit = async () => {
      loading.value = true;
      try {
        await store.dispatch(PUT_INSURANCECOMPANY_EXECUTIVE, {
          idInsurance: insuranceCompanyId.value,
          idExecutive: formState.id,
          payload: {
            name: formState.name,
            email: formState.email,
            phone: formState.phone,
            isPrincipal: formState.isPrincipal
          }
        });
        await getExecutives();

        // Emit para que actualize la informacion del ejecutivo que aparece a la izquirda
        emit('refreshExecutive', true);

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Ejecutivo modificado con éxito'
        );
        visible.value = false;
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido modificar al Ejecutivo. ${error.response.data.message}`
        );
      }
      loading.value = false;
    };

    const onDelete = async () => {
      loading.value = true;
      const insuranceId = parseInt(route.params.id);
      const executiveId = parseInt(formState.id);
      const payload = { insuranceId, employeeId: executiveId };
      try {
        await store.dispatch(DELETE_INSURANCECOMPANY_EXECUTIVE, payload);
        await getExecutives();

        // Emit para que actualize la informacion del ejecutivo que aparece a la izquirda
        emit('refreshExecutive', true);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Ejecutivo eliminado con éxito'
        );

        await delay(1000);

        visible.value = false;
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido eliminar al Ejecutivo. ${error.response.data.message}`
        );
      }

      loading.value = false;
    };

    return {
      // Delete Validation
      admin,
      // Variables
      executives,
      visible,
      modalType,
      modalTitle,
      loading,
      formRef,
      formState,
      rules,
      // Methods
      handleOk,
      openCreate,
      openUpdate,
      openDelete,
      onCreate,
      onEdit,
      onDelete,
      blockCreateButton,
      blockEditButton,
      blockDeleteButton
    };
  }
};
</script>

<style>
.edit-executive-text {
  color: blue;
  cursor: pointer;
}

.delete-executive-text {
  color: red;
  cursor: pointer;
}
</style>
