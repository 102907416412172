<template>
  <div>
    <a-modal
      v-model:visible="visible"
      :title="modal_content.title"
      :closable="!loading"
      :afterClose="handleClose"
      :maskClosable="!loading"
    >
      <a-form
        class="mt-2"
        name="form"
        layout="vertical"
        ref="formref"
        :model="form_state"
        :rules="rules"
      >
        <div v-if="request_type >= 2">
          <a-typography-title :level="5">
            Seleccione Factura para {{ modal_content.title }}
          </a-typography-title>
          <a-form-item name="policies">
            <!-- <a-row :span="12" :gutter="15" class="mt-2">
        <a-col class="ml-3 mb-2"> -->
            <a-checkbox-group
              v-model:value="form_state.policies"
              :options="options"
            />
            <!-- </a-col>
      </a-row> -->
          </a-form-item>
        </div>

        <a-typography-title :level="5">
          Mensaje a compañía aseguradora
        </a-typography-title>
        <p>{{ modal_content.subtitle }}</p>

        <a-form-item name="content" ref="content">
          <a-textarea
            v-model:value="form_state.content"
            placeholder="Escribe Aquí..."
            :rows="10"
          >
          </a-textarea>
        </a-form-item>
      </a-form>

      <a-typography-title :level="5">Documentos adjuntos</a-typography-title>

      <b
        v-for="att in total_attachments"
        :key="att.id"
        class="pdf-viewer"
        @click="openPdfViewer(att.objectUrl)"
      >
        {{ att.objectFilename }}
      </b>

      <template #footer>
        <a-button
          type="primary"
          @click="handleOk"
          :loading="loading"
          :disabled="textContentEdit"
        >
          Enviar
        </a-button>
      </template>
    </a-modal>

    <pdf-viewer ref="pdf_ref" />
  </div>
</template>

<script>
import { reactive, ref, watchEffect, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import notification from '@/utils/notifications';
import PDFViewer from '@/components/PDFViewer.vue';
import {
  POST_REQUESTS,
  FETCH_BILLING_DETAIL_LIST,
  FETCH_COLLECTION_ATTACHMENTS,
  GET_BILLING_DETAIL_LIST,
  GET_COLLECTION_ATTACHMENTS,
  GET_LOGGED_USER
} from '@/store/types';

export default {
  props: {
    period: {
      type: String,
      default: ''
    },
    ticketId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    'pdf-viewer': PDFViewer
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const formref = ref();
    const valuePoliza = ref([]);
    const options = computed(() =>
      store.getters[GET_BILLING_DETAIL_LIST]?.rows?.map((e) => ({
        label: e.folio,
        value: e.id
      }))
    );
    const form_state = reactive({
      content: '',
      policies: []
    });
    const textContentEdit = ref(false);

    const checkText = async (rule, value) => {
      const found = value.match('{Texto escrito por broker}');
      if (found) {
        textContentEdit.value = true;
        return Promise.reject('Edite mensaje broker');
      } else if (value == '') {
        return Promise.reject('ingrese mensaje');
      }
      return Promise.resolve();
    };

    const rules = {
      content: [
        {
          required: true,
          validator: checkText,
          type: 'string'
        }
      ],
      policies: [
        {
          required: true,
          message: 'Seleccione una poliza',
          type: 'array'
        }
      ]
    };

    // * Modal
    const visible = ref(false);
    const request_type = ref(0);
    const total_invoices = computed(
      () => store.getters[GET_BILLING_DETAIL_LIST]?.data?.total_items
    );
    const total_attachments = computed(
      () => store.getters[GET_COLLECTION_ATTACHMENTS]
    );
    const modal_content = reactive({
      title: '',
      subtitle: ''
    });

    const handleOk = () => {
      formref.value.validate().then(() => {
        createReprocess();
      });
    };
    const handleClose = () => {
      form_state.policies = [];
      formref.value.resetFields();
    };

    const createReprocess = async () => {
      loading.value = true;
      try {
        let payload;
        if (request_type.value <= 1) {
          payload = {
            messageEmail: form_state.content,
            type: request_type.value,
            brokerId: store.getters[GET_LOGGED_USER].id
          };
        } else {
          payload = {
            messageEmail: form_state.content,
            type: request_type.value,
            invoiceId: form_state.policies,
            brokerId: store.getters[GET_LOGGED_USER].id
          };
        }
        await store.dispatch(POST_REQUESTS, { id: route.params.id, payload });

        notification('success', 'Correcto', 'Solicitud creada correctamente.');

        emit('requestCreated', true);

        visible.value = false;
      } catch (error) {
        console.log('error', error);
        notification('error', 'Error', error.response.data.message);
        visible.value = false;
      }
      loading.value = false;
    };

    // * PDFViewer
    const pdf_ref = ref();
    const openPdfViewer = (url) => {
      pdf_ref.value.openModal(url);
    };

    const getInvoicesTotal = () => {
      store
        .dispatch(FETCH_BILLING_DETAIL_LIST, {
          collection_id: props.ticketId,
          query: '?size=1000'
        })
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
        });
    };

    const getAttachments = () => {
      store
        .dispatch(FETCH_COLLECTION_ATTACHMENTS, props.ticketId)
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
        });
    };
    watch(form_state, (val) => {
      const found = val.content.match('{Texto escrito por broker}');
      const existPolicies = !val.policies.length;
      if (
        found ||
        (existPolicies && request_type.value == 2) ||
        (existPolicies && request_type.value == 3)
      ) {
        textContentEdit.value = true;
      } else if (val.content == '') {
        textContentEdit.value = true;
      } else {
        textContentEdit.value = false;
      }
    });
    watchEffect(() => {
      switch (parseInt(request_type.value)) {
        case 2:
          modal_content.title = 'Solicitud por Nota de Crédito';
          modal_content.subtitle =
            'Escribe un mensaje a la compañia aseguradora informando los motivos para solicitar la anulación de la factura';
          form_state.content =
            'Estimada compañía:\n\n{Texto escrito por broker}\n\nUn cordial saludo';
          break;
        case 3:
          modal_content.title = 'Solicitud por Nota de Débito';
          modal_content.subtitle =
            'Escribe un mensaje a la compañia aseguradora informando los motivos para solicitar la anulación de la factura';
          form_state.content =
            'Estimada compañía:\n\n{Texto escrito por broker}\n\nUn cordial saludo';
          break;
        default:
          modal_content.title = 'Solicitud por Ajuste Retroactivo';
          modal_content.subtitle =
            'Escribe un mensaje a la compañia aseguradora informando las inconsistencias registradas en el proceso de cobranza';
          form_state.content =
            'Estimada compañía:\n\n{Texto escrito por broker}\n\nUn cordial saludo';
          break;
      }
    });

    const openModal = (type) => {
      request_type.value = type;
      visible.value = true;

      if (type == 2 || type == 3) {
        getInvoicesTotal();
      }

      getAttachments();
    };

    return {
      visible,
      modal_content,
      form_state,
      formref,
      rules,
      loading,
      total_invoices,
      total_attachments,
      pdf_ref,
      openPdfViewer,
      handleOk,
      handleClose,
      openModal,
      request_type,
      valuePoliza,
      options,
      textContentEdit
    };
  }
};
</script>

<style scoped>
.pdf-viewer {
  color: #2521e5;
  cursor: pointer;
}
</style>
