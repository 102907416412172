// * SET
export const SET_PROFILE = 'auth/SET_PROFILE';
export const UNSET_PROFILE = 'auth/UNSET_PROFILE';

// * FETCH
export const FETCH_AUTH = 'auth/FETCH_AUTH';

// * GET
export const GET_LOGGED_USER = 'auth/GET_LOGGED_USER';

// * OTROS
export const LOG_OUT = 'auth/LOG_OUT';
export const LOG_IN = 'auth/LOG_IN';
export const TOKEN_VALIDATION = 'auth/TOKEN_VALIDATION';
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const RESET_PASS = 'auth/RESET_PASS';
