import { getOneUserById } from '../../network';
import { FETCH_USER_BY_ID, SET_LOADING_USER_DATA } from '../types';

const store = {
  state: {
    loading: false,
  },

  getters: {
    isLoading(state) {
      return state.loading;
    },
  },

  mutations: {
    [SET_LOADING_USER_DATA](state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    async [FETCH_USER_BY_ID]({ commit }, payload) {
      commit(SET_LOADING_USER_DATA, true);
      try {
        const response = await getOneUserById(payload);
        return response;
      } catch (error) {
        console.error('Error al buscar la data del usuario');
        console.error('Id usuario: ', payload);
        throw error;
      } finally {
        commit(SET_LOADING_USER_DATA, false);
      }
    },
  },
};

export default store;
