<template>
  <div>
    <a-modal v-model:visible="visible" title="Envio de cobranza" centered>
      <a-form ref="formRef" layout="vertical" :model="formState" :rules="rules">
        <a-form-item name="messageText">
          <template #label>
            <a-row>
              <a-col>
                <span>Mensaje a cliente</span>
              </a-col>
              <a-typography-title class="text-gray-8 fw-regular mb-2" :level="5">
                Escribe un mensaje a tu cliente para informar sobre la nomina de
                cobranza
              </a-typography-title>
            </a-row>
            <a-row> </a-row>
          </template>
          <a-textarea v-model:value="formState.messageText" placeholder="Escribe Aquí..." :rows="13" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button :loading="loading" key="back" @click="closeModal" :disabled="loading">Cancelar</a-button>
        <a-button key="submit" :loading="loading" :disabled="textAreaEdit" type="primary"
          @click="submitModal">Enviar</a-button>
      </template>
      <div>
        <a-row>
          <span>Documento adjunto:</span>
        </a-row>
      </div>
      <a-skeleton :loading="loading_attachments">
        <a-list class="my-3" item-layout="horizontal" :data-source="paginated_attachements">
          <template #renderItem="{ item }">
            <div>
              <a-button type="link" class="pdf-viewer" @click="checkPDForExcel(item)">
                {{ item.objectFilename }}
              </a-button>
            </div>
          </template>
        </a-list>

        <a-pagination v-if="total_attachments.length > 2" v-model:current="current_pagination" :page-size="size_per_page"
          :total="total_attachments.length" show-less-items />
      </a-skeleton>
      <pdf-viewer ref="pdf_ref" />
    </a-modal>

    <a-modal v-model:visible="success_visible" title="Envío de Cobranza" :footer="null">
      <a-result status="success" title="Cobranza Enviada" :sub-title="sub_title_confirm" />
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  POST_COLLECTION_HUMAN_SEND,
  FETCH_COLLECTIONS,
  GET_LOGGED_USER,
  FETCH_COLLECTION_ATTACHMENTS,
  FETCH_BILLING_DETAIL_LIST,
  GET_COLLECTION_ATTACHMENTS
} from '../../../store/types';
import notification from '../../../utils/notifications';
import EventBus from '../../../utils/events';
import PDFViewer from '@/components/PDFViewer.vue';

export default {
  name: 'CollectionTicketHumanSend',
  props: {
    data: {
      type: Object,
      default: {}
    },
    ticketId: {
      type: [String, Number],
      default: ''
    },
    collectionType: {
      type: String,
      default: 'COLLECTION'
    }
  },
  components: {
    'pdf-viewer': PDFViewer
  },
  setup(props) {
    const visible = ref(false);
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const textAreaEdit = ref(false);
    const brokerId = store.getters[GET_LOGGED_USER].id;
    const formRef = ref();
    const sub_title_confirm = ref(
      `La cobranza correspondiente al periodo ${props.data.period} ha sido enviada con éxito`
    );
    const formState = reactive({
      messageText: ''
    });
    watch(
      () => visible.value,
      (newVal) => {
        visible.value = newVal;
        if (visible.value == true) {
          formState.messageText =
            'Estimado cliente:\n\n' +
            `Adjuntamos cobranzas del periodo ${props.data.period} ${props.data.companies.length >= 1
              ? `de la empresa ${props.data.companies.map(
                (e) => e.businessName
              )}`
              : `de las empresas ${props.data.companies
                .map((e) => e.businessName)
                .join(', ')}`
            }.\n\n` +
            '{Texto escrito por broker}\n\n' +
            'Un cordial saludo';
        } else {
          formState.messageText = '';
        }
      }
    );
    watch(formState, (val) => {
      const found = val.messageText.match('{Texto escrito por broker}');
      if (found) {
        textAreaEdit.value = true;
      } else {
        textAreaEdit.value = false;
      }
    });
    const checkText = async (rule, value) => {
      const found = value.match('{Texto escrito por broker}');
      if (found) {
        textAreaEdit.value = true;
        return Promise.reject('Edite mensaje broker');
      } else if (value == '') {
        return Promise.reject('ingrese mensaje');
      }
      return Promise.resolve();
    };
    const rules = {
      messageText: [
        {
          required: true,
          validator: checkText,
          type: 'string'
        }
      ]
    };

    const closeModal = () => {
      visible.value = false;
    };

    const openModal = () => {
      visible.value = true;
      getAttachments();
    };

    const submitModal = async () => {
      EventBus.emit('loadingHumandSend', true);
      loading.value = true;
      try {
        const validate = await formRef.value.validate();
        if (validate) {
          const resp = await store.dispatch(POST_COLLECTION_HUMAN_SEND, {
            collectionId: route.params.id,
            payload: {
              message: formState.messageText,
              brokerId
            }
          });
          if (resp.status == 200) {
            openSuccessVisible();
            loading.value = false;
            await store.dispatch(FETCH_COLLECTIONS, {
              collection_id: route.params.id,
              query_params: ''
            });
          }
        }
        loading.value = false;
        EventBus.emit('loadingHumandSend', false);
      } catch (error) {
        loading.value = false;
        EventBus.emit('loadingHumandSend', false);
        notification('error', 'Error', error.response.data.message);
        console.log('error', error);
      }
      closeModal();
    };
    const loading_attachments = ref(false);

    const total_attachments = ref([]);
    const current_pagination = ref(1);
    const size_per_page = 2;

    const paginated_attachements = computed(() => {
      return [
        total_attachments.value[current_pagination.value - 1],
        total_attachments.value[current_pagination.value]
      ];
    });

    const getAttachments = async () => {
      if (total_attachments.value.length === 0) {
        loading_attachments.value = true;
        try {
          await store.dispatch(FETCH_COLLECTION_ATTACHMENTS, props.ticketId);
          total_attachments.value.push(
            ...store.getters[GET_COLLECTION_ATTACHMENTS]
          );

          if (props.collectionType === 'COLLECTION') {
            const resp = await store.dispatch(FETCH_BILLING_DETAIL_LIST, {
              collection_id: props.ticketId,
              query: ''
            });
            resp.data.rows.forEach((element) => {
              total_attachments.value.push({
                objectUrl: element.objectUrl,
                objectFilename: element.objectName
              });
            });
          }
        } catch (error) {
          notification('error', 'Error', error.response.data.message);
        }
        loading_attachments.value = false;
      }
    };

    // * PDFViewer
    const pdf_ref = ref();
    const openPdfViewer = (url) => {
      pdf_ref.value.openModal(url);
    };
    const checkPDForExcel = (att) => {
      const extensionPdf = att.objectFilename.match(/\.pdf$/i);
      if (extensionPdf) {
        openPdfViewer(att.objectUrl);
      } else {
        window.location.href = att.objectUrl;
      }
    };

    // * Moda de confirmacion
    const success_visible = ref(false);

    const openSuccessVisible = () => {
      success_visible.value = true;
    };

    const closeSuccessVisible = () => {
      success_visible.value = false;
    };

    return {
      visible,
      loading,
      rules,
      formRef,
      formState,
      closeModal,
      submitModal,
      openModal,
      textAreaEdit,
      loading_attachments,
      getAttachments,
      total_attachments,
      checkPDForExcel,
      pdf_ref,
      openPdfViewer,
      success_visible,
      openSuccessVisible,
      closeSuccessVisible,
      sub_title_confirm,
      current_pagination,
      size_per_page,
      paginated_attachements
    };
  }
};
</script>
