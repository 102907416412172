<template>
  <page-header :breadcrumRoutes="routes" title="Baja individual">
    <template #tags>
      <tags
        :stateId="ticket_state_id"
        :loading="true"
        v-if="ticket_state_id !== ''"
      />
      <tags :stateId="1" :loading="true" v-else />
      <tags color="processing"> ID: {{ id_baja }} </tags>
    </template>

    <template #buttons v-if="user_role != 'rrhh'">
      <a-button
        v-if="ticket_state_id == 1 || ticket_state_id == 8"
        @click="openCancelModal"
        :disabled="loading"
        :loading="loading"
      >
        Cancelar
      </a-button>
      <a-button
        v-if="ticket_state_id == 8"
        @click="() => (updateEndDateVisible = true)"
        class="ml-2"
        :disabled="loading"
        :loading="loading"
      >
        Editar fecha de término
      </a-button>
      <a-button
        v-if="ticket_state_id == 8"
        type="primary"
        class="ml-2"
        :loading="loading"
        :disabled="loading"
        @click="openExecuteModal"
        >Ejecutar</a-button
      >
    </template>
  </page-header>

  <a-modal
    v-model:visible="updateEndDateVisible"
    title="Editar fecha de término"
    @ok="updateEndDate"
  >
    <a-date-picker
      format="DD-MM-YYYY"
      style="width: 100%"
      v-model:value="newEndDate"
    />
  </a-modal>

  <stepper
    ticketType="baja"
    :state="stepper_props.state"
    :stateDates="stepper_props.dates"
    ref="stepper_ref"
  />

  <a-card>
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket
          :data="data_to_table"
          title="Datos corporativos"
          :loadTicket="loadTicket"
        />

        <a-card
          v-if="user_role != 'rrhh'"
          title="Casos asociados"
          bodyStyle="padding: 24px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;"
          :bordered="false"
        >
          <div v-if="cases.length == 0">
            <a-typography-text>No existen casos</a-typography-text>
          </div>

          <a-row
            type="flex"
            justify="space-between"
            v-for="c in cases"
            :key="c.id"
          >
            <a-col :span="6">
              <a-typography-text>{{ c.state }}</a-typography-text>
            </a-col>
            <a-col>
              <tags :stateId="c.tag" />
            </a-col>
            <a-col>
              <a-button type="link" @click="redirectCase(c)"
                >Ir a caso</a-button
              >
            </a-col>
            <a-divider class="my-2" v-if="cases.length > 1" />
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="12">
        <records
          title="Historial"
          :loadingHistory="loadingHistory"
          :data="ordered_records"
          v-if="user_role != 'rrhh'"
        />

        <comments
          v-if="user_role != 'rrhh'"
          class="mt-2"
          :comments="comments_ordered"
          :idToTicket="id_baja"
          @new_comment="getNewCommment"
        />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, ref, computed, createVNode, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import Stepper from '@/components/Tickets/Stepper.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Records from '@/components/Tickets/Records.vue';
import Comments from '@/components/Tickets/Comments.vue';
import notification from '@/utils/notifications';
import parseDate from '@/utils/dateFormatter';
import {
  GET_LOGGED_USER,
  FETCH_TICKET_ID,
  FETCH_TICKET_COMMENTS_ID,
  FETCH_CASES,
  FETCH_USER_BY_ID,
  FETCH_RECORD_FILE_TICKET,
  FETCH_COMPANY_BY_USER_ID,
  PATCH_FINISH_TICKET_BAJA,
  POST_CANCEL_TICKET_BAJA,
  UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION
} from '@/store/types';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import delay from '@/utils/delay';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'BajaDetail',
  components: {
    stepper: Stepper,
    'datos-ticket': DatosTicket,
    records: Records,
    comments: Comments,
    'page-header': PageHeader,
    tags: Tags
  },
  setup() {
    // * VUEX
    const store = useStore();
    const user_role = store.getters[GET_LOGGED_USER].roleName;
    const showOnlyCancel = ref(false);
    const loadTicket = ref(false);
    const loadingHistory = ref(false);

    const updateEndDateVisible = ref(false);
    const newEndDate = ref();

    const updateEndDate = async () => {
      try {
        if (!newEndDate.value) {
          notification('error', 'Error', 'Debe seleccionar una fecha.');
          return;
        }
        await store.dispatch(UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION, {
          id: id_baja,
          endDate: newEndDate.value.startOf('day').toISOString()
        });
        await getTicketData(id_baja);
        updateEndDateVisible.value = false;
        newEndDate.value = undefined;
      } catch (error) {
        console.error(error);
        let errMsg = error?.response?.data?.message;
        if (!errMsg) {
          errMsg =
            'Ha ocurrido un error al cambiar la fecha de término. Favor intentar más tarde.';
        }
        notification('error', 'Error', errMsg);
      }
    };

    // * Obtener la id desde la ruta url en la que estoy
    const route = useRoute();
    const id_baja = route.params.id; // id de la baja que estoy viendo

    // * Rutas
    const routes = [
      {
        path: '/bajas',
        breadcrumbName: 'Bajas'
      },
      {
        path: '#',
        breadcrumbName: 'Individual'
      },
      {
        breadcrumbName: `#${id_baja}`
      }
    ];

    const getBroker = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };

    // * Stepper
    const header_state_data = reactive({
      text: '',
      color: '',
      showCancelAndExecuted: false,
      revisado_disabled: false,
      show_buttons: true
    });
    const stepper_props = reactive({
      state: '',
      dates: []
    });
    const stepper_ref = ref('null');

    const ticket_state_id = ref('');
    const router = useRouter();
    const records_steps = reactive([]);
    const records_comments = reactive([]);
    const records_files = reactive([]);

    const stepperData = async (id) => {
      // loadingHistory.value=true;

      // Data para el stepper para que cargue de manerareactiva

      // 1.- Obtengo data para el ticket
      loadingHistory.value = true;
      try {
        const ticket_response = await store
          .dispatch(FETCH_TICKET_ID, id)
          .catch((e) => e.response);
        const ticket_data = ticket_response.data;
        if (ticket_data.type.id != 2) {
          return router.push({ name: 'Missing' });
        }
        records_steps.length = 0;
        for (let i = 0; i < ticket_data.states.length; i++) {
          const element = ticket_data.states[i];
          console.log('elemeeent', element);
          records_steps.push({
            title: element.state.tag,
            description: element.createdAt
          });
        }

        ticket_state_id.value =
          ticket_data.states[ticket_data.states.length - 1].state.id;

        // 2.- Primero obtengo el ultimo estado del cual esta el ticket, obviamente este puede cambiar
        let last_ticket_state = '';
        last_ticket_state = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        // 3.- Transformo el ultimo estado en un texto que pueda interpretar el stepper
        let step = getStateType(last_ticket_state);

        // 4.- En caso de que el paso quede en cancelado, tengo que obtener el penultimo estado
        // que estubo correcto, con este estado es el cual se le va a marcar la X en el stepper ya que asi lo toma ant design
        if (step === 'cancelado') {
          // Obtengo entonces el penultimo estado
          const last_before = ticket_data.states.sort((a, b) => {
            return b.id - a.id;
          })[1].state.tag;
          step = getStateType(last_before);
          // Marco la X en el estado
          cancelStep();
        }
        if (step == 'ejecutado') {
          finishStep();
        }

        // 5.- Ahora que ya tengo el estado que quiero mostrar marcado en el stepper, se lo paso una variable
        // que mandare como propiedad en el componente stepper
        stepper_props.state = step;

        // 6.- Tomo los estados ordenados por fecha, despues eliminare los siguientes si es que un estado
        // se devolvio al anterior y hay mas estados delante del ultimo estado donde quedo
        /*
              (X)            (((X)))                       (X)               ()
                        [este es el ultimo
                          estado ya que
                          se devolvio
                          por alguna razon]
                                                [Este deberia de
                                                eliminarse de la
                                                lista de estados
                                                para no mostrarlo
                                                en el stepper]
            CREADO   EN REVISION BEWELL   EN REVISION ASEGURADORA   EJECUTADO
        */
        const sorted_dates = ticket_data.states.sort(function (a, b) {
          return a.id - b.id;
        });

        const final_obj = {};
        for (let i = 0; i < sorted_dates.length; i++) {
          const element = sorted_dates[i];
          const state_name = getStateType(element.state.tag);
          final_obj[state_name] = element.createdAt;
        }

        // 6.- Elimino estados que quedaron por delante del ultimo estado como explique arriba
        // Con el ultimo estado del ticket, valido si hay estados mas adelantes los cuales deba eliminar

        switch (step) {
          case 'creado':
            delete final_obj['revision_bewell'];
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_bewell':
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_aseguradora':
            delete final_obj['ejecutado'];
            break;
        }

        // 7.- En caso de que el ultimo estado este en cancelado, voy a dejar el penultimo estado en marcado
        // Para que se marque la x ahi
        // Voy a preguntar cual es el ultimo estado nuevamente
        const last = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        // Si el ultimo estado del ticket fue cancelado, la fecha de este cancelado, se la paso al penultimo estado
        // asi para que se muestre en el penultimo estado que se cancelo con su fecha correspondiente
        const keys_states = Object.keys(final_obj);
        showOnlyCancel.value = false;
        if (last === 'Cancelado') {
          final_obj[keys_states[keys_states.length - 2]] =
            final_obj[keys_states[keys_states.length - 1]];
          delete final_obj['cancelado'];
        }

        // Formateo de fechas
        keys_states.forEach((element) => {
          final_obj[element] = parseDate(final_obj[element]);
          // final_obj[element] = formatDate(new Date(final_obj[element]));
        });

        stepper_props.dates = final_obj;

        // 8.- Paso data para el page-header
        // Si el ultimo estado esta en 'En revision bewell' osea 'revision_bewell', podemos darle al boton de revisado y de cancelar
        // el unico estado que puedo cancelar y revisar el ticket es en 'Revision aseguradora'

        header_state_data.revisado_disabled = true;
        header_state_data.show_buttons = false;

        switch (last) {
          case 'Creado':
            header_state_data.showCancelAndExecuted = true;
            header_state_data.text = 'Creado';
            header_state_data.color = 'blue';
            break;
          case 'En revision Aseguradora':
            header_state_data.showCancelAndExecuted = true;
            header_state_data.text = 'En revisión aseguradora';
            header_state_data.color = 'gold';
            header_state_data.show_buttons = true;
            break;
          case 'Ejecutado':
            header_state_data.showCancelAndExecuted = false;
            header_state_data.text = 'Ejecutado';
            header_state_data.color = 'green';
            break;
          case 'Cancelado':
            header_state_data.showCancelAndExecuted = false;
            header_state_data.text = 'Cancelado';
            header_state_data.color = 'red';
            break;
          default:
            // TODO: En caso de que reciba cualquier otro estado que no deba
            header_state_data.text = last;
            header_state_data.color = 'default';
            break;
        }
        loadingHistory.value = false;
      } catch (error) {
        loadingHistory.value = false;
        console.log('error', error);
      }
      // loadingHistory.value=false;
    };

    const getStateType = (state) => {
      // Lo transformo en un texto que el stepper lo sepa interpretar, ya que los nombres de los estados
      // Estan en cambio aun, los estados en el stepper siempre van a estar igual, pero no aca
      // la regla es la siguiente
      switch (state) {
        case 'Creado': // Asi viene desde el backend
          return 'creado'; // Asi es como lo toma el stepper y lo marca en su lugar
        case 'En revision Bewell':
          return 'revision_bewell';
        case 'En revision Aseguradora':
          return 'revision_aseguradora';
        case 'Ejecutado':
          return 'ejecutado';
        case 'Cancelado':
          return 'cancelado';
      }
    };

    const cancelStep = () => {
      // Metodo para cancelar el stepper en un cierto paso
      stepper_ref.value.cancelStep();
    };
    const finishStep = () => {
      stepper_ref.value.finishStep();
    };

    // * Ticket
    // * Compania a seguradora y polizas
    const policies = ref([]);
    const ticket = reactive({
      employee: '',
      company: '',
      insurance: '',
      policies: '',
      endDate: '',
      messageInsurance: '',
      brokerId: null
    });
    const getTicketData = async (id) => {
      loadTicket.value = true;
      try {
        // Metodo para obtener data y llenar el ticket
        const ticket_response = await store
          .dispatch(FETCH_TICKET_ID, id)
          .catch(() => router.push({ name: 'Missing' }));
        const ticket_data = ticket_response.data;
        const data = ticket_data.policies;
        policies.value = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          policies.value.push(element.numberPolicy);
        }
        ticket.brokerId = ticket_data.brokerId;
        ticket.employee = ticket_data.employee;
        ticket.company = ticket_data.company;
        ticket.insurance = ticket_data.insuranceCompany.businessName;
        ticket.policies = policies.value
          .reduce((acc, item) => {
            return [...acc, item];
          }, '')
          .toString();
        ticket.endDate = parseDate(ticket_data.endDate).substring(0, 10);

        const value_to_records = ticket_data.states;
        fillRecordsWithStates(value_to_records);
        loadTicket.value = false;
        await getGroupByEmployee(ticket.employee.id);

        // loadTicket.value=false;
      } catch (error) {
        loadTicket.value = false;
        console.log('error', error);
      }
    };

    // Casos
    const cases = ref([]);

    const getAllCasesByTicketId = async (id) => {
      cases.value = [];

      const response_cases = await store
        .dispatch(FETCH_CASES, id)
        .catch((e) => e.response);

      for (let i = 0; i < response_cases.data.length; i++) {
        const obj = {
          id: '',
          tag: '',
          state: '',
          typeCaseId: 0
        };

        const caso = response_cases.data[i];

        obj.id = caso.id;
        obj.tag = caso.states
          .sort((a, b) => {
            return b.id - a.id;
          })[0]
          .state.id.toString();
        obj.state = caso.typeCase.tag;
        obj.typeCaseId = caso.typeCaseId;

        cases.value.push(obj);
      }
    };

    const redirectCase = (value) => {
      const ticketId = value.id;

      const ticketType =
        value.state === 'Out of Time'
          ? 'fuera_de_tiempo'
          : value.state.toLowerCase();

      switch (ticketType) {
        case 'fuera de tiempo: baja individual':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
      }
    };

    // * Comentarios
    const comments = ref([]);
    const getCommentsFromTicket = async (id) => {
      // Metodo para obtener data y llenar los comentarios

      comments.value.length = 0;
      const comments_response = await store.dispatch(
        FETCH_TICKET_COMMENTS_ID,
        id
      );

      for (let i = 0; i < comments_response.length; i++) {
        const element = comments_response[i];
        const brokerName = await getBroker(element.brokerId);
        element.brokerName = brokerName;
        element.author = brokerName;
        comments.value.push(element);
      }

      fillRecordsWithComents(comments.value);
    };

    const getNewCommment = async (value) => {
      const { brokerId } = value;
      const brokerName = await getBroker(brokerId);

      comments.value.push({
        message: value.title,
        createdAt: value.description,
        brokerName
      });

      records.push({
        title: 'Nuevo comentario',
        description: value.description
      });
    };

    const comments_ordered = computed(() => {
      const rows = [];

      for (let i = 0; i < comments.value.length; i++) {
        const element = comments.value[i];
        const finishDate = new Date(element.createdAt);
        rows.push({
          description: finishDate,
          title: element.message,
          broker: element.brokerName,
          author: element.brokerName
        });
      }

      rows.sort(function (a, b) {
        return a.description - b.description;
      });

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        const fecha = parseDate(element.description);
        rows[i].description = fecha;
      }

      return rows;
    });

    // * Archivos
    const file_records = ref([]);
    const getFilesRecordsFromTicket = async (id) => {
      // Metodo para obtener data del registro de los archivos enviados por ticket

      file_records.value.length = 0;
      const file_records_response = await store.dispatch(
        FETCH_RECORD_FILE_TICKET,
        id
      );
      for (let i = 0; i < file_records_response.length; i++) {
        const element = file_records_response[i];
        file_records.value.push(element);
      }

      fillRecordsWithFilesHistory(file_records.value);
    };

    // const ticketData = ref([]);
    // const crearDataToTable = async (data) =>{
    //   ticketData.value=data;
    // };

    // * Mensaje aseguradora
    const insuranceMessage = ref('');
    const getInsuranceMessage = (value) => {
      if (value.emailTicket != null) {
        insuranceMessage.value = value.emailTicket.message;
      }
    };

    // * Historial
    const records = reactive([]);

    // Llenar el historial con estados del ticket
    const fillRecordsWithStates = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: element.state.tag,
          description: element.createdAt
        });
      }
    };

    // Llenar el historial con Comentarios
    const fillRecordsWithComents = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: 'Nuevo comentario',
          description: element.createdAt
        });
      }
    };

    // Llenar el historial con el historial de archivos enviados
    const fillRecordsWithFilesHistory = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: 'Documento enviado',
          description: element.createdAt
        });
      }
    };

    const ordered_records = computed(() => {
      loadingHistory.value = true;
      const rows = [];
      const records = [records_steps, records_comments, records_files];

      records.forEach((element) => {
        for (let i = 0; i < element.length; i++) {
          const el = element[i];
          if (el.title == 'Ejecutado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'finish'
            });
          } else if (el.title == 'Cancelado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'error'
            });
          } else {
            rows.push({
              description: new Date(el.description),
              title: el.title
            });
          }
        }
        // loadingHistory.value=false;
      });

      rows.sort(function (a, b) {
        return a.description - b.description;
      });

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        const fecha = parseDate(element.description); // formatDate(element.description);
        rows[i].description = fecha;
      }
      return rows;
    });

    // * Formatear fecha

    // const padTo2Digits = (num) => {
    //   return num.toString().padStart(2, '0');
    // };

    // const formatDate = (date) => {
    //   const year    = date.getFullYear();
    //   let month   = date.getMonth()+1;
    //   let day     = date.getDate();
    //   let hour    = date.getHours();
    //   let minute  = date.getMinutes();
    //   let second  = date.getSeconds();
    //   if(month.toString().length == 1) {
    //     month = `0${month}`;
    //   }
    //   if(day.toString().length == 1) {
    //     day = `0${day}`;
    //   }
    //   if(hour.toString().length == 1) {
    //     hour = `0${hour}`;
    //   }
    //   if(minute.toString().length == 1) {
    //     minute = `0${minute}`;
    //   }
    //   if(second.toString().length == 1) {
    //     second = `0${second}`;
    //   }
    //   const dateTime = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    //   return dateTime;
    // };

    // * Cancelar ticket
    const cancelTickets = async () => {
      loading.value = true;
      try {
        // Hago la request hacia la base de datos
        const response = await store.dispatch(POST_CANCEL_TICKET_BAJA, id_baja);

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await stepperData(id_baja);

        await delay(1000);

        // Agrego una row mas al historial
        records.push({
          title: 'Cancelado',
          description: response.data.createdAt,
          status: 'error'
        });
        loading.value = true;
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };

    const openCancelModal = () => {
      Modal.confirm({
        title: () => '¿Está seguro que desea cancelar el ticket de baja?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          cancelTickets();
        },
        onCancel() {}
      });
    };

    // * Ejecutar ticket
    const loading = ref(false);
    const ejecutar = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          PATCH_FINISH_TICKET_BAJA,
          id_baja
        );

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await stepperData(id_baja);

        // Agrego una row mas al historial
        records.push({
          title: 'Ejecutado',
          description: response.data.Ticket.createdAt
        });
        loading.value = false;
      } catch (error) {
        loading.value = false;
        notification('error', 'Error', error.response.data.message);
        console.log(error);
      }
    };

    const openExecuteModal = () => {
      Modal.confirm({
        title: () => '¿Está seguro que desea ejecutar el ticket de baja?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          ejecutar();
        },
        onCancel() {}
      });
    };

    // * Grupos
    const groups = ref({});
    const getGroupByEmployee = async (id) => {
      // Metodo para obtener los grupos que pertenece el asegurado
      groups.value = {};
      const group_company_response = await store.dispatch(
        FETCH_COMPANY_BY_USER_ID,
        id
      );
      groups.value = group_company_response.data;
    };
    const brokerName = ref('');

    // * Obtencion de la data principal
    const getData = async (id) => {
      try {
        // Obtengo data del ticket
        await getTicketData(id);

        // OBtengo mensaje de aseguradora
        getInsuranceMessage(ticket);

        brokerName.value = await getBroker(ticket.brokerId);

        // Obtengo los comentarios asociados al ticket
        await getCommentsFromTicket(id);

        await getAllCasesByTicketId(id);

        // Obtengo el registro de archivos enviados por el ticket
        await getFilesRecordsFromTicket(id);

        // Obtengo los grupos que pertenece el asegurado

        // Obtengo las las polizas junto con su compania aseguradora

        // // Creo la data para pasarsela a la tabla principal descriptiva
        // const data_to_table = {
        //   name:ticket.value.employee.firstName,
        //   last_name:ticket.value.employee.firstSurname,
        //   rut:ticket.value.employee.rut,
        //   company:ticket.value.company.businessName,
        //   insurance:insurance_companies.value,
        //   policies:policies.value,
        //   endDate:(parseDate(ticket.value.endDate)).substring(0,10)
        // };

        // crearDataToTable(data_to_table);

        // Actualizar data para el stepper
        await stepperData(id);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getData(id_baja);
    });

    // * Crear data para la tabla principal
    const data_to_table = computed(() => {
      return [
        {
          key: 'Nombre',
          value: `${ticket?.employee?.firstName} ${ticket?.employee?.firstSurname}`
        },
        {
          key: 'RUT',
          value: ticket.employee ? rutHelper.formatRut(ticket.employee.rut) : ''
        },
        {
          key: 'Empresa',
          value: ticket.company ? ticket.company.businessName : ''
        },
        {
          key: 'Aseguradora',
          value: ticket.insurance ? ticket.insurance : ''
        },
        {
          key: 'Pólizas',
          value: ticket.policies ? ticket.policies : ''
        },
        {
          key: 'Fecha de término',
          value: ticket.endDate
        },
        {
          key: 'Broker',
          value: brokerName.value || ''
        }
      ];
    });

    return {
      finishStep,
      stepper_props,
      stepper_ref,
      loading,
      data_to_table,
      ordered_records,
      comments_ordered,
      getNewCommment,
      id_baja,
      routes,
      header_state_data,
      openCancelModal,
      openExecuteModal,
      user_role,
      showOnlyCancel,
      ticket_state_id,
      records_steps,
      records_comments,
      loadTicket,
      loadingHistory,
      cases,
      redirectCase,
      updateEndDateVisible,
      newEndDate,
      updateEndDate
    };
  }
};
</script>
