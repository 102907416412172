<template>
  <div>
    <a-modal
      v-model:visible="modal_visible"
      :title="title"
      :closable="!loading"
      :maskClosable="!loading"
      centered
    >
      <a-typography-title :level="5"
        >¿Estás seguro que deseas aprobar la solicitud de reproceso de cobranza?
      </a-typography-title>
      <a-row class="mt-3 mb-4">
        <a-col :span="3">
          <exclamation-circle-outlined
            :style="{ fontSize: '30px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            {{ main_text }}
          </a-typography-text>
        </a-col>
      </a-row>

      <template #footer>
        <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
        <a-button :loading="loading" @click="aproveReprocess" type="primary"
          >Sí, aprobar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
import { GET_LOGGED_USER, POST_CHANGE_REPROCESS_STATE } from '@/store/types';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  props: {
    reprocessType: {
      type: Number,
      default: 1
    },
    reprocessId: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const modal_visible = ref(false);
    const loading = ref(false);
    const store = useStore();
    const title = computed(() => {
      const types = {
        1: 'Aprobar reproceso por inconsistencia',
        2: 'Aprobar reproceso por anulación de factura',
        3: 'Aprobar Reproceso por Cambio y/o adicionar'
      };

      return types[props.reprocessType];
    });

    const main_text = computed(() => {
      let text =
        'Para aprobar una solicitud de reproceso, la compañía aseguradora debe haber concordado con las inconsistencias y haber enviado una nueva nómina de cobranza';
      if (props.reprocessType === 3) {
        text =
          'Para aprobar una solicitud de reproceso, la compañía aseguradora debe haber concordado con los cambios solicitados y haber enviado a una nueva nómina de cobranza';
      }

      return text;
    });

    const openModal = () => {
      modal_visible.value = true;
    };

    const closeModal = () => {
      modal_visible.value = false;
    };

    const aproveReprocess = async () => {
      loading.value = true;
      try {
        const payload = {
          insurance_resolution: 'test',
          state: 2, // 2 -> Aprobado
          brokerId: store.getters[GET_LOGGED_USER].id
        };

        await store.dispatch(POST_CHANGE_REPROCESS_STATE, {
          id: props.reprocessId,
          payload
        });

        notification('success', 'Correcto', 'Reproceso Aprobado.');
        emit('aprovedReprocess', true);
        closeModal();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    return {
      modal_visible,
      title,
      main_text,
      loading,
      aproveReprocess,
      openModal,
      closeModal
    };
  }
};
</script>
