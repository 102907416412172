<template>
  <div>
    <page-header :breadcrumRoutes="[
      {
        breadcrumbName: 'Cobranza y facturación / Ticket'
      }
    ]" title="Ticket de cobranza">
      <template #tags>
        <a-tag :color="getCurrentStateColor(current_ticket_state)">
          {{ getCurrentStateName(current_ticket_state) }}
        </a-tag>
        <a-tag color="processing">
          ID: {{ params_id }}
        </a-tag>
        <a-tag v-if="reprocess_records.length > 1" class="tag-pointer" color="processing"
          @click="openReprocessRecordRef">
          <span>Reproceso: <b>{{ reprocess_records.length - 1 }}</b></span>
        </a-tag>
      </template>
      <template v-if="user_role != 'rrhh'" #buttons>
        <div v-if="is_collection_resource_id === false">
          <a-row :gutter="8">
            <a-col>
              <a-button v-if="state_button == 'Validar'" :loading="processingLoading" type="primary"
                @click="openValidateModal">
                Validar
              </a-button>
            </a-col>
            <a-col>
              <a-button v-if="state_button == 'Enviar a RRHH'" @click="openRRHHModal" type="primary"
                :loading="loadingHumandsend">
                Enviar a RRHH
              </a-button>
            </a-col>
            <a-col>
              <a-button v-if="state_button == 'Aprobar cobranza'" type="primary" @click="openAprovalCollectionModal"
                :loading="loadingHumandsend">
                Aprobar cobranza
              </a-button>
            </a-col>
            <a-col>
              <a-button v-if="reprocessAprovedValid" type="primary" @click="openReopencollectionModal"
                :loading="loadingHumandsend || processingLoading">
                Reabrir Cobranza
              </a-button>
            </a-col>
          </a-row>
          <a-row v-if="state_button == 'Procesando'">
            <a-col>
              <a-button type="primary" :loading="processingLoading">
                Procesando
              </a-button>
            </a-col>
          </a-row>
        </div>
      </template>
    </page-header>

    <main-stepper :stepper-data="stepper_data" />

    <billing-detail v-if="collection_ticket_data.type === 'COLLECTION' ||
      (collection_ticket_data.type === 'PRECOLLECTION' &&
        stepper_data.current === 3)
    " ref="billing_detail" :collectionTicketId="params_id" :states="last_collection_state" />

    <a-card>
      <div>
        <a-typography-title :level="4" style="display: inline-block">
          Datos ticket</a-typography-title>
        <a-tag style="display: inline-block" color="processing" class="ml-3">{{
          collection_ticket_data.type === 'COLLECTION'
            ? 'COBRANZA'
            : 'PRE-COBRANZA'
        }}</a-tag>
      </div>

      <a-row :gutter="16">
        <a-col :span="12">
          <ticket-data :data="ticket_data" />

          <associate-reprocesses v-if="user_role != 'rrhh'" :hide-creation="is_collection_resource_id" :period="collection_ticket_data.period != undefined
            ? collection_ticket_data.period
            : ''
            " :ticket-id="params_id" :policies="collection_ticket_data.policies" :data="reprocess_list"
            @reprocessCreated="reprocessCreated" :states="last_collection_state" />

          <associate-requests v-if="user_role != 'rrhh'" :hide-creation="is_collection_resource_id" class="mt-3"
            :data="requests_list" :ticket-id="params_id" @requestCreated="requestCreated"
            :states="last_collection_state" />

          <comments v-if="user_role != 'rrhh'" class="mt-3" :comments-list="comments" title="Comentarios ticket"
            :loading="loading_comments" @createComment="createComment"
            :hide-comment-section="is_collection_resource_id" />
        </a-col>
        <a-col :span="12">
          <records v-if="user_role != 'rrhh'" title="Historial" :data="ordered_records" />

          <associate-inconsistency :class="{ 'mt-3': user_role != 'rrhh' }" @updateInconsistency="updateInconsistency"
            :squaringCollectionInsurance="squaringCollectionInsurance"
            :loadingsquaringValidate="loadingsquaringValidate" @createSquaringValidate="createSquaringValidate"
            :states="collection_ticket_data" :hide-adjust-payroll-button="is_collection_resource_id" />

          <retroactive-adjustment class="mt-3" :ticket-id="params_id" v-if="user_role != 'rrhh'" />

          <attached-document class="mt-3" v-if="user_role != 'rrhh'" :data="adjustment_documents" />
        </a-col>
      </a-row>
    </a-card>

    <div v-if="user_role != 'rrhh'">
      <modal-validate ref="validate_modal_ref" :loading="loadingsquaringValidate"
        @createSquaringValidate="createSquaringValidate" :data="ticket_data" :ticket-id="params_id" />
      <aproval-modal ref="aproval_modal_ref" />

      <!-- Modal de envio a rrhh -->
      <rrhh-modal ref="rrhh_modal_ref" :data="collection_ticket_data" :ticket-id="params_id"
        :collection-type="collection_ticket_data.type" />

      <!-- Modal de aprobacion colection -->
      <collection-aproval-modal ref="coll_aproval_modal_ref" :ticket-id="params_id"
        :collection-type="collection_ticket_data.type" @aprovedCollection="aprovedCollection" />

      <collection-aproval-modal-precollection ref="col_aprov_modal_precol" :period="collection_ticket_data.period"
        :ticket-id="params_id" :company-names="company_names" @aprovedCollection="aprovedCollection" />

      <reopen-modal :ticket-id="params_id" ref="reopen_modal_ref" :policy-number="ticket_data[5].value"
        @uploadedFile="uploadedFile" @reopenCollectionSuccess="reopenCollectionSuccess" />

      <reprocess-records ref="reprocess_record_ref" :data="reprocess_records" :ticket-id="params_id" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import BillingDetail from '@/views/brokers/collection-and-billing/processes/collection-groups/BillingDetail.vue';
import EventBus from '@/utils/events';
import stepper from '@/components/CollectionBillingStepper.vue';
import TicketData from '@/components/Tickets/TicketData.vue';
import AssociatedReprocesses from '@/components/collection and billing/reprocesses and requests/associate/AssociateReprocesses.vue';
import AssociateRequests from '@/components/collection and billing/reprocesses and requests/associate/AssociateRequests.vue';
import RetroactiveAdjustment from '@/components/collection and billing/reprocesses and requests/adjustment/RetroactiveAdjustment.vue';
import AttachedDocument from '@/components/collection and billing/reprocesses and requests/AttachedDocument.vue';
import COllectionTicketValidate from '@/components/collection and billing/collectionTicket/collectionTIcketValidate.vue';
import CollectionTicketAproval from '@/components/collection and billing/collectionTicket/CollectionTicketAproval.vue';
import CollectionTicketRRHHSend from '@/components/collection and billing/collectionTicket/CollectionTicketRRHHSend.vue';
import CollectionTicketCollectionAproval from '@/components/collection and billing/collectionTicket/CollectionTicketCollectionAproval.vue';
import CollectionTicketReopenCollection from '@/components/collection and billing/collectionTicket/CollectionTicketReopenCollection.vue';
import ReprocessRecords from '@/components/collection and billing/reprocesses and requests/reprocess/components/ReprocessRecords.vue';
import AssociateInconsistency from '@/components/collection and billing/reprocesses and requests/inconsistency/AssociateInconsistency.vue';
import CommentsLists from '@/components/CommentsList.vue';
import CollectionTicketCollectionAprovalPrecollection from '@/components/collection and billing/collectionTicket/CollectionTicketCollectionAprovalPrecollection.vue';
import {
  FETCH_COLLECTIONS,
  FETCH_REPROCESS_BY_COLLECTION_ID,
  FETCH_REQUESTS_BY_COLLECTION_ID,
  FETCH_FILES_COLLECTION_TICKETS,
  GET_REQUESTS_BY_COLLECTION_ID,
  GET_REPROCESS_BY_COLLECTION_ID,
  GET_LOGGED_USER,
  GET_FILES_COLLECTION,
  FETCH_COLLECTION_COMMENTS,
  FETCH_USER_BY_ID,
  GET_COLLECTION_COMMENTS,
  POST_COLLECTION_COMMENTS,
  GET_COLLECTIONS,
  GET_COLLECTION_REPROCESS_RECORDS,
  FETCH_COLLECTION_REPROCESS_RECORDS,
  POST_SQUARING_COLLECTION_VALIDATE,
  FETCH_COLLECTION_INCONSISTENCIES,
  GET_COLLECTION_INCONSISTENCIES
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';
import notification from '@/utils/notifications';
import Records from '@/components/Tickets/Records.vue';
import PageHeader from '@/components/PageHeader.vue';
import getTagText from '@/utils/getTagText';
import getTagColor from '@/utils/getTagColor';

export default {
  name: 'CollectionTicketDetail',
  components: {
    'main-stepper': stepper,
    'billing-detail': BillingDetail,
    'ticket-data': TicketData,
    'associate-reprocesses': AssociatedReprocesses,
    'associate-requests': AssociateRequests,
    'retroactive-adjustment': RetroactiveAdjustment,
    'attached-document': AttachedDocument,
    comments: CommentsLists,
    records: Records,
    'page-header': PageHeader,
    'modal-validate': COllectionTicketValidate,
    'aproval-modal': CollectionTicketAproval,
    'rrhh-modal': CollectionTicketRRHHSend,
    'collection-aproval-modal': CollectionTicketCollectionAproval,
    'reopen-modal': CollectionTicketReopenCollection,
    'reprocess-records': ReprocessRecords,
    'associate-inconsistency': AssociateInconsistency,
    'collection-aproval-modal-precollection':
      CollectionTicketCollectionAprovalPrecollection
  },
  setup() {
    // * Variables
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const params_id = parseInt(route.params.id);
    const user_role = store.getters[GET_LOGGED_USER].roleName;
    const state_button = ref('');
    const processingLoading = ref(false);
    const loadingsquaringValidate = ref(false);
    const squaringCollectionInsurance = ref([]);
    const reprocess_aproved_valid = computed(() => {
      const inconsistency_status = getInconsistency.value?.status;
      const exists_aproved = reprocess_list.value.some(
        (obj) => obj.tags === 'APPROVED'
      );
      const valid_inconsistency_state =
        inconsistency_status != 'NO_PROCESSED' &&
        inconsistency_status != 'PROCESSING';
      const valid_ticket_state = last_collection_state.value != 'APPROVED';
      return exists_aproved && valid_inconsistency_state && valid_ticket_state;
    });
    const INCONSISTENCY_INTERVAL_SECONDS = 3000;
    const loadingHumandsend = ref(false);
    const button_function = ref();
    const reprocessAprovedValid = ref(false);

    const collection_ticket_data = computed(
      () => store.getters[GET_COLLECTIONS]
    );

    // * Page header
    const getCurrentStateName = (name) => {
      return name != undefined ? getTagText(name)?.toUpperCase() : '';
    };

    const getCurrentStateColor = (name) => {
      const factored_name = getCurrentStateName(name);
      return factored_name != undefined ? getTagColor(factored_name) : '';
    };

    const current_ticket_state = computed(() => {
      const { states, isReprocessing } = store.getters[GET_COLLECTIONS];
      if (isReprocessing === false) {
        return states != undefined ? states[states?.length - 1].state : '';
      }
      return 'En Reproceso';
    });

    // * Historial de reprocesos
    const reprocess_record_ref = ref();
    const openReprocessRecordRef = () => {
      reprocess_record_ref.value.openModal();
    };

    const reprocess_records = computed(() => {
      return store.getters[GET_COLLECTION_REPROCESS_RECORDS].map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          reprocess: obj.name,
          fileName: obj.objectFilename,
          date: parseDate(obj.createdAt),
          detail: obj.id
        };
      });
    });

    const getReprocessRecords = async () => {
      try {
        if (user_role != 'rrhh') {
          await store.dispatch(FETCH_COLLECTION_REPROCESS_RECORDS, params_id);
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const uploadedFile = () => {
      getReprocessRecords();
      billing_detail.value.getBillingDetailList();
    };

    // * Stepper
    const fetchCollectionTicketData = (query = '') => {
      store
        .dispatch(FETCH_COLLECTIONS, {
          collection_id: params_id,
          query_params: query
        })
        .catch(() => router.push({ name: 'Missing' }));
    };

    const show_reopen_collection_button = computed(() => {
      const aproved_reprocess = reprocess_list.value.some(
        (obj) => obj.tags === 'APPROVED'
      );
      const valid_state =
        stepper_data.value.current === 1 || stepper_data.value.current === 2;

      return aproved_reprocess && valid_state;
    });

    const stepper_data = computed(() => {
      const props = {
        current: 0,
        state: 'process',
        steps: [
          {
            title: 'Iniciada',
            date: '',
            key: 1
          },
          {
            title: 'Validación corredora',
            date: '',
            key: 2
          },
          {
            title: 'Validación RRHH',
            date: '',
            key: 3
          },
          {
            title: 'Aprobada',
            date: '',
            key: 4
          }
        ]
      };
      const { states } = collection_ticket_data.value;

      if (states != undefined) {
        const state_id = {
          CREATED: 0,
          BEWELL_VALIDATION: 1,
          HR_VALIDATION: 2,
          APPROVED: 3,
          CANCELED: 4
        };
        states.forEach((obj) => {
          const formatted_date = parseDate(obj.createdAt);
          props.steps[state_id[obj.state]].date = formatted_date;
          switch (obj.state) {
            case 'APPROVED':
              props.state = 'finish';
              break;
            case 'CANCELED':
              props.state = 'error';
              break;
          }
        });

        const last_state = states[states?.length - 1].state;
        props.current = state_id[last_state];
      }
      return props;
    });

    const last_collection_state = computed(() => {
      const { states } = collection_ticket_data.value;
      if (states != undefined) {
        return states[states.length - 1].state;
      }
      return '';
    });

    // * Historial
    const ordered_records = computed(() => {
      /*
      - Cuando se cambia de estado
      - Cuando se crea un reproceso) (link al reproceso)
      - Cuando se crea una solicitud (link a la solicitud)
      - Cuando se deja un comentario
      - Cuando se marca pagada una factura
      */
      let final_values = [];
      const addValues = (new_values) => {
        if (new_values != undefined) {
          final_values = [...final_values, ...new_values];
        }
      };

      // Estados
      const { states } = store.getters[GET_COLLECTIONS];
      const states_names = {
        CREATED: 'Iniciada',
        BEWELL_VALIDATION: 'Validación corredora',
        HR_VALIDATION: 'Validación RRHH',
        APPROVED: 'Aprobada',
        CANCELED: 'Cancelada'
      };

      const refactored_states = states?.map((state) => {
        if (states_names[state.state] == 'Aprobada') {
          return {
            title: states_names[state.state],
            description: state.createdAt,
            status: 'finish'
          };
        }
        return {
          title: states_names[state.state],
          description: state.createdAt
        };
      });

      addValues(refactored_states);

      // Lista de reprocesos
      const refactored_reprocess = [];
      store.getters[GET_REPROCESS_BY_COLLECTION_ID]?.forEach((rep) => {
        const { id } = rep;
        const { type } = rep;
        const name = getReprocessTypeName(rep.type);
        const states = rep.reprocessStates;

        const states_titles = {
          IN_INSURANCE: `Creación de reproceso por ${name}`,
          CANCELED: `Cancelación de reproceso por ${name}`,
          APPROVED: `Aprobación de reproceso por ${name}`,
          REJECTED: `Rechazo de reproceso por ${name}`
        };

        refactored_reprocess.push(
          ...states.map(({ state, createdAt }) => {
            return {
              title: states_titles[state],
              description: createdAt,
              redirectionRoute: {
                name: getRouteNameByReprocessType(type),
                params: { id }
              }
            };
          })
        );
      });

      addValues(refactored_reprocess);

      // Lista de solicitud
      const refactored_request = [];
      store.getters[GET_REQUESTS_BY_COLLECTION_ID]?.forEach((rep) => {
        const { id } = rep;
        const { type } = rep;
        const name = getRequestsTypeName(rep.type);
        const states = rep.requestStates;

        const states_titles = {
          IN_INSURANCE: `Creación de solicitud por ${name}`,
          CANCELED: `Cancelación de solicitud por ${name}`,
          APPROVED: `Aprovación de solicitud por ${name}`,
          REJECTED: `Rechazo de solicitud por ${name}`
        };

        refactored_request.push(
          ...states.map(({ state, createdAt }) => {
            return {
              title: states_titles[state],
              description: createdAt,
              redirectionRoute: {
                name: getRouteNameByRequestType(type),
                params: { id }
              }
            };
          })
        );
      });

      addValues(refactored_request);

      // Comentarios
      const comments_list = comments?.value;
      const refactored_comments = comments_list?.map((m) => {
        return {
          title: 'Comentario creado',
          description: m.date
        };
      }, []);

      addValues(refactored_comments);

      const sorted_values = final_values.sort(
        (a, b) => Date.parse(a.description) - Date.parse(b.description)
      );
      const dates_parsed = sorted_values.map((state) => {
        return {
          ...state,
          description: parseDate(state.description)
        };
      });

      return dates_parsed;
    });

    // * Defalle facturacion
    const billing_detail = ref();

    // * Datos ticket
    const ticket_data = computed(() => {
      const {
        period,
        holding,
        subHoldings,
        companies,
        insuranceCompany,
        policies
      } = collection_ticket_data.value;

      return [
        {
          key: 'Periodo cobranza',
          value: period
        },
        {
          key: 'Holding',
          value: holding
        },
        {
          key: 'Subholdings',
          value: subHoldings
            ?.filter((e, i) => {
              if (i <= 2) {
                return e;
              }
            })
            ?.join(',')
            .concat(
              subHoldings.length >= 2 ? ` +${subHoldings.length - 3}...` : []
            )
        },
        {
          key: 'Empresas',
          value: companies
            ?.map((e) => e.businessName)
            .filter((e, i) => {
              if (i <= 2) {
                return [e.businessName];
              }
            })
            ?.join(',')
            .concat(
              [companies?.businessName].length >= 2
                ? ` +${[companies?.businessName].length - 3}...`
                : []
            )
        },
        {
          key: 'Aseguradora',
          value: insuranceCompany?.businessName
        },
        {
          key: 'Polizas',
          value: policies
            ?.filter((e, i) => {
              if (i <= 2) {
                return e;
              }
            })
            ?.join(',')
            .concat(policies.length >= 2 ? ` +${policies.length - 3}...` : [])
        },
        {
          key: 'Correo aseguradoras',
          value: insuranceCompany?.collectionEmails
            .map((e) => e)
            .filter((e, i, arr) => arr.indexOf(e) === i)
            .join(',\n')
        },
        {
          key: 'Correo clientes',
          value: companies
            ?.map((e) => e.collectionEmails)
            .flat()
            .filter((e, i, arr) => arr.indexOf(e) == i)
            .join(',\n')
        }
      ];
    });

    const company_names = computed(() => {
      const { companies } = collection_ticket_data.value;
      if (companies != undefined) {
        const company_array = companies.map((comp) => comp.businessName);
        return company_array.length > 2
          ? `${[company_array[0], company_array[1]].join(', ')} +${company_array.length - 2
          }`
          : company_array.join(', ');
      }
      return '';
    });

    // * Reprocesos asociados
    const getReprocesses = (query = '') => {
      store
        .dispatch(FETCH_REPROCESS_BY_COLLECTION_ID, {
          collection_id: params_id,
          query_params: query
        })
        .catch((err) =>
          notification('error', 'Error', err.response.data.message)
        );
    };

    const reprocess_list = computed(() => {
      return store.getters[GET_REPROCESS_BY_COLLECTION_ID].map((obj, index) => {
        return {
          key: index,
          name: getReprocessTypeName(obj.type),
          tags: obj.state,
          created_at: obj.reprocessStates[0].createdAt,
          redirection: {
            title: 'Ver reproceso',
            route: {
              name: getRouteNameByReprocessType(obj.type),
              params: { id: obj.id }
            }
          }
        };
      });
    });

    const getReprocessTypeName = (value) => {
      switch (value) {
        case 'REPROCESS_FOR_INCONSISTENCY':
          return 'Inconsistencia';
        case 'REPROCESS_FOR_INVOICE_CANCEL':
          return 'Anulación Facturación';
        default:
          return 'Cambio/Adicional';
      }
    };

    const getRouteNameByReprocessType = (type) => {
      switch (type) {
        case 'REPROCESS_FOR_INCONSISTENCY':
          return 'InconsistencyDetail';
        case 'REPROCESS_FOR_INVOICE_CANCEL':
          return 'AnnulmentDetail';
        default:
          return 'ChangeAndOrAditionalDetail';
      }
    };

    const reprocessCreated = () => {
      const query_params = getParamsQuery();

      getReprocesses(query_params);
      fetchCollectionTicketData(query_params);
    };

    // * Documento adjuntos
    const getAttachedDocuments = async (params = '') => {
      try {
        if (user_role != 'rrhh') {
          await store.dispatch(FETCH_FILES_COLLECTION_TICKETS, {
            collection_id: params_id,
            query_params: params
          });
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const adjustment_documents = computed(() => {
      return store.getters[GET_FILES_COLLECTION].map((obj, index) => {
        return {
          name: obj.objectFilename,
          file_name: obj.objectFilename,
          url: obj.objectUrl,
          key: index
        };
      });
    });

    // * Solicitudes asociadas
    const requests_list = computed(() => {
      return store.getters[GET_REQUESTS_BY_COLLECTION_ID].map((obj, index) => {
        return {
          key: index,
          name: getRequestsTypeName(obj.type),
          tags: obj.state,
          created_at: obj.requestStates[0].createdAt,
          redirection: {
            title: 'Ver solicitud',
            route: {
              name: getRouteNameByRequestType(obj.type),
              params: { id: obj.id }
            }
          }
        };
      });
    });

    const getRequests = (query = '') => {
      store
        .dispatch(FETCH_REQUESTS_BY_COLLECTION_ID, {
          collection_id: params_id,
          query_params: query
        })
        .catch((err) =>
          notification('error', 'Error', err.response.data.message)
        );
    };

    const getRequestsTypeName = (value) => {
      switch (value) {
        case 'RETROACTIVE_ADJUSTMENT_REQUEST':
          return 'Ajuste retroactivo';
        case 'CREDIT_NOTE_REQUEST':
          return 'Nota de crédito';
        case 'DEBIT_NOTE_REQUEST':
          return 'Nota de débito';
        default:
          return 'Nota de débito';
      }
    };

    const getRouteNameByRequestType = (type) => {
      switch (type) {
        case 'RETROACTIVE_ADJUSTMENT_REQUEST':
          return 'RetroActiveAdjustmentDetail';
        case 'CREDIT_NOTE_REQUEST':
          return 'CreditNoteDetail';
        case 'DEBIT_NOTE_REQUEST':
          return 'DebitNoteDetail';
        default:
          return 'RetroActiveAdjustmentDetail';
      }
    };

    const requestCreated = () => {
      const query_params = getParamsQuery();

      getRequests(query_params);
      fetchCollectionTicketData(query_params);
    };

    const getBroker = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };


    // * Comentarios
    const comments = ref([]);
    const getComments = async (query = '') => {
      await store.dispatch(FETCH_COLLECTION_COMMENTS, {
        collection_id: params_id,
        param: query
      });
      for (const comment of store.getters[GET_COLLECTION_COMMENTS]?.data || []) {
        const author = await getBroker(comment.brokerId);
        comments.value.push({
          author,
          description: parseDate(comment.createdAt),
          date: comment.createdAt,
          content: comment.message
        });
      }
    };

    const loading_comments = ref(false);
    const createComment = async (comment) => {
      loading_comments.value = true;
      try {
        const created_comment = {
          brokerId: store.getters[GET_LOGGED_USER].id,
          message: comment
        };
        await store.dispatch(POST_COLLECTION_COMMENTS, {
          id: params_id,
          payload: created_comment
        });

        notification('success', 'Correcto', 'Comentario creado');
        await getComments();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_comments.value = false;
    };

    // * Modal Validar
    const validate_modal_ref = ref();
    const openValidateModal = () => {
      validate_modal_ref.value.openModal();
    };

    // * Modal rrgg
    const rrhh_modal_ref = ref();
    const openRRHHModal = () => {
      rrhh_modal_ref.value.openModal();
    };

    // * Modal Aprovacion collection
    const coll_aproval_modal_ref = ref();
    const col_aprov_modal_precol = ref();
    const openAprovalCollectionModal = () => {
      if (collection_ticket_data.value.type === 'COLLECTION') {
        col_aprov_modal_precol.value.openModal();
      } else {
        coll_aproval_modal_ref.value.openModal();
      }
    };

    const createSquaringValidate = async (val) => {
      loadingsquaringValidate.value = true;
      try {
        await store.dispatch(POST_SQUARING_COLLECTION_VALIDATE, {
          collectionId: params_id,
          payload: val.force ? `?force=${val.force}` : ''
        });

        const query_params = getParamsQuery();
        fetchCollectionTicketData(query_params);
        updatedata(query_params);

        validate_modal_ref.value.closeModal();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loadingsquaringValidate.value = false;
    };

    const updatedata = async (params = '') => {
      try {
        const resp = await store.dispatch(FETCH_COLLECTION_INCONSISTENCIES, {
          collection_id: params_id,
          query_params: params
        });

        switch (resp.data.status) {
          case 'NO_PROCESSED':
            squaringCollectionInsurance.value = {
              ...resp.data,
              totalDiscrepancies: 0
            };
            break;
          case 'PROCESSING':
            // Como esta siendo procesado la cola, voy a dejar la data de squaring varia denuevo
            // pero dejo los botones cargando
            // y despues de 5 segundos vuelvo a llamar a este mismo metodo para ver si termino el proceso
            squaringCollectionInsurance.value = {
              ...resp.data,
              totalDiscrepancies: 0
            };
            processingLoading.value = true;
            loadingsquaringValidate.value = true;
            reprocessAprovedValid.value = false;
            setTimeout(async () => {
              await updatedata(params);
            }, INCONSISTENCY_INTERVAL_SECONDS);
            break;
          default:
            // Saco los loading
            processingLoading.value = false;
            loadingsquaringValidate.value = false;
            squaringCollectionInsurance.value = [resp.data]
              .map((e) => ({
                ...e,
                discrepancies: [e.discrepancies].map((d) => ({
                  ...d,
                  dependents: [d.dependents].map((dp) => ({
                    ...dp,
                    types: 'Cargas'
                  })),
                  employees: [d.employees].map((em) => ({
                    ...em,
                    types: 'Titulares'
                  }))
                })),
                status: e.status
              }))
              .map((rf) => ({
                ...rf,
                discrepancies: rf.discrepancies.map((rfd) => ({
                  ...rfd,
                  table_data: []
                    .concat(rfd.employees, rfd.dependents)
                    .map((td, i) => ({
                      ...td,
                      key: i
                    }))
                }))
              }))[0];
            break;
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const getInconsistency = computed(
      () => store.getters[GET_COLLECTION_INCONSISTENCIES]
    );

    const aproval_modal_ref = ref();
    const openAprovalModal = () => {
      aproval_modal_ref.value.openModal();
    };

    // * Modal de reabrir cobranza
    const reopen_modal_ref = ref();
    const openReopencollectionModal = () => {
      reopen_modal_ref.value.openModal();
    };

    const aprovedCollection = () => {
      getMainData();
    };

    const forceReSquaring = ref(false);
    const updateInconsistency = (val) => {
      forceReSquaring.value = val.force;
      if (loadingsquaringValidate.value == true) {
        setTimeout(() => {
          val.closeModal();
        }, 1000);
      }
    };

    const show_aproval_modal = computed(() => {
      // El estado del ticket esta en 'Validación de RR.HH'
      const validation1 = stepper_data.value.current === 2;
      // No existe ningun reproceso en estado 'IN_INSURANCE'
      const validation2 = !reprocess_list.value.some(
        (obj) => obj.tags === 'IN_INSURANCE'
      );
      // No existe ninguna solicitud en estado 'IN_INSURANCE'
      const validation3 = !requests_list.value.some(
        (obj) => obj.tags === 'IN_INSURANCE'
      );

      return validation1 && validation2 && validation3;
    });

    // * Obtencion de data principal
    const getParamsQuery = () => {
      const { query } = route;
      const start_query = Object.keys(route.query).length === 0 ? '' : '?';
      const query_params =
        start_query +
        Object.keys(query)
          .map((q) => `${q}=${query[q]}`)
          .join('&');
      return query_params;
    };

    const getMainData = () => {
      const query_params = getParamsQuery();

      fetchCollectionTicketData(query_params);
      getReprocesses(query_params);
      getComments(query_params);
      getRequests(query_params);
      getAttachedDocuments(query_params);
      getReprocessRecords();
      updatedata(query_params);
    };

    const is_collection_resource_id = computed(() => {
      return 'collectionResourceId' in route.query;
    });

    const reopenCollectionSuccess = () => {
      getMainData();
    };

    watch(state_button, (newVal) => {
      switch (newVal) {
        case 'Validar':
          button_function.value = () => openValidateModal();
          break;
        case 'Enviar a RRHH':
          button_function.value = () => openRRHHModal();
          break;
        case 'Aprobar cobranza':
          button_function.value = () => openAprovalCollectionModal();
          break;
        case 'Reabrir cobranza':
          button_function.value = () => openReopencollectionModal();
          break;
        default:
          button_function.value = () => { };
          break;
      }
    });

    watchEffect(() => {
      EventBus.on('loadingHumandSend', (val) => {
        loadingHumandsend.value = val;
      });

      reprocessAprovedValid.value = reprocess_list.value.some(
        (obj) => obj.tags === 'APPROVED'
      );

      if (reprocess_aproved_valid.value) {
        state_button.value = 'Reabrir Cobranza';
      }

      if (getInconsistency.value.status == 'NO_PROCESSED') {
        state_button.value = 'Validar';
        reprocessAprovedValid.value = false;
      }

      if (getInconsistency.value.status == 'PROCESSING') {
        state_button.value = 'Procesando';
        reprocessAprovedValid.value = false;
      }

      if (
        last_collection_state.value == 'BEWELL_VALIDATION' &&
        (getInconsistency.value.status === 'MATCH' ||
          getInconsistency.value.status === 'NO_MATCH')
      ) {
        state_button.value = 'Enviar a RRHH';
      }

      if (
        (show_aproval_modal.value && reprocess_aproved_valid.value) ||
        (last_collection_state.value == 'HR_VALIDATION' &&
          (getInconsistency.value.status === 'MATCH' ||
            getInconsistency.value.status === 'NO_MATCH'))
      ) {
        state_button.value = 'Aprobar cobranza';
      }

      if (last_collection_state.value == 'APPROVED') {
        state_button.value = '';
        stepper_data.value.state = 'finish';
        reprocessAprovedValid.value = false;
      }

      if (state_button.value == '') {
        reprocessAprovedValid.value = false;
      }
    });

    watch(
      () => route.query,
      (new_route) => {
        console.log('new_route =', new_route);
        getMainData();
      }
    );

    onMounted(() => {
      getMainData();
    });

    return {
      stepper_data,
      params_id,
      billing_detail,
      last_collection_state,
      collection_ticket_data,
      ticket_data,
      reprocess_list,
      reprocessCreated,
      user_role,
      requests_list,
      requestCreated,
      is_collection_resource_id,
      adjustment_documents,
      comments,
      loading_comments,
      createComment,
      ordered_records,
      getCurrentStateColor,
      getCurrentStateName,
      current_ticket_state,
      reprocess_records,
      getReprocessRecords,
      uploadedFile,
      state_button,
      processingLoading,
      validate_modal_ref,
      openValidateModal,
      loadingsquaringValidate,
      createSquaringValidate,
      openRRHHModal,
      rrhh_modal_ref,
      loadingHumandsend,
      coll_aproval_modal_ref,
      openAprovalCollectionModal,
      reprocess_aproved_valid,
      reopen_modal_ref,
      openReopencollectionModal,
      aprovedCollection,
      getInconsistency,
      forceReSquaring,
      updateInconsistency,
      show_aproval_modal,
      aproval_modal_ref,
      openAprovalModal,
      show_reopen_collection_button,
      reprocess_record_ref,
      openReprocessRecordRef,
      squaringCollectionInsurance,
      reopenCollectionSuccess,
      col_aprov_modal_precol,
      company_names,
      button_function,
      reprocessAprovedValid
    };
  }
};
</script>

<style>
.tag-pointer {
  cursor: pointer;
}
</style>
