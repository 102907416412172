<template>
  <div>
    <a-card class="mb-2">
      <div>
        <a-typography-title :level="4">
          Detalle facturación
        </a-typography-title>
        <a-typography-text>
          Aquí encontraras el detalle y pago de las facturas asociadas al ticket
          de cobranza
        </a-typography-text>
      </div>

      <detail-billing class="mt-3" :dataTable="table_data" :loadingData="loading_data" :routeName="$route.name">
        <template #details="{ record }">
          <a-row>
            <a-col :span="8">
              <a-tooltip :color="'#05045D'" placement="topLeft" title="Ver más">
                <a-button :style="{ margin: '-9px' }" type="link" @click="openPdfViewer(record.objectUrl)">
                  <eye-outlined />
                </a-button>
              </a-tooltip>
            </a-col>

            <a-col :span="8" v-if="user_role != 'rrhh'">
              <a-tooltip v-if="stateApproved == 'APPROVED'" :color="'#05045D'" placement="topLeft" title="Pagar">
                <a-button :style="{ margin: '-9px' }" type="link" @click="handlePaymentModal(record)">
                  <credit-card-outlined />
                </a-button>
              </a-tooltip>
            </a-col>
          </a-row>
        </template>

        <!-- estado -->
        <template #invoiceNumber="{ record }">
          {{ record.folio }}
          <a-tag style="margin-left: 2px" v-for="(tag, i) in record.tags" :key="i" :color="'warning'">{{
            tag == 'HAS_CREDIT_NOTE'
              ? 'NC'
              : '' || tag == 'HAS_DEBIT_NOTE'
                ? 'ND'
                : ''
          }}</a-tag>
        </template>
      </detail-billing>

      <!-- PAGO MODAL -->
      <a-modal v-model:visible="visible_payment_modal" title="Pago de factura" centered>
        <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">
          {{
            `¿Estás seguro que deseas marcar como pagada la factura N° ${invoiceNumber} asociada a este ticket de
          cobranza?.`
          }}
        </a-typography-title>
        <a-row class="mb-4">
          <a-col :span="3">
            <exclamation-circle-outlined :style="{ fontSize: '35px', color: '#FFBF01' }" />
          </a-col>
          <a-col :span="20">
            <a-typography-text class="text-gray-8">
              Al confirmar el pago de está factura se le informará a la compañía
              aseguradora.
            </a-typography-text>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
              <a-form-item required name="invoicePaymentDate" label="Seleccione fecha de pago de factura">
                <a-date-picker placeholder="DD-MM-AAAA" v-model:value="formState.invoicePaymentDate"
                  :style="{ width: '100%' }" :format="dateFormat" />
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
        <template #footer>
          <a-button key="back" @click="handlecloseModal" :disabled="loadingModalCancel">Volver atrás</a-button>
          <a-button key="submit" :loading="loadingModal" :disabled="formState.invoicePaymentDate == null" type="primary"
            @click="handleSendPayment">Sí, confirmar</a-button>
        </template>
      </a-modal>
    </a-card>

    <pdf-viewer ref="pdf_ref" />
  </div>
</template>

<script>
import { onMounted, ref, reactive, watch, computed } from 'vue';
import {
  EyeOutlined,
  CreditCardOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import moment from 'moment';
import { GET_LOGGED_USER } from '@/store/types';
import PDFViewer from '@/components/PDFViewer.vue';
import DetailBilling from '@/components/Tables/DetailBilling.vue';
import {
  FETCH_BILLING_DETAIL_LIST,
  PATCH_MARK_INVOICE_PAID
} from '@/store/types';
import stateColor from '@/utils/collectionBillingStateColor';
import notification from '@/utils/notifications';

export default {
  name: 'BillingDetail',
  components: {
    'detail-billing': DetailBilling,
    'eye-outlined': EyeOutlined,
    'credit-card-outlined': CreditCardOutlined,
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'pdf-viewer': PDFViewer
  },
  props: {
    collectionTicketId: {
      type: Number,
      default: 1
    },
    states: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const user_role = store.getters[GET_LOGGED_USER].roleName;
    const table_data = ref([]);
    const loading_data = ref(false);
    const invoiceNumber = ref('');
    const invoiceId = ref();
    const loadingModalCancel = ref(false);
    const dateFormat = 'DD-MM-YYYY';
    const dataPaid = ref();
    const stateApproved = computed(() => props.states);
    const formState = reactive({
      invoicePaymentDate: null
    });
    const formRef = ref();

    const getBillingDetailList = async () => {
      loading_data.value = true;

      const { query } = route;
      const start_query = Object.keys(route.query).length === 0 ? '' : '?';
      const query_params =
        start_query +
        Object.keys(query)
          .map((q) => `${q}=${query[q]}`)
          .join('&');

      try {
        const resp = await store.dispatch(FETCH_BILLING_DETAIL_LIST, {
          collection_id: props.collectionTicketId,
          query: query_params
        });
        table_data.value = resp?.data?.rows.map((obj, index) => {
          return {
            ...obj,
            id: obj.id,
            key: index,
            numeroFactura: obj.folio,
            numeroPoliza: obj.policies.map((e) => e.numberPolicy).join(', '),
            fechaEmision: obj.issueDate,
            state: obj.paidAt == null ? 'Emitida' : 'Pagada',
            actionDetails: ''
          };
        });
      } catch (error) {
        console.log(error);
      }
      loading_data.value = false;
    };

    // * Modal
    const visible_payment_modal = ref(false);
    const loadingModal = ref(false);

    const handlePaymentModal = (data) => {
      invoiceNumber.value = data.folio;
      invoiceId.value = data.id;
      dataPaid.value = data;
      visible_payment_modal.value = true;

      // si esta en pagada me salga esa notificacion
      if (data.state == 'Pagada') {
        notification(
          'warning',
          'Atencion',
          'Esta factura ya ha sido marcada como pagada. Sin embargo, si necesitas ajustar la fecha de pago, aún puedes hacerlo.',
          12
        );
      }

      // si existe la fecha que inicie el modal con la fecha que habia guardado
      if (data.paidAt == null) {
        formState.invoicePaymentDate = null;
      } else {
        formState.invoicePaymentDate = moment(data.paidAt, 'YYYY-MM-DD');
      }
    };

    const handlecloseModal = () => {
      visible_payment_modal.value = false;
      loadingModalCancel.value = false;
    };

    const handleSendPayment = async () => {
      loadingModalCancel.value = true;
      loadingModal.value = true;
      try {
        await store.dispatch(PATCH_MARK_INVOICE_PAID, {
          invoiceId: invoiceId.value,
          date: { paidAt: formState.invoicePaymentDate }
        });
        getBillingDetailList();
        formState.invoicePaymentDate = null;
        if (dataPaid.value.state !== 'Pagada') {
          notification('success', 'Correcto', 'La factura ya ha sido pagada');
        } else {
          notification(
            'success',
            'Correcto',
            'La fecha de pago de la factura ah sido editada'
          );
        }
        loadingModal.value = false;
      } catch (error) {
        loadingModal.value = false;
        console.log('error', error);
        notification(
          'error',
          'Error',
          'Ah surgido un problema con el pago de la factura'
        );
      }
      // table_data.value.map((e) => (e.state = 'Pagada'));
      handlecloseModal();
    };
    const getColorState = (value) => {
      return stateColor(value);
    };
    const rules = {
      invoicePaymentDate: [
        {
          required: true,
          type: 'object',
          trigger: 'change',
          message: 'Seleccione una fecha'
        }
      ]
    };
    watch(formState, (val) => {
      if (val.invoicePaymentDate == null) {
        loadingModal.value = true;
      } else {
        loadingModal.value = false;
      }
    });
    // * PDFViewer
    const pdf_ref = ref();
    const openPdfViewer = (url) => {
      pdf_ref.value.openModal(url);
    };

    // * Obtencion de data
    watch(
      () => route.query,
      () => {
        getBillingDetailList();
      }
    );

    onMounted(() => {
      getBillingDetailList();
    });

    return {
      table_data,
      loading_data,
      loadingModal,
      visible_payment_modal,
      handleSendPayment,
      handlePaymentModal,
      handlecloseModal,
      getBillingDetailList,
      getColorState,
      invoiceNumber,
      formState,
      formRef,
      rules,
      loadingModalCancel,
      invoiceId,
      dateFormat,
      openPdfViewer,
      pdf_ref,
      dataPaid,
      user_role,
      stateApproved
    };
  }
};
</script>
