export * from './auth/types';
export * from './user/types';
export * from './token/types';
export * from './broker/types';

export * from './ticket/common/types';
export * from './ticket/incorporation/types';
export * from './ticket/cases/types';
export * from './ticket/comments/types';
export * from './ticket/historyFileTicket/types';
export * from './ticket/insured/types';
export * from './ticket/modificaciones/types';
export * from './ticket/reapplication/types';
export * from './ticket/transfer/types';
export * from './ticket/dependants-incorporation/types';
export * from './ticket/dependents-exclusion/types';

export * from './clients/companies/types';
export * from './clients/mailReception/types';
export * from './clients/holdings/types';
export * from './clients/policies/types';
export * from './clients/groups/types';
export * from './clients/stock/types';
export * from './clients/subholdings/types';
export * from './employee/types';
export * from './aseguradoras/types';
export * from './ejecutivos/types';

export * from './stockGlobal/types';

export * from './tabs/types';

export * from './reset/types';
export * from './dashboard/dashboardRRHH/types';
export * from './dashboard/dashboardAdmin/types';
export * from './ticket/bajas/types';
export * from './ticket/altas/altaIndividual/types';
export * from './ticket/altas/altaMasivas/types';
export * from './ticket/datatable/types';

export * from './tables/types';

export * from './prueba/types';
export * from './cobranza y facturacion/reprocesses/inconsistencia/types';
export * from './cobranza y facturacion/reprocesses/cambio&Adicional/types';
export * from './cobranza y facturacion/reprocesses/anulacion/types';
export * from './cobranza y facturacion/reprocesses/types';
export * from './cobranza y facturacion/requests/creditNote/types';
export * from './cobranza y facturacion/requests/debitNote/types';
export * from './cobranza y facturacion/requests/retroActiveAdjustment/types';
export * from './cobranza y facturacion/requests/types';
export * from './cobranza y facturacion/procesos/types';
export * from './cobranza y facturacion/collectionTicket/types';

export * from './digitalForm/types';
export * from './dependents/types';

export const RESET_STATE = '/RESET_STATE';
