<template>
  <a-modal
    v-model:visible="modal_visible"
    :title="`Cancelar solicitud por ${text_title}`"
    :closable="!loading"
    :maskClosable="!loading"
    centered
  >
    <a-typography-title :level="5"
      >¿Estás seguro que desea cancelar la solicitud por {{ text_title }}
    </a-typography-title>
    <a-row class="mt-2 mb-4">
      <a-col :span="3">
        <exclamation-circle-outlined
          :style="{ fontSize: '38px', color: '#FFBF01' }"
        />
      </a-col>
      <a-col :span="20">
        <a-typography-text class="text-gray-8">
          Al cancelar la solicitud deberás informar a la compañía aseguradora
          los motivos para cancelar la solicitud
        </a-typography-text>
      </a-col>
    </a-row>
    <a-col>
      <a-typography-title :level="5" strong
        >Mensaje a compañía aseguradora</a-typography-title
      >
      <a-typography-text class="text-gray-8 fw-regular mb-2" :level="5">
        Escribe aquí los motivos por los cuales estás cancelando la solicitud
      </a-typography-text>
      <a-form
        ref="form_ref"
        :model="form_state"
        name="form_state"
        :rules="rules"
      >
        <a-form-item name="message_text">
          <a-textarea
            v-model:value="form_state.message_text"
            :autoSize="{ minRows: 4, maxRows: 8 }"
          />
        </a-form-item>
      </a-form>
    </a-col>
    <template #footer>
      <a-button @click="closeModal" :disabled="loading">Volver atrás</a-button>
      <a-button
        key="submit"
        @click="formValidation"
        :loading="loading"
        type="primary"
        >Confirmar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import notificiation from '../../../../../utils/notifications';
import {
  GET_LOGGED_USER,
  POST_CHANGE_REQUEST_STATE
} from '../../../../../store/types';

export default {
  props: {
    requestType: {
      type: Number,
      default: 1
    },
    requestId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  setup(props, { emit }) {
    const store = useStore();
    const loading = ref(false);
    const modal_visible = ref(false);
    const text_title = computed(() => {
      const types = {
        1: 'Ajuste retroactivo',
        2: 'Nota de crédito',
        3: 'Nota de débito'
      };

      return types[props.requestType];
    });

    const rules = {
      message_text: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ]
    };

    const form_ref = ref();
    const form_state = reactive({
      message_text: ''
    });

    const openModal = () => {
      modal_visible.value = true;
      form_state.message_text = `Estimada compañía aseguradora:\n\nA través del presente correo solicitamos cancelar la solicitud que existe por ${text_title.value} debido a  que existe un error en el requerimiento.\n\nUn cordial saludo`;
    };

    const closeModal = () => {
      modal_visible.value = false;
    };

    const formValidation = () => {
      form_ref.value
        .validate()
        .then(() => {
          cancelRequest();
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const cancelRequest = async () => {
      loading.value = true;
      try {
        const payload = {
          insurance_resolution: form_state.message_text,
          state: 4,
          brokerId: store.getters[GET_LOGGED_USER].id
        };

        await store.dispatch(POST_CHANGE_REQUEST_STATE, {
          id: props.requestId,
          payload
        });
        notificiation('success', 'Correcto', 'Solicitud Cancelada.');
        emit('cancelledRequest', true);
        closeModal();
        form_state.message_text = '';
        form_ref.value.resetFields();
      } catch (error) {
        notificiation('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    return {
      modal_visible,
      text_title,
      form_state,
      rules,
      form_ref,
      loading,
      formValidation,
      openModal,
      closeModal
    };
  }
};
</script>
