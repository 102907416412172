<template>
  <!-- Boton para crear un usuario -->

  <a-row type="flex" justify="space-between">
    <a-typography-title :level="4">Contraparte principal</a-typography-title>
    <a-button v-if="user_role === 'superadmin'" type="primary" class="new_group" @click="openModal">
      Nuevo usuario
    </a-button>
  </a-row>

  <div>
    <div v-if="loadingUsers">
      <a-skeleton active />
    </div>
    <div v-else>
      <div v-for="user in users" :key="user.id">
        <a-descriptions bordered>
          <a-descriptions-item label="Nombre" :span="3">
            {{ user.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Correo electrónico" :span="3">
            {{ user.email }}
          </a-descriptions-item>
          <a-descriptions-item label="Teléfono" :span="3">
            {{ user.phone }}
          </a-descriptions-item>
        </a-descriptions>

        <span style="justify-content: flex-end; list-style: none; display: flex" class="mt-2 mr-2"
          v-if="user_role === 'superadmin'">
          <p class="edit-holding-text" @click="openEditionModal(user.id)">
            Editar
          </p>
          <p class="delete-holding-text ml-5" @click="deleteUser(user)">
            Eliminar
          </p>
        </span>
      </div>
    </div>

    <!-- modal eliminar -->
    <a-modal v-model:visible="deleteVisible" centered :title="'Eliminar usuario'" @cancel="closeDeleteUserModal"
      :closable="!loadingDelete" :maskClosable="!loadingDelete">
      <a-form name="form" layout="vertical" ref="formRefDeletion" :model="formState" :rules="rules">
        <p>
          Para eliminar el usuario
          <strong>{{ counterPartUser }}</strong> escribe en el recuadro
          “eliminar” y presiona continuar.
        </p>
        <a-form-item ref="eliminar" name="eliminar" has-feedback>
          <a-input v-model:value="formState.eliminar" placeholder="eliminar" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" :disabled="loadingDelete" @click="closeDeleteUserModal">Cancelar</a-button>
        <a-button type="primary" :danger="'danger'" key="submit" :loading="loadingDelete" :disabled="!blockEliminar"
          @click="handleOk">
          Continuar
        </a-button>
      </template>
    </a-modal>

    <!-- Modal busqueda de usuario -->

    <a-modal v-model:visible="searchModal" title="Crear usuario" :footer="null">
      <b>Ingrese correo electrónico</b>

      <a-form ref="formRefSearch" :model="formStateSearch" :rules="rulesSearch" layout="vertical">
        <a-form-item label="Email" name="email">
          <a-input placeholder="Correo electrónico" v-model:value="formStateSearch.email"
            style="display: inline-block; width: 70%" />
          <a-button type="primary" class="ml-2" @click="searchAction">Buscar</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- Modal para crear un usuario -->
    <a-modal v-model:visible="creationModal.visibility" title="Crear usuario" @cancel="closeCreationModal"
      :closable="!creationModal.loading" :maskClosable="!creationModal.loading">
      <b>Ingrese correo electrónico</b>

      <a-form ref="formRefCreation" :model="formStateCreation" :rules="rulesCreation" layout="vertical">
        <a-form-item label="Email" name="email">
          <a-input placeholder="Correo electrónico" v-model:value="formStateCreation.email"
            style="display: inline-block; width: 70%" :disabled="true" />
          <a-button type="primary" class="ml-2" @click="backToSearch">Limpiar</a-button>
        </a-form-item>

        <b>Crear usuario</b>

        <a-form-item label="Nombre y apellido" name="name">
          <a-input placeholder="Ingresa nombre y apellido usuario/a" v-model:value="formStateCreation.name"
            :disabled="formStateCreation.disable_name" />
        </a-form-item>

        <a-form-item label="Teléfono" name="phone">
          <a-input placeholder="Ingresa número de teléfono" v-model:value="formStateCreation.phone"
            :disabled="formStateCreation.disable_phone" />
        </a-form-item>

        <a-form-item name="isPrincipal">
          <a-checkbox v-model:checked="formStateCreation.isPrincipal">Contraparte principal</a-checkbox>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="backToSearch" :disabled="creationModal.loading">Volver</a-button>
        <a-button key="submit" @click="createUser" :loading="creationModal.loading">{{ creationModal.button_text
          }}</a-button>
      </template>
    </a-modal>

    <!-- Modal para editar un usuario -->
    <a-modal v-model:visible="editingModal.visibility" title="Editar usuario" @cancel="closeEditionnModal"
      :closable="!editingModal.loading" :maskClosable="!editingModal.loading">
      <a-form ref="formRefEdition" :model="formStateEdition" :rules="rulesEdition" layout="vertical">
        <a-form-item label="Nombre" name="name">
          <a-input placeholder="Nombre apellido" v-model:value="formStateEdition.name" />
        </a-form-item>

        <a-form-item label="Email" name="email">
          <a-input placeholder="example@jumpitt.com" v-model:value="formStateEdition.email" />
        </a-form-item>

        <a-form-item label="Teléfono" name="phone">
          <a-input placeholder="+56999999999" v-model:value="formStateEdition.phone" />
        </a-form-item>

        <a-form-item name="isPrincipal">
          <a-checkbox v-model:checked="formStateEdition.isPrincipal">Contraparte principal</a-checkbox>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="closeEditionnModal" :disabled="editingModal.loading">Cancelar</a-button>
        <a-button key="submit" :disabled="disable_edition" @click="editExecutive"
          :loading="editingModal.loading">Editar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import {
  FETCH_USERS,
  FETCH_USER,
  GET_USER,
  GET_USERS,
  POST_USER,
  PUT_EXECUTIVE,
  GET_LOGGED_USER,
  DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY
} from '@/store/types';
import notificacion from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'Usuario',
  setup() {
    // * Vuex
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_LOGGED_USER].roleName;
    const counterPartUser = ref('');

    // * Usuarios
    // Obtengo el store y las rutas
    const route = useRoute();

    // Id de la compañia
    const company_id = route.params.id;
    const deleteVisible = ref(false);
    const loadingDelete = ref(false);
    const blockEliminar = ref(false);
    const dataModalDelete = ref([]);

    // Obtengo la lista de usuarios
    store
      .dispatch(FETCH_USERS, company_id)
      .then((resp) => {
        console.log(resp.status);
      })
      .catch((err) => {
        console.log(err);
      });

    // Usuarios escuchando a que se carguen o se obtenga uno nuevo
    const loadingUsers = ref(false);
    const users = computed(() => {
      // Dejo cargando el skeleton
      loadingUsers.value = true;
      const users_store = store.getters[GET_USERS];
      // Saco el skeleton
      loadingUsers.value = false;

      return users_store;
    });

    // * Buscar usuario
    const formRefSearch = ref();
    const formStateSearch = reactive({
      email: ''
    });

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    const rulesSearch = {
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    const searchAction = () => {
      formRefSearch.value
        .validate()
        .then(() => {
          searchUser(formStateSearch.email);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // Obtener el id de la empresa desde la url
    const searchUser = async (value) => {
      let name = '';
      let nameDisabled = false;
      let phone = '';
      let phoneDisabled = false;
      let isPrincipal = false;
      let button_text = 'Crear';

      try {
        const resp = await store.dispatch(FETCH_USER, {
          params: { email: value },
          companyId: company_id
        });

        const user = store.getters[GET_USER];

        if (user != undefined) {
          name = user.name;
          phone = user.phone;
          isPrincipal = false;
          nameDisabled = true;
          phoneDisabled = true;
          button_text = 'Asociar';
        }

        console.log(resp);
      } catch (error) {
        console.log('Error');
      }

      // Le paso los valores al formulario que se carga cuando se crea el usuario
      // Si encontro a un uisuario, se pasa todo
      // Si no, solo le paso el correo par ponerlo en el input email
      formStateCreation.email = formStateSearch.email;

      formStateCreation.name = name;
      formStateCreation.phone = phone;
      formStateCreation.isPrincipal = isPrincipal;
      formStateCreation.disable_name = nameDisabled;
      formStateCreation.disable_phone = phoneDisabled;
      creationModal.button_text = button_text;

      // Cierro el modal de busqueda
      searchModal.value = false;
      // Abro el modal para crear al usuario
      creationModal.visibility = true;
    };

    // * Modal para la busqueda de usuario
    const searchModal = ref(false);

    const openModal = () => {
      searchModal.value = true;
    };

    const backToSearch = () => {
      // DEjo el correo vacio en cual estaba buscando
      formStateSearch.email = '';

      closeCreationModal();
      openModal();
    };

    // * Crear un usuario
    const formRefCreation = ref();
    const formStateCreation = reactive({
      name: '',
      disable_name: false,
      phone: null,
      disable_phone: false,
      email: '',
      isPrincipal: false
    });
    const formState = reactive({
      eliminar: ''
    });

    const rulesCreation = {
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      name: [
        {
          required: true,
          message: 'Ingrese nombre',
          trigger: 'change',
          type: 'string'
        }
      ]
    };

    // * Modal para crear un usuario
    const creationModal = reactive({
      visibility: false,
      button_text: '',
      loading: false
    });

    const closeCreationModal = () => {
      creationModal.visibility = false;
      formRefCreation.value.resetFields();
    };

    const createUser = async () => {
      creationModal.loading = true;
      loadingUsers.value = true;

      try {
        const resp = await formRefCreation.value.validate();

        const obj = {
          name: resp.name,
          email: resp.email,
          isPrincipal: resp.isPrincipal
        };

        if (resp.phone != '') {
          obj['phone'] = resp.phone;
        }

        await store.dispatch(POST_USER, { payload: obj, id: company_id });

        closeCreationModal();

        notificacion('success', 'Correcto', 'Ejecutivo creado correctamente');
      } catch (error) {
        const errorMessage = error.response.data.message;
        let finalMessage = '';

        if (errorMessage === 'Executive already exists in company') {
          finalMessage = 'Ejecutivo ya existe en esta compañía';
        } else {
          finalMessage = 'Ocurrio un error, vuelve a intentarlo mas tarde';
        }

        notificacion('error', 'Error', finalMessage);
      }

      creationModal.loading = false;
      loadingUsers.value = false;
    };

    // * Editar Modal
    const editingModal = reactive({
      visibility: false,
      loading: false
    });

    const formRefEdition = ref();
    const formStateEdition = reactive({
      name: '',
      phone: null,
      // email:'',
      isPrincipal: false,
      email: ''
    });
    const userEditBackup = ref({});
    const rulesEdition = {
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      name: [
        {
          required: true,
          message: 'Ingrese nombre',
          trigger: 'change',
          type: 'string'
        }
      ]
    };
    const checkEliminar = async (rules, val) => {
      console.log('checckkkk', val);
      if (!val) {
        blockEliminar.value = false;
        return Promise.reject('Ingrese el valor requerido');
      }
      if (val.toUpperCase() == 'ELIMINAR' || val.toLowerCase() == 'eliminar') {
        blockEliminar.value = true;
        return Promise.resolve();
      }
      blockEliminar.value = false;
      return Promise.reject('Ingrese el valor requerido');
    };
    const rules = {
      eliminar: [
        {
          required: true,
          // message: 'Ingrese el valor requerido',
          validator: checkEliminar,
          type: 'string',
          trigger: 'change'
        }
      ]
    };

    const closeEditionnModal = () => {
      editingModal.visibility = false;
    };

    const openEditionModal = async (id) => {
      await store.dispatch(FETCH_USER, {
        companyId: company_id,
        executiveId: id
      });
      const { user, isPrincipal } = store.getters[GET_USER];

      // Creo un objeto backup para compararlo cuando edito
      userEditBackup.value = user;

      formStateEdition.name = user.name;
      formStateEdition.phone = user.phone;
      formStateEdition.email = user.email;
      formStateEdition.isPrincipal = isPrincipal;

      editingModal.visibility = true;
    };

    const disable_edition = computed(() => {
      if (
        formStateEdition.name != userEditBackup.value.name ||
        formStateEdition.email != userEditBackup.value.email ||
        formStateEdition.phone != userEditBackup.value.phone ||
        formStateEdition.isPrincipal != userEditBackup.value.isPrincipal
      ) {
        return false;
      }
      return true;
    });

    const editExecutive = async () => {
      editingModal.loading = true;
      loadingUsers.value = true;

      try {
        const resp = await formRefEdition.value.validate();

        if (resp.phone == '') {
          resp.phone = null;
        }

        await store.dispatch(PUT_EXECUTIVE, {
          payload: resp,
          idCompany: company_id,
          idExecutive: userEditBackup.value.id
        });
        await delay(1000);

        notificacion('success', 'Correcto', 'Ejecutivo Editado correctamente');
      } catch (error) {
        notificacion(
          'error',
          'Error',
          'Ocurrio un error inesperado, vuelve a intentarlo mas tarde'
        );
      }

      editingModal.loading = false;
      loadingUsers.value = false;

      closeEditionnModal();
    };

    const formRefDeletion = ref();
    const closeDeleteUserModal = () => {
      formRefDeletion.value.resetFields();
      deleteVisible.value = false;
    };

    const deleteUser = (val) => {
      deleteVisible.value = true;
      blockEliminar.value = false;
      counterPartUser.value = val.name;
      dataModalDelete.value = val;
    };
    const handleOk = async () => {
      loadingDelete.value = true;

      try {
        const resp = await store.dispatch(
          DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY,
          { companyId: route.params.id, executiveId: dataModalDelete.value.id }
        );
        if (resp == 200) {
          return resp;
        }
        await delay(1500); // ! Eliminar este await a produccion, este sirve para hacer aparecer que el boton esta cargando con data

        await store.dispatch(FETCH_USERS, company_id);
        message.success('El ejecutivo fue eliminado correctamente.', 6);
        loadingDelete.value = false;
        deleteVisible.value = false;
      } catch (error) {
        loadingDelete.value = false;
        deleteVisible.value = false;
        message.error('Ah ocurrido un error inesperado,intentelo mas tarde', 8);
        console.log('erroor', error);
      }
    };

    return {
      // Variables
      users,
      formRefSearch,
      formRefCreation,
      formStateSearch,
      rulesSearch,
      searchModal,
      formStateCreation,
      creationModal,
      rulesCreation,
      loadingUsers,
      formRefEdition,
      formStateEdition,
      rulesEdition,
      editingModal,
      user_role,
      // Funciones
      openModal,
      searchAction,
      backToSearch,
      closeCreationModal,
      createUser,
      closeEditionnModal,
      openEditionModal,
      disable_edition,
      editExecutive,
      formRefDeletion,
      closeDeleteUserModal,
      deleteUser,
      deleteVisible,
      loadingDelete,
      rules,
      formState,
      counterPartUser,
      blockEliminar,
      handleOk,
      dataModalDelete
    };
  }
};
</script>
