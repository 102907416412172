<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-typography-title :level="4">Grupos</a-typography-title>
      <a-button type="primary" class="new_group" @click="openModal('agregar')" v-if="user_role === 'superadmin'">
        Nuevo grupo
      </a-button>
    </a-row>

    <div v-if="skeleton">
      <a-skeleton active />
    </div>
    <div>
      <div v-if="groups.length > 0" class="mt-5">
        <div v-for="(g, index) in groups" :key="index" class="mb-3">
          <a-descriptions bordered>
            <a-descriptions-item v-if="g.externalId" label="ID externo" :span="3">
              {{ g.externalId }}
            </a-descriptions-item>
            <a-descriptions-item v-if="g.collectionName" label="Nombre cobranza" :span="3">
              {{ g.collectionName }}
            </a-descriptions-item>
            <a-descriptions-item label="Nombre" :span="3">
              {{ g.name }}
            </a-descriptions-item>
            <a-descriptions-item v-if="g.fileGroupCompany !== null" label="Plan de coberturas" :span="3">
              <a :href="g.fileGroupCompany.fileGroupCompanyURL" target="_blank" rel="noreferrer noopener">
                {{ g.fileGroupCompany.file }}
              </a>
            </a-descriptions-item>
          </a-descriptions>

          <span style="justify-content: flex-end; list-style: none; display: flex" class="mr-2"
            v-if="user_role === 'superadmin'">
            <p @click="openModal('editar', g.id)" class="edit-holding-text">
              Editar
            </p>
            <p @click="openModal('eliminar', g.id)" class="delete-holding-text ml-5">
              Eliminar
            </p>
          </span>
        </div>
      </div>
    </div>

    <a-modal v-model:visible="modal_values.visibility" :title="modal_values.title" @ok="formValidation"
      @cancel="cancelModal" :closable="!modal_values.loading" :maskClosable="!modal_values.loading">
      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <div v-if="modal_values.title === 'Eliminar grupo'">
          <span v-html="modal_values.custom_text"></span>
        </div>

        <a-form-item v-if="modal_values.title !== 'Eliminar grupo'" label="ID externo" name="externalId" has-feedback>
          <a-input v-model:value="formState.externalId" @keyup.enter="formValidation" />
        </a-form-item>

        <a-form-item ref="value" :label="modal_values.input_title" name="value" has-feedback>
          <a-input v-model:value="formState.value" @keyup.enter="formValidation" />
        </a-form-item>

        <a-form-item v-if="modal_values.title !== 'Eliminar grupo'" label="Nombre cobranza" name="collectionName"
          has-feedback>
          <a-input v-model:value="formState.collectionName" @keyup.enter="formValidation" />
        </a-form-item>

        <a-form-item v-if="modal_values.title !== 'Eliminar grupo'" has-feedback name="fileList">
          <a-upload name="avatar" :file-list="formState.fileList" :remove="handleRemove" :before-upload="beforeUpload">
            <a-button>
              <upload-outlined></upload-outlined>
              Adjuntar plan de cobertura
            </a-button>
          </a-upload>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button @click="cancelModal" :disabled="modal_values.loading">Cancelar</a-button>
        <a-button :type="modal_values.button.color" @click="formValidation" :loading="modal_values.loading"
          :danger="modal_values.mode === 'delete'" :disabled="modal_values.button_disabled">{{ modal_values.button.text
          }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { notification, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import {
  GET_LOGGED_USER,
  FETCH_CLIENT_COMPANY_GROUPS,
  GET_CLIENT_COMPANY_GROUPS,
  POST_CLIENT_COMPANY_GROUP,
  PUT_CLIENT_COMPANY_GROUP,
  DELETE_CLIENT_COMPANY_GROUP
} from '@/store/types';

export default {
  name: 'Grupos',
  components: {
    'upload-outlined': UploadOutlined
  },
  setup() {
    // * Vuex
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_LOGGED_USER].roleName;

    // * Lista de grupos
    const route = useRoute();
    const company_id = route.params.id;
    const skeleton = ref(false);

    // Obtencion de data
    const getGroupCompanies = async (id) => {
      skeleton.value = true;
      try {
        store.dispatch(FETCH_CLIENT_COMPANY_GROUPS, id);
      } catch (error) {
        console.log(error);
      }
      skeleton.value = false;
    };

    getGroupCompanies(company_id);

    // Lista de grupos
    const groups = computed(() => {
      return store.getters[GET_CLIENT_COMPANY_GROUPS].map((a) => {
        return {
          id: a.id,
          name: a.name,
          fileGroupCompany: a.fileGroupCompany,
          externalId: a.externalId,
          collectionName: a.collectionName
        };
      });
    });

    // * Modal
    const modal_values = reactive({
      visibility: false,
      title: '',
      button: {
        color: '',
        text: ''
      },
      custom_text: '',
      input_title: '',
      button_disabled: false,
      loading: false,
      mode: ''
    });

    const openModal = (type, id) => {
      // Dejo el valor del input que aparece en el principio como vacio
      formState.value = '';
      formState.collectionName = '';
      formState.externalId = '';
      formState.fileList = [];
      formState.removeFile = false;
      fileBackup.fileList = [];
      fileBackup.removeFile = false;

      // Deto el titulo del input como "Nombre" en caso de que pase a eliminacion, le saco este titulo ya que no se tiene que mostrar
      modal_values.input_title = 'Nombre';

      // Agrego que tipo de accion va a ser: Agregar, editar o eliminar
      action_type.value = type;

      // Valido de que tipo de modal voy abrir
      switch (type) {
        case 'agregar': {
          modal_values.title = 'Nuevo grupo';
          modal_values.button.text = 'Agregar grupo';
          modal_values.button.color = 'primary';
          modal_values.mode = 'add';

          break;
        }
        case 'editar': {
          modal_values.title = 'Editar grupo';
          modal_values.button.text = 'Guardar cambios';
          modal_values.button.color = 'primary';
          modal_values.mode = 'edit';

          // Busco el item al cual le hice click
          const result = groups.value.filter((obj) => obj.id === id)[0];
          // Le paso el valor para que se muestre en el input del form
          formState.value = result.name;
          formState.collectionName = result.collectionName;
          formState.externalId = result.externalId;
          if (result.fileGroupCompany !== null) {
            formState.fileList = [result.fileGroupCompany].map((d) => {
              return {
                key: d.id,
                id: d.id,
                path: d.path,
                groupCompanyId: d.groupCompanyId,
                fileGroupCompanyURL: d.fileGroupCompanyURL,
                fileName: d.file,
                name: d.file
              };
            });
            fileBackup.fileList = formState.fileList;
          }
          // Hago un respaldo de la palabra de la que quiero cambiar, si esta se parece significa que no la e editado
          editing_word.value = result.name;
          editing_collection_name.value = result.collectionName;
          editing_id.value = result.externalId;
          group_index.value = result.id;
          break;
        }
        default: {
          modal_values.title = 'Eliminar grupo';
          modal_values.button.text = 'Continuar';
          modal_values.button.color = 'primary';
          modal_values.input_title = '';
          modal_values.mode = 'delete';

          // Busco el item al cual le hice click
          const result = groups.value.filter((obj) => obj.id === id)[0];

          // Agrego el nombre de  el grupo que vopy a eliminar en el custom text
          modal_values.custom_text = `
            <p>
            Para eliminar grupo
            <b>${result.name}</b>
            escribe en el recuadro “eliminar” y presiona continuar.
            </p>
          `;

          // Obtengo el index del grupo que estoy editando
          group_index.value = result.id;

          break;
        }
      }

      // Valido para ver si desactivo el boton o no
      formInputValidationForButton();

      modal_values.visibility = true;
    };

    const cancelModal = () => {
      // Cierro el modal
      modal_values.visibility = false;
      formRef.value.resetFields();
    };

    // * Formulario
    const action_type = ref('');
    const formRef = ref();
    const formState = reactive({
      value: '',
      collectionName: '',
      externalId: '',
      fileList: [],
      removeFile: false
    });
    const editing_word = ref('');
    const editing_collection_name = ref('');
    const editing_id = ref('');
    const group_index = ref(null);

    const fileBackup = reactive({
      fileList: [],
      removeFile: false
    });

    // Validaciones del formulario
    const checkValue = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese lo solicitado';
        return Promise.reject(error_message);
      }

      if (action_type.value === 'agregar') {
        if (nameGroupValidation(formState.value)) {
          disableButton();
          return Promise.reject(
            `Grupo existente con nombre ${formState.value}`
          );
        }

        return Promise.resolve();
      }

      if (action_type.value === 'editar') {
        if (
          editing_word.value !== formState.value &&
          nameGroupValidation(formState.value)
        ) {
          disableButton();
          return Promise.reject(
            `Grupo existente con nombre ${formState.value}`
          );
        }

        if (
          fileBackup.fileList === formState.fileList &&
          editing_word.value === formState.value &&
          editing_collection_name.value === formState.collectionName &&
          editing_id.value === formState.externalId
        ) {
          disableButton();
          return Promise.reject('No se generó ningún cambio');
        }

        return Promise.resolve();
      }

      if (value === 'eliminar') {
        return Promise.resolve();
      }
      return Promise.reject('Ingrese palabra solicitada');
    };

    const rules = {
      value: [
        {
          required: true,
          validator: checkValue,
          trigger: 'change'
        }
      ]
    };

    const formValidation = () => {
      formRef.value
        .validate()
        .then(() => {
          // Si estoy agregando un grupo
          if (action_type.value === 'agregar') {
            createGroupCompany();
            setTimeout(() => {
              formRef.value.resetFields();
            }, 2000);
          } else if (action_type.value === 'editar') {
            editGroupCompany(group_index.value);
          } else {
            deleteGroupCompany(group_index.value);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };

    // * Crear Grupo
    const createGroupCompany = async () => {
      // creo la data del formulario
      const formData = new FormData();
      formData.append('name', formState.value);
      formData.append('companyId', company_id);
      if (formState.externalId && formState.externalId !== '') {
        formData.append('externalId', formState.externalId);
      }
      if (formState.collectionName && formState.collectionName !== '') {
        formData.append('collectionName', formState.collectionName);
      }
      if (formState.fileList.length !== 0) {
        formData.append('file', formState.fileList[0]);
      }

      // Dejo cargando el boton
      modal_values.loading = true;
      skeleton.value = true;

      try {
        // Creo el grupo
        await store.dispatch(POST_CLIENT_COMPANY_GROUP, formData);

        // Obtengo otra vez todos los grupos existentes
        // await getGroupCompanies(company_id);
        openNotificationWithIcon('success', 'Correcto', 'Grupo creado');
      } catch (error) {
        console.log(error);
        modal_values.visibility = false;

        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      // Saco el boton de carga y cierro el modal
      modal_values.loading = false;
      modal_values.visibility = false;
      skeleton.value = false;
    };

    // * Editar grupo
    const editGroupCompany = async (id) => {
      try {
        const formData = new FormData();
        formData.append('name', formState.value);
        formData.append('companyId', company_id);
        if (formState.externalId && formState.externalId !== '') {
          formData.append('externalId', formState.externalId);
        }
        if (formState.collectionName && formState.collectionName !== '') {
          formData.append('collectionName', formState.collectionName);
        }
        if (formState.fileList !== []) {
          formData.append('file', formState.fileList[0]);
        } else {
          formData.append('file', []);
        }
        formData.append('removeFile', formState.removeFile);

        // Dejo cargando el boton
        modal_values.loading = true;
        skeleton.value = true;

        store.dispatch(PUT_CLIENT_COMPANY_GROUP, { data: formData, id });

        openNotificationWithIcon('success', 'Correcto', 'Grupo editado');
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      // Saco el boton de carga y cierro el modal
      modal_values.loading = false;
      modal_values.visibility = false;
      skeleton.value = false;
    };

    // * Eliminar grupo
    const deleteGroupCompany = async (id) => {
      // Dejo cargando
      // # La data de la lista
      // # Boton de cargando
      modal_values.loading = true;
      skeleton.value = true;

      try {
        await store.dispatch(DELETE_CLIENT_COMPANY_GROUP, {
          group_id: id,
          company_id
        });
        openNotificationWithIcon('success', 'Correcto', 'Grupo eliminado');
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      // Saco la carga y cierro el modal
      modal_values.loading = false;
      modal_values.visibility = false;
      skeleton.value = false;
    };

    // * Validacion
    // Valido que al momento de crear o editar un grupo este sea distinto nombre a los que ya estan
    const nameGroupValidation = (name) => {
      const index = groups.value.findIndex((obj) => {
        return obj.name.toLowerCase() === name.toLowerCase();
      });

      return index > -1;
    };

    // * Notificaciones
    const openNotificationWithIcon = (type, title, message) => {
      notification[type]({
        message: title,
        description: message
      });
    };

    // * Habilitacion de botones
    const enableButton = () => {
      modal_values.button_disabled = false;
    };

    const disableButton = () => {
      modal_values.button_disabled = true;
    };

    // Con este metodo valido si puedo deshabilitar o habilitar un boton dependiendo de la logica
    const formInputValidationForButton = () => {
      if (modal_values.mode === 'edit') {
        if (
          (editing_word.value !== formState.value && formState.value !== '') ||
          (editing_collection_name.value !== formState.collectionName &&
            formState.collectionName !== '') ||
          (formState.fileList.length > 0 &&
            formState.fileList !== fileBackup.fileList) ||
          (editing_id.value !== formState.externalId &&
            formState.externalId !== '')
        ) {
          enableButton();
        } else {
          disableButton();
        }
      } else if (modal_values.mode === 'delete') {
        if (formState.value == 'eliminar') {
          enableButton();
        } else {
          disableButton();
        }
      } else if (modal_values.mode === 'add') {
        if (formState.value) {
          enableButton();
        } else {
          disableButton();
        }
      } else {
        enableButton();
      }
    };

    watch(
      () => formState.value,
      () => {
        formInputValidationForButton();
      }
    );

    watch(
      () => formState.externalId,
      () => {
        formInputValidationForButton();
      }
    );

    watch(
      () => formState.collectionName,
      () => {
        formInputValidationForButton();
      }
    );

    watch(
      () => formState.fileList,
      () => {
        formInputValidationForButton();
      }
    );

    const handleRemove = (file) => {
      if (file.id) {
        formState.removeFile = true;
      }
      formState.fileList = [];
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.fileList = [file];
      }
      return false;
    };

    return {
      fileBackup,
      // variables
      modal_values,
      formRef,
      formState,
      rules,
      groups,
      skeleton,
      group_index,
      user_role,
      // Metodos
      openModal,
      cancelModal,
      formValidation,
      nameGroupValidation,
      handleRemove,
      beforeUpload
    };
  }
};
</script>

<style>
.new_group {
  display: block !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}
</style>
