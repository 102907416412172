<template>
  <div>
    <PageHeader
      :breadcrumRoutes="routes"
      title="Formulario de traspaso de póliza"
    />

    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_data"
      :rules="form_rules"
    >
      <a-row :gutter="12">
        <a-col :span="12">
          <a-card>
            <a-row type="flex">
              <a-form-item label="Envío de información a">
                <a-radio-group value="1">
                  <a-radio value="1">Compañía</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-form-item required name="startDate" label="Inicio de traspaso">
              <a-date-picker
                placeholder="DD-MM-AAAA"
                v-model:value="form_data.startDate"
                :format="format_date"
              />
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>

      <a-card class="mt-2">
        <a-typography-title class="text-gray-8" :level="4">
          Datos póliza de origen
        </a-typography-title>
        <a-row :gutter="16" type="flex">
          <a-col :span="4">
            <a-form-item ref="rut" label="RUT" name="rut" has-feedback>
              <a-input
                @change="(e) => onSelectChange(e, 'rut')"
                placeholder="RUT"
                maxLength="12"
                v-model:value="form_data.rut"
                @keyup="formatRuts"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originCompany"
              label="Empresa"
              name="originCompany"
              has-feedback
            >
              <a-select
                @change="(e) => onSelectChange(e, 'originCompany')"
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.originCompany"
                :options="origin_company_option"
                :disabled="disableCompanyInput"
                :loading="company_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originGroup"
              label="Grupo"
              name="originGroup"
              has-feedback
            >
              <a-select
                @change="(e) => onSelectChange(e, 'originGroup')"
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.originGroup"
                :options="origin_group_options"
                :disabled="disableGroupInput || groups_loading"
                :loading="groups_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originInsuranceCompanyId"
              label="Aseguradora"
              name="originInsuranceCompanyId"
              has-feedback
            >
              <a-select
                @change="(e) => onSelectChange(e, 'originInsuranceCompanyId')"
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.originInsuranceCompanyId"
                :options="origin_insurance_options"
                :loading="insurance_loading"
                :disabled="disableCompanyInput || insurance_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originPolicies"
              label="Pólizas"
              name="originPolicies"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                mode="multiple"
                allowClear
                :disabled="disablePolicyInput || policies_loading"
                v-model:value="form_data.originPolicies"
                :loading="policies_loading"
                :options="origin_policy_number_options"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Datos póliza de destino
        </a-typography-title>
        <a-row :gutter="16" type="flex">
          <a-col :span="4">
            <a-form-item
              ref="newCompany"
              label="Empresa"
              name="newCompany"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.newCompany"
                :options="destiny_company_options"
                @change="(e) => onSelectChange(e, 'newCompany')"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="newGroup"
              label="Grupo"
              name="newGroup"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.newGroup"
                :options="destiny_group_options"
                @change="(e) => onSelectChange(e, 'newGroup')"
                :loading="destiny_group_loading"
                :disabled="disableNewGroup || destiny_group_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="newInsuranceCompanyId"
              label="Aseguradora"
              name="newInsuranceCompanyId"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                :loading="destiny_insurance_loading"
                v-model:value="form_data.newInsuranceCompanyId"
                :options="destiny_insurance_options"
                @change="(e) => onSelectChange(e, 'newInsuranceCompanyId')"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="newPolicies"
              label="Pólizas"
              name="newPolicies"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                mode="multiple"
                allowClear
                v-model:value="form_data.newPolicies"
                :options="destiny_policy_number_options"
                :disabled="disableNewPolicy || destiny_policy_loading"
                :loading="destiny_policy_loading"
                @change="(e) => onSelectChange(e, 'newPolicies')"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="newCoverages"
              label="Coberturas"
              name="newCoverages"
              has-feedback
            >
              <a-select
                placeholder="Coberturas"
                show-search
                optionFilterProp="label"
                mode="multiple"
                allowClear
                :disabled="disableNewCoverages || destiny_coverages"
                :loading="destiny_coverages"
                v-model:value="form_data.newCoverages"
                :options="coverages_options"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-row type="flex" class="justify-content-end mt-3">
        <a-col>
          <a-button
            size="large"
            class="px-4"
            type="primary"
            @click="formSubmit"
            block
          >
            Siguiente
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <TransferCreationResumeModal
      ref="resume_modal_ref"
      :loading="resume_modal_loading"
      @createTransfer="createTransfer"
    />

    <TransferCreationResumeSuccessModal ref="resume_success_modal_ref" />
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import {
  POST_TICKET_TRANSFER,
  FETCH_COMPANIES,
  FETCH_EMPLOYEE_DATA_BY_RUT,
  FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT,
  FETCH_COMPANY_POLICIES,
  FETCH_INSURANCES,
  FETCH_POLICIES_COVERAGES,
  FETCH_COMPANY_BY_RUT,
  FETCH_GROUP_COMPANIES,
  GET_LOGGED_USER
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import notifications from '@/utils/notifications';
import TransferCreationResumeModal from './TransferCreationResumeModal.vue';
import TransferCreationResumeSuccessModal from './TransferCreationResumeSuccessModal.vue';
import PageHeader from '@/components/PageHeader.vue';

const routes = [
  {
    path: '/traspasos',
    breadcrumbName: 'Traspasos de póliza'
  },
  {
    breadcrumbName: 'Formulario'
  }
];

const store = useStore();
const brokerId = store.getters[GET_LOGGED_USER].id;
const employee_name = ref('');
const form_data = reactive({
  brokerId,
  startDate: null,
  rut: null,
  originCompany: null,
  originGroup: null,
  originInsuranceCompanyId: null,
  originPolicies: [],
  newCompany: null,
  newGroup: null,
  newInsuranceCompanyId: null,
  newPolicies: [],
  newCoverages: []
});
const checkRut = async (_, value) => {
  if (!value) {
    const error_message = 'Ingrese RUT';
    return Promise.reject(error_message);
  }
  if (rutHelper.validateRut(value)) {
    return Promise.resolve();
  }
  return Promise.reject('RUT no válido');
};

const form_rules = {
  startDate: [
    {
      required: true,
      message: 'Seleccione una fecha',
      trigger: 'change',
      type: 'object'
    }
  ],
  rut: [
    {
      required: true,
      validator: checkRut,
      trigger: 'change'
    }
  ],
  originCompany: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  originGroup: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  originInsuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  originPolicies: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'array'
    }
  ],
  newCompany: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  newGroup: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  newInsuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  newPolicies: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'array'
    }
  ],
  newCoverages: [
    {
      type: 'array',
      required: true,
      message: 'Seleccione una covertura',
      trigger: 'change'
    }
  ]
};
const form_ref = ref();
const format_date = 'DD-MM-YYYY';
const origin_company_option = ref([]);
const origin_group_options = ref([]);
const origin_insurance_options = ref([]);
const origin_policy_number_options = ref([]);
const destiny_company_options = ref([]);
const destiny_group_options = ref([]);
const destiny_insurance_options = ref([]);
const destiny_policy_number_options = ref([]);
const coverages_options = ref([]);

const formatRuts = () => {
  form_data.rut = rutHelper.formatRut(form_data.rut);
};

const formSubmit = () => {
  form_ref.value
    .validate()
    .then((resp) => {
      openResumeModal({
        requestStart: resp.startDate.format('DD-MM-YYYY'),
        name: employee_name.value,
        rut: resp.rut,
        group: origin_group_options.value.find(
          (group) => group.value === resp.originGroup
        ).label,
        insurance: origin_insurance_options.value.find(
          (obj) => obj.value === resp.originInsuranceCompanyId
        ).label,
        policies: origin_policy_number_options.value
          .map((obj) =>
            resp.originPolicies.includes(obj.value) ? obj.label : ''
          )
          .filter((el) => el !== '')
          .join(', '),
        groupDestiny: destiny_group_options.value.find(
          (group) => group.value === resp.newGroup
        ).label,
        insuranceDestiny: destiny_insurance_options.value.find(
          (obj) => obj.value === resp.newInsuranceCompanyId
        ).label,
        policiesDestiny: destiny_policy_number_options.value
          .map((obj) => (resp.newPolicies.includes(obj.value) ? obj.label : ''))
          .filter((el) => el !== '')
          .join(', '),
        coverages: coverages_options.value
          .map((obj) =>
            resp.newCoverages.includes(obj.value) ? obj.label : ''
          )
          .filter((el) => el !== '')
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const company_loading = ref(false);
const getEmployeeDataByRut = async (rut) => {
  company_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_EMPLOYEE_DATA_BY_RUT, rut);
    if (resp.data != null) {
      employee_name.value = `${resp.data.firstName} ${resp.data.firstSurname}`;
    }
  } catch (error) {
    employee_name.value = '';
    notifications('error', 'Error', error.response.data.message);
  }
  company_loading.value = false;
};

const getEmployeeCompanies = async (rut) => {
  try {
    const resp = await store.dispatch(FETCH_COMPANY_BY_RUT, rut);
    const values = resp.data;
    origin_company_option.value = values.map((company) => ({
      label: company.businessName,
      value: company.id
    }));
  } catch (error) {
    console.log(error.response.data.message);
    notifications(
      'error',
      'Error',
      'Error al obtener empresas por rut de empleado.'
    );
  }
};

const getAllCompanies = async () => {
  try {
    const resp = await store.dispatch(FETCH_COMPANIES);
    const values = resp.data;
    destiny_company_options.value = values.map((company) => ({
      label: company.businessName,
      value: company.id
    }));
  } catch (error) {
    console.log(error.response.data.message);
    notifications('error', 'Error', 'Error al obtener todas las empresas.');
  }
};

const insurance_loading = ref(false);
const getEmployeeInsuranceCompaniesByRut = async (
  rut,
  companies,
  groupCompany
) => {
  insurance_loading.value = true;
  try {
    const resp = await store.dispatch(
      FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT,
      { rut, companies, groupCompany }
    );
    origin_insurance_options.value = resp.map((insurance) => ({
      label: insurance.businessName,
      value: insurance.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las aseguradoras.');
  }
  insurance_loading.value = false;
};

const getPoliciesByEmployeeAndInsuranceCompany = async (params) => {
  try {
    const resp = await store.dispatch(FETCH_COMPANY_POLICIES, params);
    console.log(
      'Obtener polizas por empleado y compañia aseguradora',
      resp.data
    );

    return resp.data.map((policy) => ({
      label: policy.numberPolicy,
      value: policy.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las pólizas.');
  }
};

const destiny_insurance_loading = ref(false);
const getDestinyInsuranceCompanies = async () => {
  destiny_insurance_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_INSURANCES, {
      companyId: form_data.newCompany,
      groupCompanyId: form_data.newGroup
    });
    destiny_insurance_options.value = resp.data.map((insurance) => ({
      label: insurance.businessName,
      value: insurance.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener todas las pólizas.');
  }
  destiny_insurance_loading.value = false;
};

const getGroups = async (params) => {
  try {
    const resp = await store.dispatch(FETCH_GROUP_COMPANIES, params);
    return resp.data;
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener todos los grupos.');
  }
};

const debounce = (cb, delay = 1000) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

const updateDebouceRut = debounce((rut, company, group) => {
  const valid_rut = rutHelper.validateRut(rut);
  if (valid_rut) {
    getEmployeeDataByRut(rut);
    getEmployeeInsuranceCompaniesByRut(rut, company, group);
    getEmployeeCompanies(rut);
  }
}, 500);

// habilitacion y deshabilitacion de inputs
const disableCompanyInput = computed(() => {
  if (rutHelper.validateRut(form_data.rut) && employee_name.value != '') {
    return false;
  }
  return true;
});

const disableGroupInput = computed(() => {
  if (
    form_data.rut != '' &&
    form_data.rut != null &&
    form_data.originCompany != null
  ) {
    return false;
  }
  return true;
});

const disablePolicyInput = computed(() => {
  if (
    disableCompanyInput.value === false &&
    form_data.originInsuranceCompanyId != null
  ) {
    return false;
  }
  return true;
});

const disableNewGroup = computed(() => {
  if (form_data.newCompany != null) {
    return false;
  }
  return true;
});

const disableNewPolicy = computed(() => {
  if (form_data.newCompany != null && form_data.newInsuranceCompanyId != null) {
    return false;
  }
  return true;
});

const disableNewCoverages = computed(() => {
  if (form_data.newPolicies.length > 0) {
    return false;
  }
  return true;
});

// Reactividad de inputs
watch(
  () => [form_data.rut, form_data.originCompany, form_data.originGroup],
  ([newRut, newCompany, newGroup]) => {
    if (newRut != '') {
      const normalized_rut = rutHelper.normalizeRut(newRut);
      updateDebouceRut(normalized_rut, newCompany, newGroup);
    } else {
      employee_name.value = '';
    }
  }
);

const policies_loading = ref(false);
watch(
  () => [
    form_data.rut,
    form_data.originCompany,
    form_data.originInsuranceCompanyId
  ],
  async ([newRut, newCompany, newInsurance]) => {
    if (rutHelper.validateRut(newRut) && newInsurance) {
      policies_loading.value = true;
      origin_policy_number_options.value =
        await getPoliciesByEmployeeAndInsuranceCompany(
          `?rut=${rutHelper.normalizeRut(
            newRut
          )}&companyId=${newCompany}&insuranceCompanyId=${newInsurance}`
        );
      policies_loading.value = false;
    }
  }
);

const destiny_policy_loading = ref(false);
watch(
  () => [form_data.newCompany, form_data.newInsuranceCompanyId],
  async ([newCompany, newInsuranceDestiny]) => {
    if (newInsuranceDestiny && newCompany) {
      destiny_policy_loading.value = true;
      destiny_policy_number_options.value =
        await getPoliciesByEmployeeAndInsuranceCompany(
          `?companyId=${newCompany}&insuranceCompanyId=${newInsuranceDestiny}`
        );
      destiny_policy_loading.value = false;
    }
  }
);

watch(
  () => [form_data.newCompany, form_data.newGroup],
  async () => {
    await getDestinyInsuranceCompanies();
  }
);

watch(
  () => form_data.newPolicies,
  (newDestinyPolicyNumber) => {
    if (newDestinyPolicyNumber.length > 0) {
      getPoliciesCoverages(newDestinyPolicyNumber);
    }
  }
);

const groups_loading = ref(false);
watch(
  () => [form_data.rut, form_data.originCompany],
  async ([newRut, newCompany]) => {
    if (rutHelper.validateRut(newRut) && newCompany) {
      groups_loading.value = true;
      const resp = await getGroups(
        `?company=${newCompany}&rut=${rutHelper.normalizeRut(newRut)}`
      );
      origin_group_options.value = resp.map((group) => ({
        label: group.name,
        value: group.id
      }));
      groups_loading.value = false;
    }
  }
);

const destiny_group_loading = ref(false);
watch(
  () => form_data.newCompany,
  async (company) => {
    if (company) {
      destiny_group_loading.value = true;
      const resp = await getGroups(`?company=${company}&policyStatus=Vigente`);
      destiny_group_options.value = resp.map((group) => ({
        label: group.name,
        value: group.id
      }));
      destiny_group_loading.value = false;
    }
  }
);

const destiny_coverages = ref(true);
const getPoliciesCoverages = async (policies) => {
  destiny_coverages.value = true;
  const params = `?${policies.map((p) => `policies=${p}`).join('&')}`;
  try {
    const resp = await store.dispatch(FETCH_POLICIES_COVERAGES, params);
    coverages_options.value = resp.map((coverage) => ({
      label: `${coverage.name} - Poliza ${coverage.policies.numberPolicy}`,
      value: coverage.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las coberturas.');
  }
  destiny_coverages.value = false;
};

const onSelectChange = (value, name) => {
  const keys = Object.keys(form_data);
  const index = keys.findIndex((key) => key === name) + 1;
  const finish = index < 7 ? 6 : keys.length - 1;

  for (let i = index; i <= finish; i++) {
    if (
      keys[i] === 'newPolicies' ||
      keys[i] === 'newCoverages' ||
      keys[i] === 'originPolicies'
    ) {
      form_data[keys[i]] = [];
    } else {
      form_data[keys[i]] = null;
    }
  }
};

// * Resume modal
const resume_modal_ref = ref();
const resume_modal_loading = ref(false);
const openResumeModal = (values) => {
  resume_modal_ref.value.openModal(values);
};

// * Resume success modal
const resume_success_modal_ref = ref();

// * Post send
const createTransfer = () => {
  resume_modal_loading.value = true;

  const {
    originGroup: groupCompanyId,
    originInsuranceCompanyId: insuranceCompanyId,
    originPolicies: policies,
    newGroup: newGroupCompanyId,
    newInsuranceCompanyId,
    newPolicies,
    newCoverages
  } = form_data;

  const payload = {
    rut: rutHelper.normalizeRut(form_data.rut),
    brokerId,
    groupCompanyId,
    insuranceCompanyId,
    policies,
    newGroupCompanyId,
    newInsuranceCompanyId,
    newPolicies,
    newCoverages,
    transferDate: form_data.startDate.format()
  };

  store
    .dispatch(POST_TICKET_TRANSFER, payload)
    .then((resp) => {
      const { id } = resp.data;
      resume_modal_ref.value.closeModal();
      resume_success_modal_ref.value.openSuccessModal(id);
      form_ref.value.resetFields();
    })
    .catch((err) => {
      notifications('error', 'Error', err.response.data.message);
    })
    .finally(() => {
      resume_modal_loading.value = false;
    });
};

// * Carga principal
onMounted(() => {
  getAllCompanies();
  getDestinyInsuranceCompanies();
});
</script>
