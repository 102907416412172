<template>
  <div>
    <page-header :breadcrumRoutes="breadcrumRoutes" :title="headerTitle">
      <template #tags>
        <a-tag :color="getCurrentStateColor(current_ticket_state)">
          {{ getCurrentStateName(current_ticket_state) }}
        </a-tag>
        <a-tag color="processing">
          <span
            >ID Reproceso: <b>{{ ticket_id }}</b></span
          >
        </a-tag>
      </template>

      <template #buttons>
        <div v-if="current_ticket_state === 'IN_INSURANCE'">
          <a-button @click="openCancelModal">Cancelar</a-button>
          <a-dropdown class="ml-3">
            <a-button type="primary">
              Resolución
              <down-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" @click="openAprovalModal"
                  >Aprobada</a-menu-item
                >
                <a-menu-item key="2" @click="openRejectModal"
                  >Rechazada</a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </page-header>

    <stepper :stepper-data="stepper_props" />

    <a-card>
      <a-typography-title :level="4"> Datos reproceso </a-typography-title>

      <a-row :gutter="16">
        <a-col :span="12">
          <ticket-data :data="reprocess_details" />

          <associate-process title="Procesos asociados" :data="process_data" />

          <comment-list
            class="mt-3"
            title="Comentarios ticket"
            :commentsList="comments"
            :loading="loading_comments"
            @createComment="createComment"
          />
        </a-col>
        <a-col :span="12">
          <associate-inconsistency
            :squaringCollectionInsurance="squaring_collection_insurance"
            :loadingsquaringValidate="loading_squaring_validate"
            :states="collection_ticket_data"
            @updateInconsistency="updateInconsistency"
            @createSquaringValidate="createSquaringValidate"
          />

          <collapsable-table class="mt-3" title="Mensaje aseguradora">
            <a-typography-text
              v-html="
                insurance_message
                  ? insurance_message.replace(/\n/g, '<br>')
                  : 'No existe mensaje aseguradora'
              "
            >
            </a-typography-text>
          </collapsable-table>

          <collapsable-table class="mt-3" title="Resolucion compañia">
            <a-typography-text
              v-html="
                company_resolution
                  ? company_resolution.replace(/\n/g, '<br>')
                  : 'No existe mensaje aseguradora'
              "
            >
            </a-typography-text>
          </collapsable-table>
        </a-col>
      </a-row>
    </a-card>

    <!-- Modal de aprobacion -->
    <aproval-modal
      ref="aproval_modal"
      :reprocess-type="reprocess_type"
      :reprocess-id="parseInt(reprocessId)"
      @aprovedReprocess="getReprocessData"
    />

    <!-- Modal de rechazo -->
    <reject-modal
      ref="reject_modal"
      :reprocess-type="reprocess_type"
      :reprocess-id="parseInt(reprocessId)"
      @rejectReprocess="getReprocessData"
    />

    <!-- Modal de cancelacion -->
    <cancel-modal
      ref="cancel_modal"
      :reprocess-id="parseInt(reprocessId)"
      :reprocess-type="reprocess_type"
      @cancelledReprocess="getReprocessData"
    />
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { DownOutlined } from '@ant-design/icons-vue';
import AssociateProcess from './components/AssociateProcess.vue';
import ReprocessAprovalModal from './components/ReprocessAprovalModal.vue';
import ReprocessRejectModal from './components/ReprocessRejectModal.vue';
import ReprocessCancelModal from './components/ReprocessCancelModal.vue';
import AssociateInconsistency from '@/components/collection and billing/reprocesses and requests/inconsistency/AssociateInconsistency.vue';
import PageHeader from '@/components/PageHeader.vue';
import {
  FETCH_REPROCESS,
  FETCH_PROCESS_BY_REPROCESS,
  FETCH_COLLECTIONS,
  FETCH_REPROCESS_COMMENTS,
  FETCH_COLLECTION_INCONSISTENCIES,
  GET_REPROCESS_COMMENTS,
  GET_REPROCESS,
  GET_PROCESS_BY_REPROCESS,
  GET_COLLECTIONS,
  GET_LOGGED_USER,
  POST_REPROCESS_COMMENTS,
  POST_SQUARING_COLLECTION_VALIDATE
} from '@/store/types';
import notification from '@/utils/notifications';
import StepperCobranzaFacturacion from '@/components/CollectionBillingStepper.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import CollapsableTable from '@/components/CollapsableTable.vue';
import CommentsLists from '@/components/CommentsList.vue';
import getTagText from '@/utils/getTagText';
import getTagColor from '@/utils/getTagColor';
import parseDate from '@/utils/dateFormatter';

export default {
  components: {
    'page-header': PageHeader,
    stepper: StepperCobranzaFacturacion,
    'ticket-data': DatosTicket,
    'collapsable-table': CollapsableTable,
    'comment-list': CommentsLists,
    'associate-inconsistency': AssociateInconsistency,
    'down-outlined': DownOutlined,
    'aproval-modal': ReprocessAprovalModal,
    'reject-modal': ReprocessRejectModal,
    'cancel-modal': ReprocessCancelModal,
    'associate-process': AssociateProcess
  },
  props: {
    headerTitle: {
      type: String,
      default: 'Titulo del page header'
    },
    reprocessId: {
      type: [String, Number],
      default: ''
    },
    breadcrumRoutes: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const ticket_id = route.params.id;

    // * Page header
    const current_ticket_state = computed(() => {
      const { reprocessStates } = store.getters[GET_REPROCESS];

      if (reprocessStates != undefined) {
        return reprocessStates[reprocessStates?.length - 1].state != undefined
          ? reprocessStates[reprocessStates?.length - 1].state
          : 'cancelled';
      }

      return '';
    });

    const getCurrentStateName = (name) => {
      return name != undefined ? getTagText(name)?.toUpperCase() : '';
    };

    const getCurrentStateColor = (name) => {
      const factored_name = getCurrentStateName(name);
      return factored_name != undefined ? getTagColor(factored_name) : '';
    };

    // * Stepper data
    const stepper_props = computed(() => {
      const props = {
        current: 0,
        state: 'process',
        steps: [
          {
            title: 'En aseguradora',
            date: '',
            key: 1
          },
          {
            title: 'Pendiente resolución',
            date: '',
            key: 2
          }
        ]
      };

      const { reprocessStates: states } = store.getters[GET_REPROCESS];

      // Formateo la fecha del primer estado y la coloco en el stepper
      props.steps[0].date =
        states != undefined ? parseDate(states[0].created_at) : '';

      // Si existen mas de un estado voy a colocar su nombre correspondiente con su fecha
      if (states?.length > 1) {
        const last_state = states[1];

        props.steps[1].date = parseDate(last_state.created_at);

        switch (last_state.state) {
          case 'APPROVED':
            props.steps[1].title = 'Aprobada';
            props.state = 'finish';
            break;
          case 'REJECTED':
            props.steps[1].title = 'Rechazado';
            break;
          default:
            props.steps[1].title = 'Cancelado';
            props.state = 'error';
            break;
        }

        props.current = 1;
      }

      return props;
    });

    // * Mensaje de aseguradora
    const insurance_message = computed(() => {
      return store.getters[GET_REPROCESS]?.reprocessEmails[0].message;
    });

    // * Resolucion compañia
    const company_resolution = computed(() => {
      return store.getters[GET_REPROCESS]?.insurance_resolution != null
        ? store.getters[GET_REPROCESS]?.insurance_resolution
        : 'No existen datos.';
    });

    // * Data de reproceso
    const subholdingsCustomRender = (values) => {
      if (values != undefined) {
        return values.length > 3
          ? `${values[0]} +${values.length - 1}`
          : values.join(' - ');
      }
      return '';
    };

    const companiesCustomRender = (values) => {
      if (values != undefined) {
        return values.length > 3
          ? `${values[0].businessName} +${values.length - 1}`
          : values.map((obj) => obj.businessName).join('-');
      }
      return '';
    };

    const reprocess_details = computed(() => {
      const data = store.getters[GET_COLLECTIONS];

      if (data != []) {
        return [
          {
            key: 'Periodo cobranza',
            value: data?.period
          },
          {
            key: 'Holding',
            value: data?.holding
          },
          {
            key: 'Subholdings',
            value: subholdingsCustomRender(data?.subHoldings)
          },
          {
            key: 'Empresas',
            value: companiesCustomRender(data?.companies)
          },
          {
            key: 'Aseguradora',
            value: data?.insuranceCompany?.businessName
          },
          {
            key: 'Polizas',
            value: data?.policies?.join(', ')
          },
          {
            key: 'Correo aseguradoras',
            value: data?.insuranceCompany?.collectionEmails
              .map((e) => e)
              .filter((e, i, arr) => arr.indexOf(e) === i)
              .join(',\n')
          },
          {
            key: 'Correo Clientes',
            value: data?.companies
              ?.map((e) => e.collectionEmails)
              .flat()
              .filter((e, i, arr) => arr.indexOf(e) == i)
              .join(',\n')
          }
        ];
      }
      return [];
    });

    const getCollectionData = (payload) => {
      store
        .dispatch(FETCH_COLLECTIONS, {
          collection_id: payload,
          query_params: ''
        })
        .catch((err) => err.response.data.message);
    };

    // * Procesos asociados
    const process_data = computed(() => {
      const reprocess = store.getters[GET_PROCESS_BY_REPROCESS];

      if (reprocess.length > 0) {
        getCollectionData(reprocess[0].id);
      }

      return reprocess?.map((obj) => {
        return {
          key: obj.id,
          name: getReprocessTypeName(obj.type),
          tags: obj.isReprocessing
            ? 'En Reproceso'
            : obj.states[obj.states.length - 1].state,
          created_at: obj.states[obj.states.length - 1].createdAt,
          redirection: {
            title: 'Ver Proceso',
            route: {
              name: 'detalleTicketCobranza',
              params: { id: obj.id }
            }
          }
        };
      });
    });

    const getReprocessTypeName = (type) => {
      switch (type) {
        case 'COLLECTION':
          return 'Cobranza';
        case 'PRECOLLECTION':
          return 'Pre cobranza';
        default:
          return type;
      }
    };

    const getProcessData = () => {
      console.log('getProcessData');
      store.dispatch(FETCH_PROCESS_BY_REPROCESS, props.reprocessId);
    };

    // * Comentarios
    const getComments = () => {
      console.log('getComments');
      store.dispatch(FETCH_REPROCESS_COMMENTS, props.reprocessId);
    };

    const comments = computed(() => {
      return store.getters[GET_REPROCESS_COMMENTS]?.map((message) => {
        return {
          author: `Autor ${message.id}`,
          avatar:
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          description: parseDate(message.createdAt),
          date: message.createdAt,
          content: message.message
        };
      });
    });
    const loading_comments = ref(false);
    const createComment = async (comment) => {
      loading_comments.value = true;
      try {
        const created_comment = {
          brokerId: store.getters[GET_LOGGED_USER].id,
          messageComment: comment
        };

        await store.dispatch(POST_REPROCESS_COMMENTS, {
          id: props.reprocessId,
          payload: created_comment
        });

        notification('success', 'Correcto', 'Comentario creado');
        getComments();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_comments.value = false;
    };

    // * Inconsistencia
    const squaring_collection_insurance = ref([]);
    const loading_squaring_validate = ref(false);

    const collection_ticket_data = computed(
      () => store.getters[GET_COLLECTIONS]
    );

    const getInconsistencyData = async (payload) => {
      try {
        const { data } = await store.dispatch(
          FETCH_COLLECTION_INCONSISTENCIES,
          {
            collection_id: payload.collection_id,
            query_params: `?collectionResourceId=${payload.collection_resource_id}`
          }
        );

        if (data.status == 'NO_PROCESSED') {
          squaring_collection_insurance.value = [data].map((e) => ({
            ...e,
            totalDiscrepancies: 0
          }))[0];
        } else if (data.status == 'PROCESSING') {
          loading_squaring_validate.value = true;
          squaring_collection_insurance.value = [data].map((e) => ({
            ...e,
            totalDiscrepancies: 0
          }))[0];
          setTimeout(async () => {
            await getInconsistencyData();
          }, 10000);
        } else {
          loading_squaring_validate.value = false;

          squaring_collection_insurance.value = [data]
            .map((e) => ({
              ...e,
              discrepancies: [e.discrepancies].map((d) => ({
                ...d,
                dependents: [d.dependents].map((dp) => ({
                  ...dp,
                  types: 'Cargas'
                })),
                employees: [d.employees].map((em) => ({
                  ...em,
                  types: 'Titulares'
                }))
              })),
              status: e.status
            }))
            .map((rf) => ({
              ...rf,
              discrepancies: rf.discrepancies.map((rfd) => ({
                ...rfd,
                table_data: []
                  .concat(rfd.employees, rfd.dependents)
                  .map((td, i) => ({
                    ...td,
                    key: i
                  }))
              }))
            }))[0];
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const createSquaringValidate = async (val) => {
      loading_squaring_validate.value = true;
      try {
        await store.dispatch(POST_SQUARING_COLLECTION_VALIDATE, {
          collectionId: collection_id.value,
          payload: val.force ? `?force=${val.force}` : ''
        });

        const resp = await store.dispatch(FETCH_COLLECTION_INCONSISTENCIES, {
          collection_id: collection_id.value,
          query_params: `?collectionResourceId=${collection_resource_id.value}`
        });

        if (resp.data.status == 'PROCESSING') {
          //     validate_modal_ref.value.closeModal();
          const data = [resp.data].map((e) => ({
            status: e.status,
            totalDiscrepancies: 0
          }));

          squaring_collection_insurance.value = data[0];

          getReprocessData();
          getProcessData();
          getComments();
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_squaring_validate.value = false;
    };

    const updateInconsistency = (val) => {
      if (loading_squaring_validate.value == true) {
        setTimeout(() => {
          val.closeModal();
        }, 1000);
      }
    };

    // * Modal de aprobacion
    const aproval_modal = ref();
    const openAprovalModal = () => {
      aproval_modal.value.openModal();
    };

    // * Modal de rechazo
    const reject_modal = ref();
    const openRejectModal = () => {
      reject_modal.value.openModal();
    };

    // * Modal de cancelacion
    const cancel_modal = ref();
    const openCancelModal = () => {
      cancel_modal.value.openModal();
    };

    // * Obtencion de data principal
    const reprocess_data = computed(() => store.getters[GET_REPROCESS]);
    const reprocess_type = computed(() => {
      const types = {
        REPROCESS_FOR_INCONSISTENCY: 1,
        REPROCESS_FOR_INVOICE_CANCEL: 2,
        REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL: 3
      };

      return types[store.getters[GET_REPROCESS]?.type];
    });

    const collection_id = ref('');
    const collection_resource_id = ref('');

    const getReprocessData = () => {
      store
        .dispatch(FETCH_REPROCESS, props.reprocessId)
        .then(({ data }) => {
          collection_id.value = data.collectionResource.collection.id;
          collection_resource_id.value = data.collectionResource.id;

          getInconsistencyData({
            collection_id: collection_id.value,
            collection_resource_id: collection_resource_id.value
          });
        })
        .catch((error) => {
          router.push({ name: 'Missing' });
          notification(
            'error',
            'Error al obtener información de reproceso',
            error.response.data.message
          );
        });
    };

    onMounted(() => {
      getReprocessData();
      getProcessData();
      getComments();
    });

    return {
      stepper_props,
      reprocess_details,
      comments,
      loading_comments,
      reprocess_data,
      current_ticket_state,
      ticket_id,
      aproval_modal,
      reprocess_type,
      reject_modal,
      cancel_modal,
      process_data,
      insurance_message,
      company_resolution,
      openCancelModal,
      openRejectModal,
      openAprovalModal,
      getCurrentStateName,
      getCurrentStateColor,
      getReprocessData,
      createComment,
      squaring_collection_insurance,
      loading_squaring_validate,
      collection_ticket_data,
      createSquaringValidate,
      updateInconsistency
    };
  }
};
</script>
