<template>
  <div>
    <page-header :breadcrumRoutes="breadcrumRoutes" :title="headerTitle">
      <template #tags>
        <a-tag :color="getCurrentStateColor(current_ticket_state)">
          {{ getCurrentStateName(current_ticket_state) }}
        </a-tag>
        <a-tag color="processing">
          <span
            >ID Solicitud: <b>{{ ticket_id }}</b></span
          >
        </a-tag>
      </template>

      <template #buttons>
        <div v-if="current_ticket_state === 'IN_INSURANCE'">
          <a-button @click="openCancelModal">Cancelar</a-button>
          <a-dropdown class="ml-3">
            <a-button type="primary">
              Resolución
              <down-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" @click="openAprovalModal"
                  >Aprobada</a-menu-item
                >
                <a-menu-item key="2" @click="openRejectModal"
                  >Rechazada</a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </page-header>

    <stepper :stepper-data="stepper_props" />

    <a-card>
      <a-typography-title :level="4"> Datos solicitud </a-typography-title>

      <a-row :gutter="16">
        <a-col :span="12">
          <ticket-data :data="request_details" />

          <associate-process title="Procesos asociados" :data="process_data" />

          <comment-list
            class="mt-3"
            title="Comentarios ticket"
            :commentsList="comments"
            :loading="loading_comments"
            @createComment="createComment"
          />
        </a-col>
        <a-col :span="12">
          <associate-inconsistency v-bind="inconsistency_props" />

          <collapsable-table class="mt-3" title="Mensaje aseguradora">
            <a-typography-text
              v-html="
                requests_data
                  ? requests_data.requestEmails[0].message.replace(
                      /\n/g,
                      '<br>'
                    )
                  : 'No existe mensaje aseguradora'
              "
            >
            </a-typography-text>
          </collapsable-table>

          <collapsable-table class="mt-3" title="Resolucion compañia">
            <a-typography-text
              v-html="
                requests_data.insurance_resolution
                  ? requests_data.insurance_resolution.replace(/\n/g, '<br>')
                  : 'No existe mensaje de Resolucion compañia'
              "
            >
            </a-typography-text>
          </collapsable-table>
        </a-col>
      </a-row>
    </a-card>

    <!-- Modal de aprobacion -->
    <aproval-modal
      ref="aproval_modal"
      :request-data="requests_data"
      :collection-data="collectionData"
      :request-type="request_type"
      :request-id="parseInt(requestId)"
      @aprovedRequest="getRequestData"
    />

    <!-- Modal de rechazo -->
    <reject-modal
      ref="reject_modal"
      :request-type="request_type"
      :request-id="parseInt(requestId)"
      @rejectRequest="getRequestData"
    />

    <!-- Modal de cancelacion -->
    <cancel-modal
      ref="cancel_modal"
      :request-id="parseInt(requestId)"
      :request-type="request_type"
      @cancelledRequest="getRequestData"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { DownOutlined } from '@ant-design/icons-vue';
import AssociateProcess from '../reprocess/components/AssociateProcess.vue';
import ReprocessAprovalModal from './components/RequestAprovalModal.vue';
import ReprocessRejectModal from './components/RequestRejectModal.vue';
import ReprocessCancelModal from './components/RequestCancelModal.vue';
import AssociateInconsistency from '@/components/collection and billing/reprocesses and requests/inconsistency/AssociateInconsistency.vue';
import PageHeader from '@/components/PageHeader.vue';
import {
  FETCH_REQUESTS_BY_ID,
  GET_REQUEST_BY_ID,
  // FETCH_REQUESTS_BY_COLLECTION_ID,
  GET_REQUESTS_ID_COLLECTION,
  FETCH_COLLECTIONS,
  GET_COLLECTIONS,
  GET_LOGGED_USER,
  POST_REQUESTS_COMMENTS,
  FETCH_REQUESTS_COMMENTS,
  GET_REQUESTS_COMMENTS,
  FETCH_REQUESTS_ID_COLLECTION
} from '@/store/types';
import notification from '@/utils/notifications';
import StepperCobranzaFacturacion from '@/components/CollectionBillingStepper.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import CollapsableTable from '@/components/CollapsableTable.vue';
import CommentsLists from '@/components/CommentsList.vue';
import getTagText from '@/utils/getTagText';
import getTagColor from '@/utils/getTagColor';
import parseDate from '@/utils/dateFormatter';

export default {
  components: {
    'page-header': PageHeader,
    stepper: StepperCobranzaFacturacion,
    'ticket-data': DatosTicket,
    'collapsable-table': CollapsableTable,
    'comment-list': CommentsLists,
    'associate-inconsistency': AssociateInconsistency,
    'down-outlined': DownOutlined,
    'aproval-modal': ReprocessAprovalModal,
    'reject-modal': ReprocessRejectModal,
    'cancel-modal': ReprocessCancelModal,
    'associate-process': AssociateProcess
  },
  props: {
    headerTitle: {
      type: String,
      default: 'Titulo del page header'
    },
    requestId: {
      type: [String, Number],
      default: ''
    },
    breadcrumRoutes: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    // * Vuex
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const ticket_id = route.params.id;

    // * Page header
    const getRequestData = () => {
      console.log('### getRequestData ###');
      store.dispatch(FETCH_REQUESTS_BY_ID, props.requestId).catch((err) => {
        notification(
          'error',
          'Error al obtener información de la solicitud',
          err.response.data.message
        );
        router.push({ name: 'Missing' });
      });
    };

    const collectionData = computed(() => store.getters[GET_COLLECTIONS]);
    const requests_data = computed(() => store.getters[GET_REQUEST_BY_ID]);

    const current_ticket_state = computed(() => {
      const { requestStates } = store.getters[GET_REQUEST_BY_ID];

      if (requestStates != undefined) {
        return requestStates[requestStates?.length - 1].state != undefined
          ? requestStates[requestStates?.length - 1].state
          : 'cancelled';
      }

      return '';
    });

    const getCurrentStateName = (name) => {
      return name != undefined ? getTagText(name)?.toUpperCase() : '';
    };

    const getCurrentStateColor = (name) => {
      const factored_name = getCurrentStateName(name);
      return factored_name != undefined ? getTagColor(factored_name) : '';
    };

    // * Stepper data
    const stepper_props = computed(() => {
      const props = {
        current: 0,
        state: 'process',
        steps: [
          {
            title: 'En aseguradora',
            date: '',
            key: 1
          },
          {
            title: 'Pendiente resolución',
            date: '',
            key: 2
          }
        ]
      };

      const { requestStates: states } = store.getters[GET_REQUEST_BY_ID];

      // Formateo la fecha del primer estado y la coloco en el stepper
      props.steps[0].date =
        states != undefined ? parseDate(states[0].createdAt) : '';

      // Si existen mas de un estado voy a colocar su nombre correspondiente con su fecha
      if (states?.length > 1) {
        const last_state = states[1];

        props.steps[1].date = parseDate(last_state.createdAt);

        switch (last_state.state) {
          case 'APPROVED':
            props.steps[1].title = 'Aprobada';
            props.state = 'finish';
            break;
          case 'REJECTED':
            props.steps[1].title = 'Rechazado';
            break;
          default:
            props.steps[1].title = 'Cancelado';
            props.state = 'error';
            break;
        }

        props.current = 1;
      }

      return props;
    });

    // * Data de reproceso
    /* const reprocess_details = computed(() => {
      const { collectionResource } = store.getters[GET_REPROCESS];

      return [
        {
          key: 'Periodo',
          value: `${collectionResource?.collection.period_year}-${collectionResource?.collection.period_month}`,
        },
      ];
    }); */

    const subholdingsCustomRender = (values) => {
      if (values != undefined) {
        return values.length > 3
          ? `${values[0]} +${values.length - 1}`
          : values.join(' - ');
      }
      return '';
    };

    const companiesCustomRender = (values) => {
      if (values != undefined) {
        return values.length > 3
          ? `${values[0].businessName} +${values.length - 1}`
          : values.map((obj) => obj.businessName).join('-');
      }
      return '';
    };

    const request_details = computed(() => {
      const data = collectionData.value;

      if (data != []) {
        return [
          {
            key: 'Periodo cobranza',
            value: data?.period
          },
          {
            key: 'Holding',
            value: data?.holding
          },
          {
            key: 'Subholdings',
            value: subholdingsCustomRender(data?.subHoldings)
          },
          {
            key: 'Empresas',
            value: companiesCustomRender(data?.companies)
          },
          {
            key: 'Aseguradora',
            value: data?.insuranceCompany?.businessName
          },
          {
            key: 'Pólizas',
            value: data?.policies?.join(', ')
          },
          {
            key: 'Correo aseguradoras',
            value: data?.insuranceCompany?.collectionEmails
              .map((e) => e)
              .filter((e, i, arr) => arr.indexOf(e) === i)
              .join(',\n')
          },
          {
            key: 'Correo clientes',
            value: data?.companies
              ?.map((e) => e.collectionEmails)
              .flat()
              .filter((e, i, arr) => arr.indexOf(e) == i)
              .join(',\n')
          }
        ];
      }
      return [];
    });

    const getCollectionData = (payload) => {
      store
        .dispatch(FETCH_COLLECTIONS, {
          collection_id: payload,
          query_params: ''
        })
        .catch((err) => err.response.data.message);
    };

    // * Procesos asociados
    const process_data = computed(() => {
      const reprocess = store.getters[GET_REQUESTS_ID_COLLECTION];

      if (reprocess.length > 0) {
        getCollectionData(reprocess[0].id);
      }

      return reprocess?.map((obj) => {
        return {
          key: obj.id,
          name: getReprocessTypeName(obj.type),
          tags: obj.states[obj.states.length - 1].state,
          created_at: obj.states[obj.states.length - 1].createdAt,
          redirection: {
            title: 'Ir al ticket',
            route: {
              name: 'detalleTicketCobranza',
              params: { id: obj.id }
            }
          }
        };
      });
    });

    const getReprocessTypeName = (type) => {
      switch (type) {
        case 'COLLECTION':
          return 'Cobranza';
        default:
          return type;
      }
    };

    const getProcessData = () => {
      store.dispatch(FETCH_REQUESTS_ID_COLLECTION, props.requestId);
    };

    // * Comentarios
    const getComments = () => {
      store.dispatch(FETCH_REQUESTS_COMMENTS, props.requestId);
    };

    const comments = computed(() => {
      return store.getters[GET_REQUESTS_COMMENTS]?.map((message) => {
        return {
          author: `Autor ${message.id}`,
          avatar:
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          description: parseDate(message.createdAt),
          date: message.createdAt,
          content: message.message
        };
      });
    });
    const loading_comments = ref(false);
    const createComment = async (comment) => {
      loading_comments.value = true;
      try {
        const created_comment = {
          brokerId: store.getters[GET_LOGGED_USER].id,
          messageComment: comment
        };

        /*
        {
          "brokerId":1,
          "messageComment":"test"
        }
        */

        await store.dispatch(POST_REQUESTS_COMMENTS, {
          id: props.requestId,
          payload: created_comment
        });

        notification('success', 'Correcto', 'Comentario creado');
        getComments();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_comments.value = false;
    };

    // * Inconsistencia
    const inconsistency_props = reactive({
      inconsistencyFileUrl:
        'https://bewell-s3.s3.us-west-2.amazonaws.com/885f40f0982fa90a1948a86966895cae?AWSAccessKeyId=AKIA2LDBHXTKVF2ASYA6&Expires=1676910757&Signature=gyCPoK%2FUs0DR19lP15OtLin7m7M%3D&response-content-disposition=attachment%3B%20filename%3D%20sample.pdf',
      payrollstate: false,
      inconsistencyTotal: 4,
      details: [
        {
          Cuadratura: 'Titulares',
          Plataforma: 120,
          Cobranza: 120
        },
        {
          Cuadratura: 'Cargas',
          Plataforma: 5,
          Cobranza: 4
        }
      ]
    });

    // * Modal de aprobacion
    const aproval_modal = ref();
    const openAprovalModal = () => {
      aproval_modal.value.openModal();
    };

    // * Modal de rechazo
    const reject_modal = ref();
    const openRejectModal = () => {
      reject_modal.value.openModal();
    };

    // * Modal de cancelacion
    const cancel_modal = ref();
    const openCancelModal = () => {
      cancel_modal.value.openModal();
    };

    // * Obtencion de data principal
    const request_type = computed(() => {
      const types = {
        RETROACTIVE_ADJUSTMENT_REQUEST: 1,
        CREDIT_NOTE_REQUEST: 2,
        DEBIT_NOTE_REQUEST: 3
      };

      return types[store.getters[GET_REQUEST_BY_ID]?.type];
    });

    onMounted(() => {
      getRequestData();
      getProcessData();
      getComments();
    });

    return {
      stepper_props,
      request_details,
      comments,
      loading_comments,
      inconsistency_props,
      requests_data,
      current_ticket_state,
      ticket_id,
      aproval_modal,
      request_type,
      reject_modal,
      cancel_modal,
      process_data,
      openCancelModal,
      openRejectModal,
      openAprovalModal,
      getCurrentStateName,
      getCurrentStateColor,
      getRequestData,
      createComment,
      collectionData
    };
  }
};
</script>
