<template>
  <div v-if="$route.name === 'Aseguradoras'">
    <page-header title="Aseguradoras" :breadcrumRoutes="routes">
      <template #buttons>
        <a-button type="primary" @click="openCreate">Crear aseguradora</a-button>
      </template>
    </page-header>

    <a-table :data-source="dataFetch" :columns="columns" :scroll="{ x: 1200 }" :loading="loading">
      <template #custom_id="{ text }"> # {{ text }} </template>
      <template #customExecutive="{ text }">
        {{ text ? text.name : '' }}
      </template>
      <template #custom_rut="{ text }">
        {{ rutHelper.formatRut(text) }}
      </template>
      <template #action="{ text, record }">
        <span style="display: flex">
          <a-tooltip color="#05045D" :title="`Ver más`">
            <a-button type="link" @click="aseguradoraRedirect(text)" class="edit-aseguradora-text">
              <eye-outlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="`Editar`">
            <a-button type="link" @click="openUpdate(record)" class="edit-aseguradora-text">
              <edit-outlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="`Eliminar`" v-if="role !== 'rrhh'">
            <a-button type="link" @click="openDelete(record)">
              <delete-outlined class="delete-holding-text" />
            </a-button>
          </a-tooltip>
        </span>
      </template>
    </a-table>
    <!-- Modal -->
    <a-modal v-model:visible="visible" :title="modal.title" @ok="closeModal" centered :closable="!loading"
      :maskClosable="!loading">
      <a-form layout="vertical" name="form" ref="formRef" :model="formState" :rules="rules">
        <!-- CREATE / UPDATE -->
        <div v-if="modal.type !== 'Delete'">
          <span class="text-gray-8 fw-regular"> RUT Aseguradora </span>
          <a-form-item name="rut" has-feedback>
            <a-input class="mt-2" placeholder="Ingresa RUT Aseguradora" v-model:value="formState.rut" :maxlength="12"
              @keyup="formatRuts" />
          </a-form-item>
          <span class="text-gray-8 fw-regular"> Nombre / Razón social </span>
          <a-form-item name="name">
            <a-input class="mt-2" placeholder="Ingresa nombre o razón social" v-model:value="formState.name"
              :maxlength="50" />
          </a-form-item>
        </div>
        <!-- DELETE -->
        <div v-if="modal.type === 'Delete'">
          <p>
            Para eliminar a la aseguradora
            <strong>{{ formState.name }}</strong> escribe en el recuadro
            “eliminar” y presiona continuar.
          </p>
          <a-form-item ref="eliminar" name="eliminar">
            <a-input v-model:value="formState.eliminar" placeholder="eliminar" :maxlength="8" />
          </a-form-item>
        </div>
      </a-form>
      <template #footer>
        <a-button key="back" @click="closeModal" :disabled="loading">Cancelar</a-button>
        <a-button :type="modal.type === 'Create' ? 'primary' : 'primary'" v-if="modal.type === 'Create'" key="submit"
          @click="aseguradoraCreate" :loading="loading" :disabled="disableCreate()">
          Crear
        </a-button>
        <a-button :type="modal.type === 'Update' ? 'primary' : 'primary'" v-if="modal.type === 'Update'" key="submit"
          @click="aseguradoraUpdate" :loading="loading" :disabled="disableUpdate()">
          Editar
        </a-button>
        <a-button v-if="modal.type === 'Delete'" :type="modal.type === 'Delete' ? 'danger' : 'danger'" key="submit"
          @click="aseguradoraDelete" :loading="loading" :disabled="disableDelete()">
          Eliminar
        </a-button>
      </template>
    </a-modal>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { reactive, ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';
import {
  FETCH_INSURANCES,
  GET_INSURANCES,
  POST_INSURANCECOMPANY,
  PUT_INSURANCECOMPANY,
  DELETE_INSURANCECOMPANY,
  GET_LOGGED_USER
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import openNotificationWithIcon from '@/utils/notifications';
import PageHeader from '@/components/PageHeader.vue';
import delay from '@/utils/delay';

const routes = [
  {
    path: '/aseguradoras',
    breadcrumbName: 'Aseguradora'
  }
];

export default {
  name: 'AseguradoraDashboard',
  components: {
    'page-header': PageHeader,
    'eye-outlined': EyeOutlined,
    'edit-outlined': EditOutlined,
    'delete-outlined': DeleteOutlined
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const getInsurance = async () => {
      return await store.dispatch(FETCH_INSURANCES, {});
    };
    getInsurance();

    const dataFetch = computed(() => store.getters[GET_INSURANCES]);
    const role = store.getters[GET_LOGGED_USER].roleName;

    // Table columns
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'custom_id'
        }
      },
      {
        title: 'RUT Aseguradora',
        dataIndex: 'rut',
        key: 'rut',
        slots: {
          customRender: 'custom_rut'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'name',
        key: 'name'
      },
      // {
      //  title: 'Tipo de Notificación',
      //  dataIndex: 'typeNotification',
      //  key: 'typeNotification'
      // },
      {
        title: 'Ejecutivo',
        dataIndex: 'ejecutivo',
        key: 'ejecutivo',
        slots: {
          customRender: 'customExecutive'
        }
      },
      {
        title: 'Perfil',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'action'
        }
      }
    ];

    // Modal
    const modal = reactive({ title: '', type: '' });
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const openCreate = () => {
      formState.id = null;
      formState.rut = '';
      formState.name = '';

      modal.title = 'Crear aseguradora';
      modal.type = 'Create';

      showModal();
    };

    const openUpdate = (values) => {
      formState.id = null;
      formState.rut = '';
      formState.name = '';

      formBackup.rut = values.rut;
      formBackup.name = values.name;
      formState.id = values.id;
      formState.rut = rutHelper.formatRut(values.rut);
      formState.name = values.name;
      modal.title = 'Editar aseguradora';
      modal.type = 'Update';

      showModal();
    };

    const openDelete = (values) => {
      formState.id = null;
      formState.rut = '';
      formState.name = '';

      formState.name = values.name;
      formState.id = values.id;
      modal.title = 'Eliminar aseguradora';
      modal.type = 'Delete';

      showModal();
    };

    // Form
    const formRef = ref();
    const formState = reactive({
      id: null,
      rut: '',
      name: '',
      eliminar: ''
    });
    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };
    const formBackup = reactive({
      rut: '',
      name: ''
    });

    // Validations
    const loading = ref(false);

    const disableCreate = () => {
      let disabled = true;
      if (formState.rut.length >= 8 && formState.name.length >= 3) {
        disabled = false;
      }
      return disabled;
    };

    const disableUpdate = () => {
      let disabled = true;
      if (
        formState.rut.length >= 8 &&
        formState.name.length >= 3 &&
        (formState.name !== formBackup.name || formState.rut !== formBackup.rut)
      ) {
        disabled = false;
      }
      return disabled;
    };

    const disableDelete = () => {
      let disabled = true;
      if (formState.eliminar === 'eliminar') {
        disabled = false;
      }
      return disabled;
    };

    const cleanForm = () => {
      formState.rut = '';
      formState.name = '';
    };

    const checkRut = async (rule, value) => {
      let error_message = '';
      if (!value) {
        error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        return Promise.resolve();
      }
      error_message = 'RUT no válido';
      return Promise.reject(error_message);
    };

    const checkName = async (rule, value) => {
      let error_message = '';
      if (!value) {
        error_message = 'Ingrese un nombre o razón social';
        return Promise.reject(error_message);
      }
      if (value.length < 3) {
        const error_message = 'Largo mínimo de 3 caracteres';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const rules = {
      rut: [
        {
          required: true,
          type: 'string',
          validator: checkRut,
          trigger: 'change'
        }
      ],
      name: [
        {
          required: true,
          type: 'string',
          validator: checkName,
          trigger: 'change'
        }
      ]
    };

    const getErrorMessage = (error) => {
      let message = '';
      switch (error) {
        case 'The Insurance name already exists':
          message =
            'Nombre o Razón social de compañía aseguradora ya registrado';
          break;
        case 'The Insurance RUT already exists':
          message = 'El RUT ingresado ya se encuentra registrado';
          break;
        case 'The Insurance RUT and name already exists':
          message =
            'El RUT y la Razón social ingresados ya se encuentran registrados';
          break;
        case 'El nombre para esta aseguradora ya existe':
          message = 'El nombre para esta aseguradora ya existe';
          break;
        case 'El Rut para esta aseguradora ya existe':
          message = 'El Rut para esta aseguradora ya existe';
          break;
        default:
          message = 'No se ha podido generar la operación, intente más tarde';
          break;
      }
      return message;
    };

    // Actions
    const aseguradoraCreate = async () => {
      loading.value = true;

      const payload = {
        rut: rutHelper.normalizeRut(formState.rut),
        businessName: formState.name
      };

      try {
        await store.dispatch(POST_INSURANCECOMPANY, { payload });

        getInsurance();
        openNotificationWithIcon('success', 'Correcto', 'Aseguradora Creada');
        await delay(1000);
      } catch (error) {
        const responseError = getErrorMessage(error.response.data.message);
        openNotificationWithIcon('error', 'Error', responseError);
      }

      loading.value = false;
      closeModal();
      cleanForm();
    };

    const aseguradoraUpdate = async () => {
      loading.value = true;
      const payload = {
        rut: rutHelper.normalizeRut(formState.rut),
        businessName: formState.name
      };

      try {
        await store.dispatch(PUT_INSURANCECOMPANY, {
          insuranceCompanyId: formState.id,
          payload
        });

        getInsurance();

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Aseguradora Actualizada'
        );

        await delay(1000);
      } catch (error) {
        const responseError = getErrorMessage(error.response.data.message);
        openNotificationWithIcon('error', 'Error', responseError);
      }

      loading.value = false;
      cleanForm();
      closeModal();
    };

    const aseguradoraDelete = async () => {
      loading.value = true;
      try {
        await store.dispatch(DELETE_INSURANCECOMPANY, formState.id);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Aseguradora Eliminada'
        );
        getInsurance();
      } catch (error) {
        const responseError = getErrorMessage(error.response.data.message);
        openNotificationWithIcon('error', 'Error', responseError);
      }
      loading.value = false;
      closeModal();
      cleanForm();
    };

    const aseguradoraRedirect = (id) => {
      router.push({ name: 'AseguradorasDetails', params: { id } });
    };

    return {
      role,
      // Variables
      routes,
      dataFetch,
      columns,
      visible,
      formRef,
      formState,
      loading,
      rules,
      modal,
      // Methods
      openCreate,
      openUpdate,
      openDelete,
      closeModal,
      disableCreate,
      disableUpdate,
      disableDelete,
      aseguradoraCreate,
      aseguradoraUpdate,
      aseguradoraDelete,
      aseguradoraRedirect,
      formatRuts,
      rutHelper
    };
  }
};
</script>

<style>
.edit-aseguradora-text {
  color: blue;
  cursor: pointer;
}

.delete-aseguradora-text {
  color: red;
  cursor: pointer;
}
</style>
