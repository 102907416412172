<template>
  <page-header title="Caso de rechazo" :breadcrumRoutes="routes">
    <template #tags>
      <tags :stateId="caseLastStateId" v-if="caseLastStateId !== ''" />
      <tags :stateId="1" v-else />

      <tags color="processing">
        ID Caso: <b>{{ caseData.id }}</b>
      </tags>
    </template>

    <template #buttons v-if="user_role != 'rrhh'">
      <a-button v-if="caseLastStateId == 5 && !hideActions" @click="showModal" type="primary">Apelar rechazo</a-button>
      <a-dropdown v-if="caseLastStateId == 6">
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="approveAppeal">Aprobada</a-menu-item>
            <a-menu-item key="2" @click="denyAppeal">Rechazada </a-menu-item>
          </a-menu>
        </template>
        <a-button>
          Resolución compañía
          <down-outlined />
        </a-button>
      </a-dropdown>
    </template>
  </page-header>

  <a-card class="mt-2">
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket :data="tableData" title="Datos caso" :loadTicket="loadTicket" />

        <solicitudes-asociadas :data="solicitudData" :state="ticketState.tag" :tagColor="ticketState.color"
          :stateId="ticketState.id" v-if="user_role != 'rrhh'" />
      </a-col>

      <a-col :span="12">
        <solicitudes-asociadas :data="solicitudData" :state="ticketState.tag" :tagColor="ticketState.color"
          :stateId="ticketState.id" v-if="user_role == 'rrhh'" />

        <records title="Historial del caso" :data="recordData" v-if="user_role != 'rrhh'"
          :loadingHistory="loadTicket" />
        <documentos-adjuntos :title="'Documentos adjuntos'" :data="attachedFiles" v-if="user_role != 'rrhh'"
          :loadingDocument="loadTicket" />
      </a-col>
    </a-row>
  </a-card>

  <!-- Modal Apelacion -->
  <div v-if="reviewFlag">
    <a-modal v-model:visible="visible" title="Apelación de caso de rechazo" @ok="handleOk" :closable="!loading"
      :maskClosable="!loading" centered>
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="handleOk" :disabled="loading">Cancelar</a-button>
              <a-button key="submit" type="primary" @click="onSubmitApelacion" :loading="loading"
                :disabled="disableApelacion()">
                Enviar
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
        <a-typography-title class="text-gray-8 fw-regular mb-1" strong :level="5">
          Selecciona destinatarios
        </a-typography-title>
        <span class="text-gray-8 fw-regular">Selecciona los destinatarios para este mensaje.</span>
        <a-col :span="24" class="mt-2">
          <a-form-item has-feedback name="addressee">
            <a-select ref="select" mode="multiple" placeholder="Selecciona destinatarios" allowClear
              v-model:value="formState.addressee" :options="casillas">
            </a-select>
          </a-form-item>
        </a-col>
        <a-typography-title class="text-gray-8 fw-regular mt-3 mb-1" strong :level="5">
          ¿Agregar más destinatarios?
        </a-typography-title>
        <span class="text-gray-8 fw-regular">
          Debes separar por comas cada dirección de correo electrónico.
        </span>
        <a-col :span="24" class="mt-2">
          <a-form-item has-feedback name="otherAddressee">
            <a-input v-model:value="formState.otherAddressee" placeholder="Ingresa correos adicionales" />
          </a-form-item>
        </a-col>
        <a-typography-title class="text-gray-8 fw-regular mt-3 mb-1" strong :level="5">
          Texto a compañía aseguradora
        </a-typography-title>
        <span class="text-gray-8 fw-regular">
          Escribe un mensaje a la compañía aseguradora para apelar el caso de
          rechazo.
        </span>
        <a-col :span="24" class="mt-2">
          <a-form-item has-feedback name="message">
            <a-textarea :rows="4" v-model:value="formState.message"
              placeholder="Escribe aquí el mensaje para la compañía aseguradora" />
          </a-form-item>
        </a-col>
        <a-col class="mt-2">
          <a-form-item has-feedback name="fileList">
            <a-upload name="avatar" :file-list="fileList" :multiple="true" :remove="handleRemove"
              :before-upload="beforeUpload">
              <a-button>
                <upload-outlined></upload-outlined>
                Adjuntar documentos
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-form>
    </a-modal>
  </div>

  <!-- Modal Aprobar / Rechazar Caso -->
  <div v-if="!reviewFlag">
    <a-modal v-model:visible="visible" :title="`Apelación ${modalText}`" @ok="handleOk" :closable="!loading"
      :maskClosable="!loading" centered>
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="handleOk" :disabled="loading">Cancelar</a-button>
              <a-button key="submit" type="primary" @click="submitApproveOrDeny" :loading="loading"
                :disabled="disableApproveDeny()">
                Enviar
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-form name="form" layout="vertical" :model="formState" :rules="rules">
        <a-typography-title class="text-gray-8 fw-regular mb-1" strong :level="5">
          Escribe un mensaje al cliente para avisar sobre la apelación
          {{ modalText }}
        </a-typography-title>
        <a-col :span="24" class="mt-4">
          <a-form-item has-feedback name="message">
            <a-textarea :rows="4" :placeholder="modalPlaceHolder" v-model:value="formState.message" />
          </a-form-item>
        </a-col>
      </a-form>
    </a-modal>
  </div>

  <!-- Modal success -->
  <template v-if="showSucess">
    <a-modal v-model:visible="showSucess" centered footer="" title="Creación de caso de rechazo">
      <a-result status="success" title="Mensaje enviado"> </a-result>
    </a-modal>
  </template>
  <!-- Modal error -->
  <template v-if="showError">
    <a-modal v-model:visible="showError" centered footer="" title="Creación de caso de rechazo">
      <a-result status="error" title="No se ha podido generar el caso">
      </a-result>
    </a-modal>
  </template>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { DownOutlined } from '@ant-design/icons-vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Records from '@/components/Tickets/Records.vue';
import SolicitudesAsociadas from '@/components/Tickets/SolicitudesAsociadas.vue';
import DocumentosAdjuntos from '@/components/Tickets/DocumentosAdjuntos.vue';
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
import moment from 'moment';
import {
  GET_LOGGED_USER,
  FETCH_CASE_ID_DATA,
  FETCH_USER_BY_ID,
  FETCH_FILES_TICKET_ID,
  FETCH_APPEAL_EMAILS,
  POST_REJECTION_APPEAL,
  POST_APROVE_OR_DENY_APPEAL
} from '@/store/types';
import openNotificationWithIcon from '@/utils/notifications';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'DetalleRechazo',
  components: {
    'datos-ticket': DatosTicket,
    records: Records,
    'solicitudes-asociadas': SolicitudesAsociadas,
    'documentos-adjuntos': DocumentosAdjuntos,
    'upload-outlined': UploadOutlined,
    'page-header': PageHeader,
    tags: Tags,
    'down-outlined': DownOutlined
  },
  setup() {
    // * Vuex
    const store = useStore();
    const user_role = store.getters[GET_LOGGED_USER].roleName;
    const loadTicket = ref(false);

    const route = useRoute();
    const caseId = ref(route.params.id);
    const caseData = ref({});
    const tableData = reactive([
      { key: 'Broker', value: '' },
      { key: 'Inicio de vigencia', value: '' },
      { key: 'Nombre', value: '' },
      { key: 'RUT', value: '' },
      { key: 'Correo', value: '' },
      { key: 'Empresa', value: '' },
      { key: 'Grupo', value: '' },
      { key: 'Aseguradora', value: '' }
    ]);
    const recordData = ref([]);
    const solicitudData = ref({});
    const casillas = ref([]);
    const currentState = ref('');
    const currentStateColor = ref('');
    const ticketState = reactive({ id: '0', tag: '', color: '' });
    const attachedFiles = ref([]);
    const startDates = ref('');
    const caseLastStateId = ref('');

    // * Rutas
    const routes = [
      {
        path: '/rechazo',
        breadcrumbName: 'Rechazo'
      },
      {
        breadcrumbName: `#${caseId.value}`
      }
    ];

    // Usar bandera para saber si es primer ciclo de revisión del caso de rechazo
    const reviewFlag = ref(true);
    const hideActions = ref(false);
    const router = useRouter();
    const getData = async () => {
      loadTicket.value = true;
      try {
        const response = await store
          .dispatch(FETCH_CASE_ID_DATA, caseId.value)
          .catch((e) => e.response);
        if (response.data.typeCaseId != 2) {
          router.push({ name: 'Missing' });
        }
        const getUser = await store
          .dispatch(FETCH_USER_BY_ID, response.data.brokerId)
          .catch((e) => e.response);
        const getFiles = await store
          .dispatch(FETCH_FILES_TICKET_ID, caseId.value)
          .catch((e) => e.response);

        attachedFiles.value = getFiles.data.map((files) => {
          return {
            key: files.id,
            name: files.name,
            ver: 'Ver más',
            route: files.caseFileUrl
          };
        });

        const { ticket } = response.data;
        startDates.value = ticket.startDate;
        const { employee } = ticket;
        const { states } = response.data;

        console.log('Estados', states);

        // Paso el id del ultimo estado que esta el caso
        caseLastStateId.value = states[states.length - 1].state.id.toString();

        const finalState = states[states.length - 1].state.tag;
        currentState.value = finalState;

        const responseCasillas = await store.dispatch(FETCH_APPEAL_EMAILS, {
          id: ticket.insuranceCompany.id,
          payload: 'Caso Rechazo'
        });

        casillas.value = responseCasillas.data.mailboxInsurances.emails.map(
          (a) => {
            return {
              value: a,
              label: a
            };
          }
        );

        reviewFlag.value = states.length === 1;
        if (finalState.toUpperCase() === 'FINALIZADO' && states.length > 1) {
          hideActions.value = true;
        } else {
          hideActions.value = finalState.toUpperCase() === 'CANCELADO';
        }

        currentStateColor.value = getTagColor(finalState);

        // Table Data
        tableData[0].value = getUser?.data?.body?.name
          ? getUser.data.body.name
          : 'Pendiente';
        tableData[1].value = parseDate(ticket.startDate).substring(0, 10);
        tableData[2].value = `${employee.firstName} ${employee.firstSurname}`;
        tableData[3].value = rutHelper.formatRut(employee.rut);
        tableData[4].value = employee.email;
        tableData[5].value = ticket.company.businessName;
        tableData[6].value = employee.employeeGroups.sort((a, b) => {
          return b.id - a.id;
        })[0].groupCompanies.name;
        tableData[7].value = ticket.insuranceCompany.businessName;

        ticketState.id =
          ticket.states[ticket.states.length - 1].state.id.toString();
        ticketState.tag = ticket.states[ticket.states.length - 1].state.tag;
        ticketState.color = getTagColor(ticketState.tag);

        // Records Data
        recordData.value = states.map((a) => {
          if (a.state.tag == 'Cancelado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'error'
            };
          } else if (a.state.tag == 'Finalizado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'finish'
            };
          } else if (a.state.tag == 'En Apelacion') {
            return {
              description: parseDate(a.createdAt),
              title: 'En apelación'
            };
          }
          return {
            description: parseDate(a.createdAt),
            title: a.state.tag
          };
        });

        // Solicitudes Asociadas Data
        solicitudData.value = {
          ticketId: ticket.id,
          ticketType: 'Alta',
          currentState: recordData.value[0].title
        };
        caseData.value = response.data;
        loadTicket.value = false;
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
        loadTicket.value = false;
        console.log(error);
      }
    };
    getData();

    // Form
    const formRef = ref();
    const formState = reactive({
      addressee: [],
      otherAddressee: '',
      message: ''
    });
    const showError = ref(false);
    const showSucess = ref(false);
    const loading = ref(false);

    const cleanForm = () => {
      formState.addressee = [];
      formState.otherAddressee = '';
      formState.message = '';
    };

    // Docs Logic

    const fileList = ref([]);
    const uploading = ref(false);

    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        fileList.value = [...fileList.value, file];
      }
      return false;
    };

    // Modal
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      visible.value = false;
      if (showError.value) {
        showError.value = !showError.value;
      }
    };

    // Submit Logic
    const onSubmitApelacion = async () => {
      loading.value = true;

      let emails = [];
      emails = [...formState.addressee];
      if (formState.otherAddressee.length >= 5) {
        let newEmails = [];
        newEmails = formState.otherAddressee.split(',');
        newEmails.map((e) => {
          emails.push(e.trim());
        });
      }
      const formData = new FormData();
      emails.forEach((email) => {
        formData.append('insuranceCompanyEmail', email);
      });
      formData.append('message', formState.message);
      fileList.value.forEach((file) => {
        formData.append('file', file);
      });
      uploading.value = true;
      try {
        const response = await store.dispatch(POST_REJECTION_APPEAL, {
          id: caseId.value,
          payload: formData
        });
        if (response.status === 200) {
          uploading.value = false;
          visible.value = false;
          showSucess.value = true;
          cleanForm();
          getData();
        }
      } catch (error) {
        console.log(error);
        uploading.value = false;
        fileList.value = [];
        showError.value = true;
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
      }

      loading.value = false;
    };

    const modalText = ref('');
    const modalPlaceHolder = ref('');
    const target = ref('');

    const approveAppeal = () => {
      modalText.value = 'aprobada';
      formState.message = `Estimado cliente le informamos que la nueva evaluación de la compañía de seguros ha sido positiva, por lo que el asegurado será ingresado en la póliza con vigencia ${moment(startDates.value).format('DD/MM/YYYY')}.`;
      target.value = 1;
      showModal();
    };

    const denyAppeal = () => {
      modalText.value = 'rechazada';
      formState.message = 'Estimado Cliente le informamos que analizada la apelación por parte de la compañía de seguros, esta ha decidido mantener su resolución de rechazo inicial.';
      target.value = 0;
      showModal();
    };

    const submitApproveOrDeny = async () => {
      loading.value = true;

      try {
        const res = await store.dispatch(POST_APROVE_OR_DENY_APPEAL, {
          id: caseId.value,
          action: target.value,
          payload: {
            message: formState.message
          }
        });

        if (res.status === 200) {
          loading.value = false;
          visible.value = false;
          showSucess.value = true;
          cleanForm();
          getData();
        }
      } catch (error) {
        const { message } = error.response.data;

        loading.value = false;
        showError.value = true;
        visible.value = false;

        openNotificationWithIcon('error', 'Error', message);
      }

      loading.value = false;
    };

    // Disable buttons validation

    const disableApelacion = () => {
      // Validacion de campos para el modal de apelacion

      const valid_recipients = formState.addressee.length > 0;
      const valid_file = fileList.value.length > 0;
      const valid_message = formState.message.length >= 5;
      const valid_more_recipients = formState.otherAddressee.length > 0;

      // En caso de que coloque 'Agregar mas destinatarios?' tengo que validar que esten bien escritos
      let emails_validation = true;
      if (valid_more_recipients) {
        const emails = formState.otherAddressee.split(',');
        for (let index = 0; index < emails.length; index++) {
          const element = emails[index].trim();
          const email_validator = validateEmail(element) != null;

          if (email_validator == false) {
            emails_validation = false;
            break;
          }
        }
      }

      // Valido lo restante
      if (
        valid_recipients &&
        valid_file &&
        valid_message &&
        emails_validation
      ) {
        return false;
      }

      return true;
    };

    const disableApproveDeny = () => {
      let blocked = true;
      if (formState.message.length >= 5) {
        blocked = false;
      }
      return blocked;
    };

    // Input validations

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkOtherAddressee = async (rule, value) => {
      if (!value) {
        return Promise.resolve();
      }

      const emails = value.split(',');
      for (let index = 0; index < emails.length; index++) {
        const element = emails[index].trim();
        const email_validator = validateEmail(element) != null;

        if (email_validator == false) {
          return Promise.reject('Correo inválido');
        }
      }
      return Promise.resolve();
    };

    const checkMesage = async (rule, value) => {
      let error_message = '';
      if (!value) {
        error_message = 'Ingrese un mensaje';
        return Promise.reject(error_message);
      }
      if (value.length < 5) {
        const error_message =
          'Largo mínimo de 5 caracteres para un mensaje válido';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const checkFiles = async () => {
      if (fileList.value.length < 1) {
        const error_message = 'Ingrese un archivo';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const rules = {
      addressee: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione al menos un destinatario',
          trigger: 'change'
        }
      ],
      otherAddressee: [
        {
          trigger: 'change',
          type: 'string',
          validator: checkOtherAddressee
        }
      ],
      message: [
        {
          required: true,
          type: 'string',
          validator: checkMesage,
          trigger: 'change'
        }
      ],
      fileList: [
        {
          validator: checkFiles,
          trigger: 'change'
        }
      ]
    };

    return {
      // Variables
      routes,
      caseData,
      casillas,
      tableData,
      recordData,
      solicitudData,
      ticketState,
      currentState,
      currentStateColor,
      attachedFiles,
      formRef,
      formState,
      rules,
      uploading,
      fileList,
      reviewFlag,
      hideActions,
      visible,
      showSucess,
      loading,
      modalText,
      modalPlaceHolder,
      user_role,
      showError,
      caseLastStateId,
      // Methods
      showModal,
      handleOk,
      handleRemove,
      beforeUpload,
      onSubmitApelacion,
      approveAppeal,
      denyAppeal,
      submitApproveOrDeny,
      disableApelacion,
      disableApproveDeny,
      loadTicket
    };
  }
};
</script>
