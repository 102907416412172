<template>
  <page-header :breadcrumRoutes="routes" title="Formulario de modificación" />

  <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules" @finish="onSubmit"
    @finishFailed="onSubmitFailed">
    <a-card class="mt-2">
      <a-row>
        <a-col>
          <a-typography-title class="text-gray-8" :level="4">Datos ticket</a-typography-title>
        </a-col>
      </a-row>
      <a-row :gutter="16" type="flex">
        <a-col :span="8"><a-form-item label="RUT" has-feedback name="rut">
            <a-input allowClear placeholder="15.342.423-6" v-model:value="formState.rut" @change="companyChange"
              @keyup="formatRuts" :maxlength="12" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Aseguradora" has-feedback name="company">
            <a-select placeholder="Selecciona una aseguradora" v-model:value="formState.company" :options="dataCompany"
              :disabled="block">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>

    <a-card class="mt-2">
      <a-row>
        <a-col>
          <a-typography-title class="text-gray-8" :level="4">Mensaje a compañía aseguradora</a-typography-title>
        </a-col>
      </a-row>
      <a-row :gutter="16" type="flex" justify="start">
        <a-col :span="12">
          <a-form-item label="Mensaje" has-feedback name="message">
            <a-textarea placeholder="Escribe aquí el mensaje para la compañía aseguradora."
              v-model:value="formState.message" :rows="4" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>

    <a-form-item class="mt-2">
      <a-row type="flex" class="justify-content-end">
        <a-col>
          <a-button size="large" class="px-4" type="primary" @click="formSubmit" block>Siguiente</a-button>
        </a-col>
      </a-row>
    </a-form-item>

    <!-- Modal para el resumen -->
    <a-modal centered v-model:visible="visible" title="Resumen de modificación individual" @ok="closeModal"
      :closable="!loading" :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="closeModal" :disabled="loading">Cancelar</a-button>
              <a-button key="submit" type="primary" @click="onSubmit" :loading="loading">Enviar</a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">Revisa que los datos ingresados esten
        correctos.</a-typography-title>
      <a-descriptions class="mb-4" title="Datos ticket" bordered>
        <a-descriptions-item label="RUT" :span="3">
          {{ formState.rut ? rutHelper.formatRut(formState.rut) : '' }}
        </a-descriptions-item>
        <a-descriptions-item label="Empresa" :span="3">
          {{
            formState.company >= 1
              ? dataCompany.find((x) => x.value == formState.company).label
              : ''
          }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="Mensaje a compañía aseguradora" bordered>
        <a-descriptions-item label="Mensaje" :span="3">
          <p v-html="formState.message
              ? formState.message.replace(/(\r\n|\n|\r)/gm, '<br>')
              : 'No hay mensaje'
            "></p>
          <!-- <a-typography-text v-text="formState.message ? formState.message : '' " class="text-gray-8"></a-typography-text> -->
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </a-form>

  <!-- En caso de que se cree correctamente el ticket -->
  <a-modal v-model:visible="showSucess" centered footer="" :maskClosable="false">
    <a-result centered status="success" title="Ticket creado"
      sub-title="Tu ticket de modificación individual ia sido creado con éxito.">
      <template #extra>
        <router-link class="justify-content-center d-flex"
          :to="{ name: 'DetalleModificaciones', params: { id: id_ticket } }">
          <a-button key="console" type="primary">Ver Ticket</a-button>
        </router-link>
      </template>
    </a-result>
  </a-modal>

  <!-- En caso de que exista un error al crear el ticket -->
  <template v-if="show_error">
    <a-modal v-model:visible="show_error" centered footer="" :maskClosable="false">
      <a-result centered status="error" title="Error"
        sub-title="No se ha podido generar el ticket. Asegúrate de que exista un ticket de alta generado previamente.">
        <template #extra>
          <a-button type="primary" @click="closeModal">Cerrar</a-button>
        </template>
      </a-result>
    </a-modal>
  </template>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import {
  FETCH_INSURANCE,
  GET_LOGGED_USER,
  POST_M_INDIVIDUAL
} from '@/store/types';
import rutHelper from '@/utils/Ruts';

const routes = [
  {
    path: '/modificaciones',
    breadcrumbName: 'Modificaciones'
  },
  {
    breadcrumbName: 'Individual'
  }
];

export default {
  name: 'ModificacionIndividual',
  components: {
    'page-header': PageHeader
  },
  setup() {
    // id del ticket
    const id_ticket = ref('');

    const dataCompany = ref([]);
    const block = ref(true);
    // * Cargo el store
    const store = useStore();

    // const typesForm = computed({
    //   get(){
    //     return store.getters[GET_TYPE_FORM];
    //   },
    //   set(value){
    //     store.dispatch(TYPE_FORM,value);
    //   }
    // });
    // * Formulario
    const formState = reactive({
      typeForm: '1',
      rut: '',
      company: null,
      message: ''
    });
    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };
    const formSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // Mostrar modal
          visible.value = true;
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    // * RUT
    const valid_rut = ref(false); // Este se activa cuando el rut es valido

    const checkRut = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        valid_rut.value = false;
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        valid_rut.value = true;

        try {
          const resp = await store.dispatch(FETCH_INSURANCE, value);

          if (resp.data.length === 0) {
            return Promise.reject(`El empleado ${rutHelper.normalizeRut(formState.rut)} no está vigente`);
          }

          dataCompany.value = resp.data.map((a) => {
            return {
              value: a.id,
              label: a.businessName
            };
          });
          block.value = false;

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }
      valid_rut.value = false;
      return Promise.reject('RUT no válido');
    };

    const companyChange = () => {
      formState.company = null;
      formRef.value.resetFields('company');
      block.value = true;
    };

    // * Compañias

    // * Metodos formulario
    const rules = {
      company: [
        {
          required: true,
          message: 'Seleccione una aseguradora',
          trigger: 'change',
          type: 'number'
        }
      ],
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      rut: [
        {
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const formRef = ref();

    const onSubmit = async () => {
      try {
        // Dejo cargando el boton de cargando
        loading.value = true;

        // Creo el ticket de modificacion
        const response = await store.dispatch(POST_M_INDIVIDUAL, {
          rut: rutHelper.normalizeRut(formState.rut),
          insuranceCompanyId: formState.company,
          message: formState.message,
          brokerId: store.getters[GET_LOGGED_USER].id
        });

        id_ticket.value = response.data.id;

        if (response.status == 200) {
          showSucess.value = true;
          formRef.value.resetFields();
        }
      } catch (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log('Error', error.message);
        console.log(error.request);
        loading.value = false;
        show_error.value = true;
      }
      visible.value = false;
      loading.value = false;
    };

    const onSubmitFailed = () => {
      console.log('Error en el formulario');
    };

    // * Modal resumen
    const visible = ref(false);
    const loading = ref(false);
    const show_error = ref(false);

    const closeModal = () => {
      visible.value = false;
      if (show_error.value) {
        show_error.value = !show_error.value;
      }
    };

    // * Modal en caso correcto de creacion de ticket
    const showSucess = ref(false);

    return {
      // * Variables
      routes,
      formState,
      rules,
      formRef,
      valid_rut,
      loading,
      id_ticket,
      // * Metodos
      onSubmit,
      onSubmitFailed,
      companyChange,
      dataCompany,
      block,
      formSubmit,
      visible,
      closeModal,
      show_error,
      showSucess,
      formatRuts,
      rutHelper
    };
  }
};
</script>
