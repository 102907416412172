<template>
  <a-modal
    v-model:visible="visible"
    title="Aprobación de cobranza"
    :closable="!loading"
    :maskClosable="!loading"
  >
    <a-typography-title class="fw-regular mb-4" :level="5">
      ¿Estás seguro que deseas aprobar la cobranza de {{ companyNames }} para el
      periodo {{ period }}?
    </a-typography-title>

    <template #footer>
      <a-button @click="closeModal" :disabled="loading">Volver atrás</a-button>
      <a-button type="primary" @click="aproveCollection" :loading="loading"
        >Sí, aprobar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { PATCH_APROVE_COLLECTION_TICKET, GET_LOGGED_USER } from '@/store/types';
import notification from '@/utils/notifications';
import EventBus from '@/utils/events';

export default {
  props: {
    period: {
      type: String,
      default: ''
    },
    ticketId: {
      type: [Number, String],
      default: 0
    },
    companyNames: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const visible = ref(false);
    const loading = ref(false);

    const openModal = () => {
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const aproveCollection = () => {
      loading.value = true;
      EventBus.emit('loadingHumandSend', true);

      const formData = new FormData();
      formData.append('brokerId', store.getters[GET_LOGGED_USER].id);

      const payload_data = { id: props.ticketId, payload: formData };
      store
        .dispatch(PATCH_APROVE_COLLECTION_TICKET, payload_data)
        .then(() => {
          notification('success', 'Correcto', 'Ticket de cobranza aprobado.');
          emit('aprovedCollection', true);
        })
        .catch((error) => {
          notification('error', 'Error', error.response.data.message);
        })
        .finally(() => {
          EventBus.emit('loadingHumandSend', false);
          loading.value = false;
          closeModal();
        });
    };

    return {
      visible,
      openModal,
      closeModal,
      aproveCollection,
      loading
    };
  }
};
</script>
