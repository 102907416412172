<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="Aprobación de cobranza"
      :closable="!loading"
      :maskClosable="!loading"
      centered
    >
      <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">
        Al aprobar está cobranza se le enviará la autorización de emisión de
        factura a la compañia aseguradora.
      </a-typography-title>
      <a-row class="mt-3 mb-4">
        <a-col :span="3">
          <exclamation-circle-outlined
            :style="{ fontSize: '30px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="18">
          <a-typography-text class="text-gray-8">
            En el caso de que cliente haya enviado una OC o HES debes cargar el
            documento
          </a-typography-text>
        </a-col>
      </a-row>
      <a-form
        ref="formRefs"
        layout="vertical"
        :model="formState"
        :rules="rules"
      >
        <a-form-item name="message" v-if="collectionType === 'PRECOLLECTION'">
          <a-textarea
            :maxlength="600"
            placeholder="Resolución"
            v-model:value="formState.message"
            :autoSize="{ minRows: 4, maxRows: 8 }"
          />
        </a-form-item>

        <a-form-item ref="fileList" label="Adjuntar OC O HES" name="fileList">
          <a-upload
            :file-list="formState.fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
            :multiple="true"
          >
            <a-button :style="{ width: '468px' }">
              <upload-outlined></upload-outlined>
              Cargar Documento
            </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="back" @click="closeModal" :disabled="loading"
          >Volver atrás</a-button
        >
        <a-button
          key="submit"
          :loading="loading"
          :disabled="loading == true"
          type="primary"
          @click="submitModal"
          >Aprobar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import {
  ExclamationCircleOutlined,
  UploadOutlined
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import EventBus from '../../../utils/events';
import notification from '@/utils/notifications';
import { GET_LOGGED_USER, PATCH_APROVE_COLLECTION_TICKET } from '@/store/types';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'upload-outlined': UploadOutlined
  },
  props: {
    collectionType: {
      type: String,
      default: 'COLLECTION'
    },
    ticketId: {
      type: [Number, String],
      default: 0
    }
  },
  setup(props, { emit }) {
    const formRefs = ref();
    const visible = ref(false);
    const loading = ref(false);
    const store = useStore();
    const formState = reactive({
      fileList: [],
      message: ''
    });

    const rules = {
      message: [
        {
          required: true,
          message: 'Ingrese mensaje',
          trigger: 'change',
          type: 'string'
        }
      ]
    };

    const handleRemove = (file) => {
      if (file.id) {
        formState.removeFiles = [...formState.removeFiles, file.id];
      }
      const index = formState.fileList.indexOf(file);
      const newFileList = formState.fileList.slice();
      newFileList.splice(index, 1);
      formState.fileList = newFileList;
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else if (formState.fileList.length === 5) {
        message.error('Máximo de archivos a subir es 5');
      } else {
        formState.fileList = [...formState.fileList, file];
      }
      return false;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const openModal = () => {
      visible.value = true;
    };

    const submitModal = () => {
      formRefs.value.validate().then((resp) => {
        const { fileList, message } = resp;
        const formData = new FormData();
        formData.append('brokerId', store.getters[GET_LOGGED_USER].id);

        if (fileList.length > 0) {
          fileList.forEach((file) => {
            formData.append('files', file);
          });
        }

        if (props.collectionType === 'PRECOLLECTION') {
          formData.append('message', message);
        }

        const payload_data = { id: props.ticketId, payload: formData };

        aproveCollection(payload_data);
      });
    };

    const aproveCollection = async (payload) => {
      loading.value = true;
      EventBus.emit('loadingHumandSend', true);
      try {
        await store.dispatch(PATCH_APROVE_COLLECTION_TICKET, payload);
        notification('success', 'Correcto', 'Ticket de cobranza aprobado.');
        emit('aprovedCollection', true);
        closeModal();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      EventBus.emit('loadingHumandSend', false);

      loading.value = false;
    };

    return {
      visible,
      formState,
      rules,
      loading,
      formRefs,
      beforeUpload,
      handleRemove,
      closeModal,
      submitModal,
      openModal
    };
  }
};
</script>
