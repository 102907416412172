<template>
  <div>
    <dashboard-admin v-if="user_role != 'rrhh'" />
    <dashboard-hr v-else />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { GET_LOGGED_USER } from '@/store/types';
import DabhboardAdmin from '@/views/brokers/dashboard/dashboard.vue';
import DashboardHR from '@/views/human-resources/dashboard/dashboard.vue';

export default {
  name: 'Dashboard',
  components: {
    'dashboard-admin': DabhboardAdmin,
    'dashboard-hr': DashboardHR
  },
  setup() {
    // * Vuex
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_LOGGED_USER].roleName;

    return {
      user_role
    };
  }
};
</script>
