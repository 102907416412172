<template>
  <div>
    <a-modal
      v-model:visible="visible"
      :title="modal_content.title"
      :closable="!loading"
      :maskClosable="!loading"
    >
      <a-typography-title :level="5">
        Mensaje a compañía aseguradora
      </a-typography-title>
      <p>{{ modal_content.subtitle }}</p>

      <a-form
        class="mt-2"
        name="form"
        layout="vertical"
        ref="formref"
        :model="form_state"
        :rules="rules"
      >
        <a-form-item name="content" ref="content">
          <a-textarea
            v-model:value="form_state.content"
            placeholder="Escribe Aquí..."
            :rows="10"
          >
          </a-textarea>
        </a-form-item>
      </a-form>

      <a-typography-title :level="5">Documentos adjuntos</a-typography-title>

      <a-skeleton :loading="loading_attachments">
        <ul v-for="att in total_attachments" :key="att.id">
          <li>
            <a-button
              type="link"
              class="pdf-viewer"
              @click="checkPDForExcel(att)"
            >
              {{ att.objectFilename }}
            </a-button>
          </li>
        </ul>
      </a-skeleton>

      <template #footer>
        <a-button
          :disabled="textContentEdit"
          type="primary"
          @click="handleOk"
          :loading="loading"
        >
          Enviar
        </a-button>
      </template>
    </a-modal>

    <pdf-viewer ref="pdf_ref" />
  </div>
</template>

<script>
import { reactive, ref, watchEffect, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import notification from '@/utils/notifications';
import PDFViewer from '@/components/PDFViewer.vue';
import {
  POST_REPROCESS,
  GET_LOGGED_USER,
  FETCH_BILLING_DETAIL_LIST,
  FETCH_COLLECTION_ATTACHMENTS,
  GET_BILLING_DETAIL_LIST,
  GET_COLLECTION_ATTACHMENTS
} from '@/store/types';

export default {
  props: {
    period: {
      type: String,
      default: ''
    },
    ticketId: {
      type: [String, Number],
      default: ''
    },
    policies: {
      type: Array,
      default: []
    }
  },
  components: {
    'pdf-viewer': PDFViewer
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const formref = ref();
    const textContentEdit = ref(false);
    const form_state = reactive({
      content: ''
    });

    const checkText = async (rule, value) => {
      const found = value.match('{Texto escrito por broker}');
      if (found) {
        textContentEdit.value = true;
        return Promise.reject('Edite mensaje broker');
      } else if (value == '') {
        return Promise.reject('ingrese mensaje');
      }
      return Promise.resolve();
    };

    const rules = {
      content: [
        {
          required: true,
          validator: checkText,
          type: 'string'
        }
      ]
    };
    watch(form_state, (val) => {
      const found = val.content.match('{Texto escrito por broker}');
      if (found) {
        textContentEdit.value = true;
      } else {
        textContentEdit.value = false;
      }
    });

    // * Modal
    const visible = ref(false);
    const reprocess_type = ref(0);
    const total_invoices = computed(() =>
      store.getters[GET_BILLING_DETAIL_LIST]?.rows
        ?.map((i) => i.folio)
        .join(', ')
    );
    const total_attachments = computed(
      () => store.getters[GET_COLLECTION_ATTACHMENTS]
    );
    const modal_content = reactive({
      title: '',
      subtitle: ''
    });

    const handleOk = () => {
      formref.value.validate().then(() => {
        createReprocess();
      });
    };

    const createReprocess = async () => {
      loading.value = true;
      try {
        const payload = {
          bodyMail: form_state.content,
          type: reprocess_type.value,
          brokerId: store.getters[GET_LOGGED_USER].id
        };

        await store.dispatch(POST_REPROCESS, { id: route.params.id, payload });

        notification('success', 'Correcto', 'Reproceso creado correctamente.');

        emit('reprocessCreated', true);

        visible.value = false;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const checkPDForExcel = (att) => {
      const extensionPdf = att.objectFilename.match(/\.pdf$/i);
      if (extensionPdf) {
        openPdfViewer(att.objectUrl);
      } else {
        window.location.href = att.objectUrl;
      }
    };
    // * PDFViewer
    const pdf_ref = ref();
    const openPdfViewer = (url) => {
      pdf_ref.value.openModal(url);
    };

    const getInvoicesTotal = () => {
      store
        .dispatch(FETCH_BILLING_DETAIL_LIST, {
          collection_id: props.ticketId,
          query: '?size=1000'
        })
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
        });
    };

    const loading_attachments = ref(false);
    const getAttachments = () => {
      loading_attachments.value = true;
      store
        .dispatch(FETCH_COLLECTION_ATTACHMENTS, props.ticketId)
        .then(() => {
          loading_attachments.value = false;
        })
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
          loading_attachments.value = false;
        });
    };

    watchEffect(() => {
      switch (parseInt(reprocess_type.value)) {
        case 1:
          modal_content.title = 'Reproceso por Inconsistencia';
          modal_content.subtitle =
            'Escribe un mensaje a la compañía aseguradora informando las inconsistencias registradas en el proceso de cobranza';
          form_state.content = `Estimada compañía:\n\nA través del presente correo solicitamos el reproceso de la nómina de cobranza correspondiente al periodo ${
            props.period
          } asociada a ${
            props.policies.length > 1 ? 'las pólizas' : 'la póliza'
          } ${props.policies.join(
            ', '
          )} debido a las siguientes inconsistencias:\n\n{Texto escrito por broker}\n\nUn cordial saludo`;
          break;
        case 2:
          modal_content.title = 'Solicitud de Reproceso por Anulación';
          modal_content.subtitle =
            'Escribe un mensaje a la compañia aseguradora informando los motivos para solicitar la anulación de la factura';
          form_state.content = `Estimada compañía:\n\nA través del presente correo solicitamos la anulación de la(s) factura(s) ${
            total_invoices.value
          } asociada(s) a ${
            props.policies.length > 1 ? 'las pólizas' : 'la póliza'
          } ${props.policies.join(
            ', '
          )} y por consecuencia el reproceso de la nómina de cobranza correspondiente al periodo de ${
            props.period
          } por los siguientes motivos:\n\n{Texto escrito por broker}\n\nUn cordial saludo`;
          break;
        default:
          modal_content.title = 'Solicitud de Reproceso por Cambio';
          modal_content.subtitle =
            'Escribe un mensaje a la compañia aseguradora informando las inconsistencias registradas en el proceso de cobranza';
          form_state.content = `Estimada compañía:\n\nA través del presente correo solicitamos el reproceso de la nómina de cobranza asociada a ${
            props.policies.length > 1 ? 'las pólizas' : 'la póliza'
          } ${props.policies.join(', ')} correspondiente al periodo ${
            props.period
          } debido a la siguiente solicitud de cambio por parte de nuestro cliente:\n\n{Texto escrito por broker}\n\nUn cordial saludo`;
          break;
      }
    });

    const openModal = (type) => {
      reprocess_type.value = type;
      visible.value = true;

      if (type == 2) {
        getInvoicesTotal();
      }

      getAttachments();
    };

    return {
      visible,
      modal_content,
      form_state,
      formref,
      rules,
      loading,
      total_invoices,
      total_attachments,
      pdf_ref,
      loading_attachments,
      openPdfViewer,
      handleOk,
      openModal,
      textContentEdit,
      checkPDForExcel
    };
  }
};
</script>

<style scoped>
.pdf-viewer {
  color: #2521e5;
  cursor: pointer;
}
</style>
