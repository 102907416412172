<template>
  <div>
    <page-header :breadcrumRoutes="routes" title="Formulario de baja masiva" />

    <a-form
      name="form"
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="rules"
      @finish="onSubmit"
    >
      <a-card class="mb-2">
        <a-row>
          <a-col>
            <a-typography-title class="text-gray-8" :level="4"
              >Datos corporativos</a-typography-title
            >
          </a-col>
        </a-row>
        <a-col class="mb-4">
          <a-typography-text class="mb-10"
            >Estos datos aparecerán en el campo de texto del email para la
            compañía aseguradora</a-typography-text
          ></a-col
        >
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <a-form-item label="Empresa" name="company" has-feedback>
              <a-select
                placeholder="Selecciona una empresa"
                v-model:value="formState.company"
                :options="dataCompany"
                @change="loadInsurance"
              >
              </a-select> </a-form-item
          ></a-col>
          <a-col :span="8">
            <a-form-item label="Aseguradora" has-feedback name="insurance">
              <a-select
                placeholder="Selecciona una aseguradora"
                v-model:value="formState.insurance"
                :options="dataInsurance"
                :disabled="blockInsurance"
                @change="loadPolicy"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Pólizas" has-feedback name="policyId">
              <a-select
                placeholder="Selecciona una póliza"
                v-model:value="formState.policyId"
                :disabled="blockPolicy"
                :options="dataPolicy"
                mode="multiple"
              >
              </a-select> </a-form-item
          ></a-col>
        </a-row>
      </a-card>
      <a-row :gutter="16" type="flex" class="mt-2">
        <a-col v-if="formState.typeForm === '1'" :span="16">
          <a-card style="height: 340px">
            <a-typography-title class="text-gray-8" :level="4">
              Mensaje a compañía aseguradora
            </a-typography-title>
            <a-row class="mb-2" :gutter="16">
              <a-col :span="16">
                <a-form-item
                  has-feedback
                  label="Escribe mensaje para compañía aseguradora"
                  name="message"
                >
                  <a-textarea
                    v-model:value="formState.message"
                    placeholder="Escribe aquí..."
                    :rows="6"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item name="optionalFiles" label="Archivos opcionales">
                  <a-upload
                    :file-list="formState.optionalFiles"
                    :multiple="true"
                    :remove="handleRemoveOptional"
                    :before-upload="beforeUploadOptional"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                  >
                    <a-button>
                      <upload-outlined />
                      Subir Archivo
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card style="height: 340px">
            <a-typography-title class="text-gray-8" :level="4">
              Subir archivo
            </a-typography-title>
            <a :href="documentURL" target="_blank" rel="noreferrer noopener">
              Descargar formato
            </a>
            <a-form-item
              label="Adjunta documento excel con listado de asegurados"
              has-feedback
              name="fileList"
            >
              <a-row align="middle" class="mt-2 mb-4">
                <a-upload-dragger
                  style="max-width: 220px"
                  v-model:fileList="formState.fileList"
                  name="fileList"
                  :remove="handleRemove"
                  :before-upload="beforeUpload"
                >
                  <p class="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>

                  <div class="ant-upload-text fs-6 text-gray-9">
                    Haz click o arrastra aquí el archivo para cargarlo
                  </div>
                </a-upload-dragger>
              </a-row>
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>

      <a-form-item class="mt-2">
        <a-row type="flex" class="justify-content-end">
          <a-col>
            <a-button
              size="large"
              class="px-4"
              type="primary"
              @click="formSubmit"
              block
              >Siguiente
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <a-modal
      v-model:visible="visible"
      title="Resumen baja masiva"
      :closable="!loading"
      :maskClosable="false"
    >
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="visible = false" :disabled="loading"
                >Cancelar</a-button
              >
              <a-button
                class="bg-blue-6"
                key="submit"
                type="primary"
                @click="onSubmit"
                :loading="loading"
                >Enviar</a-button
              >
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-8" :level="4">
        Datos corporativos
      </a-typography-title>
      <a-typography-text>
        Revisa que los datos ingresados esten correctos.
      </a-typography-text>

      <a-descriptions class="mb-4 mt-2" bordered>
        <a-descriptions-item label="Empresa" :span="3">
          {{
            dataCompany
              .map((e) => e)
              .filter((f) => f.value == formState.company)
              .map((e) => e.label)[0]
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Aseguradora" :span="3">
          {{
            dataInsurance
              .map((e) => e)
              .filter((f) => f.value == formState.insurance)
              .map((e) => e.label)[0]
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Pólizas" :span="3">
          {{
            formState.company >= 1
              ? dataPolicy
                  .filter((e) => {
                    return formState.policyId.find((f) => f == e.value);
                  })
                  .map((e) => e.label)
                  .join(', ')
              : ''
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Documento adjunto" :span="3">
          <a href="">{{ nameFile }}</a>
        </a-descriptions-item>
      </a-descriptions>
      <a-typography-title class="text-gray-8" :level="4">
        Mensaje a compañía aseguradora
      </a-typography-title>
      <a-collapse>
        <a-collapse-panel key="1" header="Desplegar">
          <p
            v-html="
              formState.message
                ? formState.message.replace(/(\r\n|\n|\r)/gm, '<br>')
                : 'No hay mensaje'
            "
          ></p>
        </a-collapse-panel>
      </a-collapse>
    </a-modal>

    <template v-if="show_error">
      <a-modal
        v-model:visible="show_error"
        centered
        footer=""
        :maskClosable="false"
      >
        <a-result status="error" title="Error" :sub-title="error_text">
          <template #extra>
            <a-button type="primary" @click="handleOk">Cerrar</a-button>
          </template>
        </a-result>
      </a-modal>
    </template>

    <template v-if="showSucess">
      <a-modal
        v-model:visible="showSucess"
        centered
        footer=""
        :maskClosable="false"
      >
        <a-result
          status="success"
          title="Ticket creado"
          sub-title="Tu ticket de baja masiva ha sido creado con éxito."
        >
          <template #extra>
            <router-link
              class="justify-content-center d-flex"
              :to="{
                name: 'BajaMasivaDetail',
                params: { id: createdTicketId }
              }"
            >
              <a-button key="console" type="primary">Ver ticket</a-button>
            </router-link>
          </template>
        </a-result>
      </a-modal>
    </template>
  </div>
</template>

<script>
import { reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import PageHeader from '@/components/PageHeader.vue';
import {
  FETCH_ALL_COMPANIES,
  FETCH_AM_INSURANCES,
  FETCH_POLICY_INSURANCE_COMPANY,
  GET_LOGGED_USER,
  POST_TICKET_BAJA_MASIVA,
  FETCH_BM_URL
} from '@/store/types';
import openNotificationWithIcon from '@/utils/notifications';

const routes = [
  {
    path: '/bajas',
    breadcrumbName: 'Bajas'
  },
  {
    breadcrumbName: 'Masiva'
  }
];

export default {
  name: 'BajaMasiva',
  components: {
    'page-header': PageHeader,
    UploadOutlined: UploadOutlined
  },
  setup() {
    const formRef = ref();
    const store = useStore();
    const blockInsurance = ref(true);
    const blockPolicy = ref(true);
    const dataPolicy = ref([]);
    const dataInsurance = ref([]);
    const dataCompany = ref([]);
    const nameFile = ref('');
    const loading = ref(false);
    const showSucess = ref(false);
    const formState = reactive({
      typeForm: '1',
      brokerId: store.getters[GET_LOGGED_USER].id,
      policyId: [],
      company: null,
      insurance: null,
      documents: [],
      message:
        'A través del presente correo solicitamos la siguiente baja masiva para los rut de colaboradores en el documento adjunto.',
      fileList: [],
      optionalFiles: []
    });
    watchEffect(async () => {
      const response = await store.dispatch(FETCH_BM_URL);
      documentURL.value = response;
    });
    const documentURL = ref(null);

    const createdTicketId = ref(null);

    /* modal */
    const visible = ref(false);
    const show_error = ref(false);
    const activeKey = ref(['1']);

    watchEffect(async () => {
      await store
        .dispatch(FETCH_ALL_COMPANIES)
        .then((response) => {
          dataCompany.value = response.data.map((a) => {
            return {
              value: a.id,
              label: a.businessName
            };
          });
          if (dataInsurance.value > 0) {
            blockInsuranceSelect();
          }
        })
        .catch((err) => {
          blockInsurance.value = true;
          console.log(err);
          message.error('Compañía no existe');
        });
    });
    const loadInsurance = async () => {
      try {
        formState.policyId = null;
        formState.insurance = null;
        formState.fileList = [];
        blockInsurance.value = true;
        if (formState.insurance == null) {
          blockPolicy.value = true;
          formRef.value.resetFields(['insurance', 'policyId']);
        }
        const response = await store.dispatch(
          FETCH_AM_INSURANCES,
          formState.company
        );
        dataInsurance.value = response.data.map((a) => {
          return {
            value: a.id,
            label: a.businessName
          };
        });
        blockInsurance.value = false;
      } catch (error) {
        console.log(error);
      }
    };
    const loadPolicy = async () => {
      try {
        if (dataPolicy.value.length > 0) {
          dataPolicy.value.splice(0);
        }
        if (formState.policyId) {
          formRef.value.resetFields('policyId');
          formState.policyId = [];
        }
        const response = await store.dispatch(FETCH_POLICY_INSURANCE_COMPANY, {
          company: formState.company,
          insurance: formState.insurance
        });
        if (response.data) {
          dataPolicy.value = response.data.map((a) => {
            return {
              value: a.id,
              label: `${a.insuranceCompany.businessName} - ${a.numberPolicy}`,
              numberPolicy: a.numberPolicy
            };
          });
          blockPolicy.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const blockInsuranceSelect = () => {
      formState.insurance = '';
      dataInsurance.value.length = 0;
      blockInsurance.value = true;
    };

    const handleRemove = () => {
      formState.fileList = [];
      formRef.value.validate('fileList');
    };

    const handleRemoveOptional = (file) => {
      const index = formState.optionalFiles.indexOf(file);
      const newFileList = formState.optionalFiles.slice();
      newFileList.splice(index, 1);
      formState.optionalFiles = newFileList;
    };

    const beforeUploadOptional = (file) => {
      const MAX_FILES = 5;
      const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;

      if (formState.optionalFiles.length >= MAX_FILES) {
        message.error('Solo puedes subir un máximo de 5 archivos');
        return false;
      }
      if (file.size > MAX_FILE_SIZE_BYTES) {
        message.error('El archivo debe ser menor a 5MB');
        return false;
      }

      formState.optionalFiles = [...formState.optionalFiles, file];
      return false;
    };

    // Validations
    const checkCompany = async (rule, value) => {
      if (!value) {
        const error_message = 'Seleccione una empresa';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };
    const checkInsurance = async (rule, value) => {
      if (!value) {
        const error_message = 'Seleccione una aseguradora';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const rules = {
      company: [
        {
          required: true,
          validator: checkCompany,
          trigger: 'change',
          type: 'number'
        }
      ],
      insurance: [
        {
          required: true,
          validator: checkInsurance,
          trigger: 'change'
        }
      ],
      policy: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione una póliza',
          trigger: 'change'
        }
      ],
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      fileList: [
        {
          type: 'array',
          required: true,
          message: 'Adjunte un archivo',
          trigger: 'change'
        }
      ]
    };

    const showModal = () => {
      visible.value = true;
    };

    const formSubmit = () => {
      formRef.value.validate().then(() => {
        visible.value = true;
      });
    };

    const onSubmit = async () => {
      loading.value = true;
      try {
        const res = await store.dispatch(POST_TICKET_BAJA_MASIVA, formState);
        createdTicketId.value = res.id;
        loading.value = false;
        openNotificationWithIcon(
          'success',
          'Correcto',
          'Ticket de baja masiva creado con éxito'
        );
        blockInsurance.value = true;
        blockPolicy.value = true;
        formState.policyId = null;
        visible.value = false;
        showSucess.value = true;
        formRef.value.resetFields();
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `Error al generar el ticket de baja masiva. ${error.response.data.message}`
        );
      }
    };

    const beforeUpload = (file) => {
      nameFile.value = file.name;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.fileList[0] = file;
      }
      return false;
    };

    const valueTextArea = ref('');

    return {
      documentURL,
      createdTicketId,
      showSucess,
      loading,
      nameFile,
      onSubmit,
      formRef,
      loadInsurance,
      beforeUpload,
      handleRemove,
      loadPolicy,
      blockInsuranceSelect,
      activeKey,
      dataInsurance,
      dataPolicy,
      blockInsurance,
      blockPolicy,
      dataCompany,
      formState,
      formSubmit,
      show_error,
      rules,
      showModal,
      routes,
      value1: ref(),
      valueTextArea,
      visible,
      handleRemoveOptional,
      beforeUploadOptional
    };
  }
};
</script>
