<template>
  <div class="mt-2">
    <a-button
      type="primary"
      @click="openModal"
      style="border: 1px solid #2521e5; width: 160px"
      class="mt-2"
    >
      <mail-outlined /> Enviar Facturas
    </a-button>
    <!-- RESUME -->
    <a-modal v-model:visible="visible" title="Envío de Facturas">
      <div>
        <p>
          Se reconocen las siguientes contrapartes para el envió de sus
          facturas, se enviará un mensaje automatico a tus clientes adjuntando
          los documentos emitidos por la compañía aseguradora.
        </p>
        <p>Revisa que los datos registrados esten correctos.</p>
        <div v-for="(resume, i) in dataHumanSend" :key="i">
          <a-card
            :bodyStyle="'padding:0;'"
            :bordered="false"
            class="ticket_details"
          >
            <template #title>
              <a-typography-title :level="5" style="color: blue">{{
                `Contraparte ${i + 1}`
              }}</a-typography-title>
              <a-typography-text> Para: </a-typography-text>
              <p style="white-space: normal">
                {{ resume.mailbox.emails.map((e) => e).join(', ') }}
              </p>
            </template>
            <a-descriptions class="" bordered>
              <a-descriptions-item label="Nº de Empresas" span="3">
                <a-tooltip
                  :title="
                    resume.companies.map((c) => c.businessName).join(', ')
                  "
                >
                  {{ resume.companies.length || '' }}
                </a-tooltip>
              </a-descriptions-item>
              <a-descriptions-item label="Nº de Facturas" span="3">
                <a-tooltip
                  :title="resume.invoices.map((i) => i.folio).join(', ')"
                >
                  {{ resume.invoices.length || '' }}
                </a-tooltip>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </div>
      </div>

      <template #footer>
        <a-button :loading="loading" @click="closeModal">Cancelar</a-button>
        <a-button type="primary" @click="SendToRRHH" :loading="loading"
          >Enviar Facturas</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, watchEffect, computed, onMounted } from 'vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import notification from '../../../../utils/notifications';
import {
  FETCH_INVOICE_HUMAN_SEND,
  GET_HUMAN_SEND_INVOICES,
  POST_INVOICE_HUMAN_SEND,
  GET_LOGGED_USER
} from '@/store/types';
import {} from '@/store/types';

export default {
  props: {
    insuranceId: {
      type: [String, Number],
      default: ''
    },
    collectionId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    'mail-outlined': MailOutlined
  },
  setup(props) {
    const store = useStore();
    const visible = ref(false);
    const loading = ref(false);
    const statusMessageError = ref('');
    const statusCodeError = ref(0);
    const dataHumanSend = ref([]);
    const dataResumeDetail = ref();
    const dataSendHumanInvoice = computed(
      () => store.getters[GET_HUMAN_SEND_INVOICES]
    );
    const brokerId = store.getters[GET_LOGGED_USER].id;

    watchEffect(() => {
      dataResumeDetail.value = [
        {
          key: 'Nº de Empresas',
          value: ''
        },
        {
          key: 'Nº de Facturas',
          value: ''
        }
      ];
    });

    const closeModal = () => {
      visible.value = false;
    };
    const SendToRRHH = async () => {
      try {
        loading.value = true;
        const messageList = dataSendHumanInvoice.value.map((counterPart) => {
          return {
            emails: counterPart.mailbox.emails,
            invoices: counterPart.invoices.map((invoice) => invoice.id),
            companies: counterPart.companies.map((company) => company.id)
          };
        });
        await store.dispatch(POST_INVOICE_HUMAN_SEND, {
          collectionId: props.collectionId,
          insuranceCompanyId: props.insuranceId,
          payload: { messageList, brokerId }
        });
        loading.value = false;
        closeModal();
        notification(
          'success',
          'Correcto',
          'Las factauras se han enviado correctamente.'
        );
      } catch (error) {
        loading.value = false;
        notification('error', 'Error', error.response.data.message);
      }
    };

    const fetchInoviceHumanSend = async () => {
      try {
        const resp = await store.dispatch(FETCH_INVOICE_HUMAN_SEND, {
          collectionId: props.collectionId,
          insuranceCompanyId: props.insuranceId
        });
        if (resp.status == 200) {
          dataHumanSend.value = resp.data;
        }
      } catch (error) {
        statusMessageError.value = error.response.data.message;
        statusCodeError.value = error.response.data.statusCode;
      }
    };

    const openModal = async () => {
      await fetchInoviceHumanSend();
      if (!dataSendHumanInvoice.value.length) {
        visible.value = false;
        notification('error', 'Error', statusMessageError.value);
      } else {
        visible.value = true;
      }
    };

    onMounted(() => {
      fetchInoviceHumanSend();
    });

    return {
      visible,
      openModal,
      closeModal,
      loading,
      SendToRRHH,
      statusMessageError,
      statusCodeError,
      dataHumanSend,
      dataResumeDetail,
      dataSendHumanInvoice,
      brokerId
    };
  }
};
</script>
1
